import React, { useContext, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Controller } from 'react-hook-form';
import DOMPurify from 'dompurify';
import BuilderContainer from 'src/pages/PerformanceBuilder/containers/BuilderContainer';
import StarsSvg from 'src/assets/svg/stars.svg';
import ChevronDownSVG from 'src/assets/svg/chevronDown.svg';
import { Select, Button, toast } from 'src/components';
import { useReviewSuggestions } from 'src/queries/Wizard/wizard';
import COMMON_CONSTANTS from 'common/commonConstants';
import {
  useUpdateBuilderReport,
  QUERY_KEYS as BUILDER_QUERY_KEYS,
  useBuilderReport
} from 'src/queries/builder';
import commonUtils from 'common/commonUtils';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import commonBuilderUtils from 'common/commonBuilderUtils';
import SpinnerSVG from 'src/assets/spinner.svg';
import { useQueryClient } from 'react-query';
import commonReviewUtils from 'common/commonReviewUtils';
import { isEmpty } from 'lodash';

const { PROMPT_TYPES } = COMMON_CONSTANTS;

const Question = ({ category, question, nextQuestionId }) => {
  const {
    data: {
      editToken, form, dataset, prevDataRef, setOpenState, reportId
    },
    setDataAttribute
  } = useContext(PerformanceBuilderContext);
  const { showBuilderAiSuggestions } = useFlags();
  const { isFetching } = useBuilderReport(reportId);

  const queryClient = useQueryClient();
  const {
    control, register, watch, getValues
  } = form;
  const prevData = prevDataRef.current;
  const {
    generateSuggestions,
    data: generateSuggestionsData,
    isLoading: isLoadingGenerateSuggestions
  } = useReviewSuggestions();
  const { update: updateReport, isLoading: isUpdateReportLoading } = useUpdateBuilderReport(editToken);

  const [isQuestionOpen, setIsQuestionOpen] = useState(false);
  const [suggestionHTML, setSuggestionHTML] = useState(null);

  useEffect(() => {
    if (generateSuggestionsData.length) {
      const [suggestionData] = generateSuggestionsData;
      setSuggestionHTML({
        __html: DOMPurify.sanitize(suggestionData)
      });
    }
  }, [generateSuggestionsData]);

  const { questionId } = question;

  const { categoryId } = category;
  const formValues = watch();
  const {
    reviews, revieweeFirstName, reviewerFirstName, industry
  } = formValues;
  const index = reviews.findIndex(
    (r) => r.categoryId === categoryId && r.questionId === questionId
  );
  const review = reviews[index];
  if (!review) return null;

  const { answer: reviewAnswer, comments: reviewComments } = review;
  const questionText = commonReviewUtils.filloutReviewQuestionName(
    question.text,
    revieweeFirstName
  );

  const switchIsQuestionOpen = () => setIsQuestionOpen(!isQuestionOpen);
  setOpenState[questionId] = setIsQuestionOpen;

  const getSuggestions = async () => {
    await generateSuggestions({
      filters: {
        text: reviewComments,
        question: questionText,
        type: PROMPT_TYPES.FEEDBACK_SUGGESTION
      },
      options: {
        source: 'builder',
        builderNames: {
          revieweeFirstName,
          reviewerFirstName
        }
      }
    });
  };

  const getIsButtonDisabled = () => {
    if (isFetching || isLoadingGenerateSuggestions) return true;

    const areFieldsValid = reviewAnswer && reviewAnswer.label;
    if (!areFieldsValid) return true;

    const { reviews: oldReviews } = prevData;
    const oldReview = oldReviews.find(
      (r) => r.categoryId === categoryId && r.questionId === questionId
    );
    if (!oldReview) return false;
    const { answer: oldAnswer, comments: oldComments } = oldReview;

    const canSubmitForm = !commonUtils.isSame(
      {
        answer: oldAnswer.label,
        comments: oldComments
      },
      {
        answer: reviewAnswer.label,
        comments: reviewComments
      }
    );

    return !canSubmitForm;
  };

  const update = async () => {
    const { reviews: oldReviewsArray } = prevData;
    const newReviewsArray = [...oldReviewsArray];
    const existingReview = newReviewsArray.find(
      (r) => r.categoryId === categoryId && r.questionId === questionId
    );
    existingReview.answer = reviewAnswer;
    existingReview.comments = reviewComments;

    const reviewsToSave = newReviewsArray
      .filter((r) => r.answer && r.answer.label)
      .map((r) => ({
        categoryId: r.categoryId,
        questionId: r.questionId,
        answer: r.answer.label,
        comments: r.comments
      }));
    const data = {
      reviews: reviewsToSave
    };

    const {
      data: { data: updatedReport }
    } = await updateReport({ data });
    queryClient.invalidateQueries([BUILDER_QUERY_KEYS.BUILDER, reportId]);
    queryClient.invalidateQueries([BUILDER_QUERY_KEYS.BUILDER, editToken]);
    queryClient.invalidateQueries(BUILDER_QUERY_KEYS.BUILDER_DASHBOARD);
    toast.show('Performance review updated!');

    prevDataRef.current = JSON.parse(JSON.stringify(getValues()));

    const industryData = dataset.find(
      (i) => i.industryId === updatedReport.industryId
    );
    setDataAttribute(
      'isAnyEmpty',
      commonBuilderUtils.getIsAnyAnswerEmpty(updatedReport, industryData)
    );

    setIsQuestionOpen(false);
    setTimeout(() => {
      if (nextQuestionId) setOpenState[nextQuestionId](true);
    }, 500);
  };

  const isReviewAnswerEmpty = !reviewAnswer || !reviewAnswer.label;
  const areCommentsTooShort = reviewComments.length <= 100;
  const canGetSuggestions = !isReviewAnswerEmpty && !areCommentsTooShort;

  const getTooltipText = () => {
    if (isReviewAnswerEmpty && areCommentsTooShort) return 'Please select an answer and add more feedback before we can make a suggestion';
    if (isReviewAnswerEmpty) return 'Please select an answer before we can make a suggestion';
    if (areCommentsTooShort) return 'Please add more feedback before we can make a suggestion';
  };

  const answerOptions = commonBuilderUtils.getAnswerOptions(
    industry.id,
    dataset
  );
  return (
    <BuilderContainer loading={isUpdateReportLoading}>
      <div
        className={`mb-4 min-h-20 py-2 w-full flex flex-col items-center rounded-lg text-left border border-slate-300 shadow px-6 ${
          isQuestionOpen ? 'pb-4' : ''
        }`}
      >
        <button
          className='w-full items-center text-left min-h-16 flex justify-between'
          onClick={switchIsQuestionOpen}
          type='button'
        >
          <span className='text-sm leading-5 font-bold w-[90%]'>
            {questionText}
          </span>
          <span
            className={`border-none focus:ring-0 transform ${
              isQuestionOpen ? '' : 'rotate-180'
            } focus:outline-none w-4 h-4 mr-2`}
          >
            <ChevronDownSVG width={20} height={20} />
          </span>
        </button>
        {isQuestionOpen ? (
          <div className='w-full flex flex-col'>
            <Controller
              name={`reviews[${index}].answer`}
              defaultValue=''
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  id={`question-category-${categoryId}-${questionId}`}
                  disabled={isLoadingGenerateSuggestions}
                  classes='w-[11.5rem] justify-self-start mb-4'
                  scrollStyle='purple'
                  customVariantClasses='bg-white rounded-lg border border-slate-300 h-10'
                  options={answerOptions}
                  title={value?.label || 'Select Answer'}
                  onChange={(option) => onChange(option)}
                  optionsWidth='fit'
                />
              )}
            />
            <div className='w-full h-full'>
              <textarea
                disabled={isLoadingGenerateSuggestions}
                maxLength='2000'
                {...register(`reviews[${index}].comments`)}
                className='h-28 text-sm border border-slate-300 bg-white rounded-lg w-full'
                placeholder='Type your response here'
              />
            </div>
            <p className='text-gray-500 text-sm mb-0'>
              Your message will be displayed on the final review.
            </p>
            {suggestionHTML ? (
              <div className='bg-sky-blue p-4 mt-4 rounded-md'>
                <div className='flex mb-2 text-gray-450'>
                  <StarsSvg className='mr-2' />
                  WorkStory AI Assistant
                </div>
                <p className='mb-1'>
                  {`Suggestions for improving your feedback for ${revieweeFirstName}:`}
                </p>
                <div
                  dangerouslySetInnerHTML={suggestionHTML}
                  className='pl-8'
                />
              </div>
            ) : null}
            <div className='w-full flex justify-between mt-6'>
              <Button
                type='button'
                disabled={getIsButtonDisabled()}
                variant='custom'
                classes='w-[7rem] bg-[#0F172A] text-white text-base px-4 py-2 rounded-md answer transition-colors duration-300'
                onClick={update}
              >
                Save
              </Button>
              <div className='flex items-center gap-4'>
                {isLoadingGenerateSuggestions ? (
                  <div className='bg-sky-blue flex justify-center items-center text-base p-2 rounded-md'>
                    <SpinnerSVG className='w-6 h-6' />
                    Processing
                  </div>
                ) : null}
                <div className='tooltip relative '>
                  {canGetSuggestions ? null : (
                    <span className='tooltip-text p-1 bg-black text-white w-80 max-w-19rem -mt-18 -ml-14'>
                      {getTooltipText()}
                    </span>
                  )}
                  {!suggestionHTML && showBuilderAiSuggestions ? (
                    <Button
                      type='button'
                      disabled={
                        isLoadingGenerateSuggestions || !canGetSuggestions
                      }
                      variant='custom'
                      classes='bg-purple text-base text-white px-4 py-2 rounded-md answer transition-colors duration-300'
                      onClick={getSuggestions}
                    >
                      Suggest Improvements
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </BuilderContainer>
  );
};

export default Question;
