import React, { useContext, useState } from 'react';
import { get } from 'lodash';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import { appUtils, Base } from 'src/components/';
import { Table, AlertModal } from 'src/componentsTailwind/index';
import { TYPES } from 'src/componentsTailwind/Table/Table';
import CircledIcon from 'src/components/CircledIcon/CircledIcon';
import CircleV2 from 'src/components/Circle/CircleV2';
import { BADGE_COLOR_CLASSES } from 'src/componentsTailwind/tailwindConstants';
import { COMPARE, useAccountsScoreComparison } from 'src/queries/account';
import GrowingBarChartSVG from 'src/assets/svg/growing-bar-chart.svg';
import STYLE from 'src/constants/style';
import COMMON_CONSTANTS from 'common/commonConstants';

const { USER_STATE } = COMMON_CONSTANTS;

const ConstructiveReviewRanking = () => {
  const { context, updateContext } = useContext(CompanyDashContext);
  const loggedUserId = appUtils.getLoggedUserId();

  const {
    range, filters, currentPages, currentSort
  } = context;
  const { managerId } = filters;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserAccount, setSelectedUserAccount] = useState(null);

  const currentPage = currentPages.constructiveReviewRanking;
  const sort = currentSort.constructiveReviewRanking;
  const updateSort = (newSort) => updateContext({
    currentSort: {
      ...currentSort,
      constructiveReviewRanking: { field: 'avgScoreGiven', order: newSort }
    },
    currentPages: {
      ...currentPages,
      constructiveReviewRanking: 1
    }
  });
  const updatePage = (newPage) => updateContext({
    currentPages: {
      ...currentPages,
      constructiveReviewRanking: newPage
    }
  });

  const {
    data: reviewers,
    meta: {
      page: { totalItems, totalPages }
    },
    isFetching: isFetchingScores
  } = useAccountsScoreComparison(
    {
      managerId,
      ...(range.start && {
        start: range.start,
        end: range.end
      }),
      notStatus: [USER_STATE.UNASSIGNED],
      compare: COMPARE.COMPANY
    },
    {
      include: { account: true },
      page: {
        number: currentPage,
        size: 10
      },
      sort: {
        field: sort.field,
        order: sort.order
      }
    },
    {
      keepPreviousData: true
    }
  );

  const columns = [
    {
      label: 'Rank',
      tooltip: false,
      span: 0.5
    },
    {
      label: 'Name',
      tooltip: true,
      type: TYPES.IMAGE_WITH_TEXT
    },
    {
      label: 'Title',
      tooltip: true
    },
    {
      label: 'Reviewing Others',
      tooltip: false,
      type: TYPES.SORTABLE,
      sort: { ...sort, isSelected: true },
      setSort: updateSort
    },
    {
      label: 'Score vs Team Average',
      tooltip: false,
      span: 0.5
    }
  ];

  const rows = reviewers.map((user, index) => {
    const {
      account: {
        _id: userId, name, title, imageUrl
      },
      avgScoreGiven,
      avgScoreGivenDiff
    } = user;

    let rankLabel = null;

    if (sort.order === 'asc') {
      rankLabel = totalItems - (currentPage - 1) * 10 - index;
    } else {
      rankLabel = (currentPage - 1) * 10 + index + 1;
    }

    const reviewingOthersLabel = avgScoreGiven ?? 0;

    let scoreVsTeamComparisonLabel = '-';
    let scoreVsTeamBadgeColorClasses = BADGE_COLOR_CLASSES.GRAY;
    let reviewingOthersProgressColorClasses = 'bg-[#1DBF73] ';

    if (isFinite(avgScoreGivenDiff)) {
      if (avgScoreGivenDiff > 0) {
        scoreVsTeamBadgeColorClasses = BADGE_COLOR_CLASSES.GREEN;
        scoreVsTeamComparisonLabel = `+${avgScoreGivenDiff}`;
      } else if (avgScoreGivenDiff < 0) {
        scoreVsTeamBadgeColorClasses = BADGE_COLOR_CLASSES.RED;
        scoreVsTeamComparisonLabel = avgScoreGivenDiff;
        reviewingOthersProgressColorClasses = 'bg-red-700';
      } else {
        reviewingOthersProgressColorClasses = 'bg-bright-blue';
      }
    } else {
      scoreVsTeamComparisonLabel = 'N/A';
    }
    const profileUrl = loggedUserId === userId
      ? `/dashboard/me/participation`
      : `/dashboard/profile/${userId}/participation`;

    return [
      { label: rankLabel, labelClasses: 'font-bold' },
      {
        label: name,
        image: imageUrl,
        type: TYPES.IMAGE_WITH_TEXT,
        onClick: () => {
          setSelectedUserAccount({
            id: userId,
            name,
            firstName: name.split(' ')[0],
            imageUrl,
            profileUrl
          });
          setIsModalOpen(true);
        }
      },
      {
        label: title
      },
      {
        label: reviewingOthersLabel,
        type: TYPES.PROGRESS_BAR,
        progress: reviewingOthersLabel,
        barClasses: reviewingOthersProgressColorClasses
      },
      {
        badges: [
          {
            label: scoreVsTeamComparisonLabel,
            colorClasses: scoreVsTeamBadgeColorClasses,
            labelClasses: '!font-bold',
            badgeClasses: '!w-[100%] !rounded-full',
            containerClasses: 'w-[60px]'
          }
        ],
        type: TYPES.BADGES
      },
      {
        label: ''
      }
    ];
  });

  const pagination = {
    currentPage,
    setCurrentPage: updatePage,
    totalPages
  };

  const isFetching = isFetchingScores;

  return (
    <>
      <AlertModal
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
        onAction={() => {
          window.open(selectedUserAccount.profileUrl, '_blank');
          setIsModalOpen(false);
          setSelectedUserAccount(null);
        }}
        title={get(selectedUserAccount, 'name', 'View More Details')}
        content={`View ${get(selectedUserAccount, 'firstName', 'this user')}'s dashboard see more details.`}
        actionText='View Dashboard'
        isLoading={isFetching}
        type='success'
        customIcon={(
          <CircleV2
            imageUrl={get(selectedUserAccount, 'imageUrl', null)}
            size='sm'
          />
        )}
      />
      <Base
        classes={`${STYLE.CONTAINER_WHITE} !p-4`}
        relative
        loading={isFetchingScores}
      >
        <CircledIcon svg={<GrowingBarChartSVG className='text-purple' />} />
        <p className='text-xl text-black font-bold mb-0 mr-8'>
          Constructive Review Ranking
        </p>
        <p className='text-md font-normal my-2 leading-5 text-bluish-gray mb-6'>
          Ranking Team Members based on how high or low they review others.
        </p>
        <Table
          rowClasses='!px-0 [&>*:first-child]:!pl-4 [&>*:last-child]:!pr-0'
          headClasses='[&_*]:!text-sm [&_*]:!truncate [&>*:first-child]:!pl-4 [&>*:last-child]:!pr-0'
          columns={columns}
          rows={rows}
          pagination={pagination}
        />
      </Base>
    </>
  );
};
export default ConstructiveReviewRanking;
