import React, { useEffect } from 'react';
import { get } from 'lodash';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useNavigate,
  useLocation,
  useParams,
  Outlet
} from 'react-router-dom';
import {
  SignUp,
  Dashboard,
  Success,
  DocumentsRedirect,
  IntroRedirect,
  RedirectPage,
  Slack,
  SlackSignUp,
  PerformanceBuilder
} from 'src/pages';
import { useFlags } from 'launchdarkly-react-client-sdk';
import appUtils from 'src/components/appUtils';
import {
  UserProfile as UserProfileV2,
  Team,
  Organization,
  Dash,
  DashTeam,
  DashCompany,
  Settings,
  DirectReview,
  EmailReview,
  EmailGoal,
  Pulse,
  PulseQuestionResults,
  UserReport,
  Goals,
  Kudos,
  GoalSettings,
  NotificationSettings,
  NotifyRevieweeParticipation,
  ReportTemplates,
  NewUserReport,
  NewUserReportFeedback,
  NewUserReportPerformanceCategories,
  NewUserReportGoalsAndObjectives,
  NewUserReportSummary,
  NewUserReportPreview,
  NewUserReportShare,
  PulseQuestionSettings
} from 'src/pagesDashboard';
import OnboardingSetup from 'src/pagesDashboard/Onboarding/OnboardingSetup';
import OnboardingVideo from 'src/pagesDashboard/Onboarding/OnboardingVideo';
import OnboardingComms from 'src/pagesDashboard/Onboarding/OnboardingComms';
import OnboardingMSTeams from 'src/pagesDashboard/Onboarding/OnboardingMSTeams';
import OnboardingSlack from 'src/pagesDashboard/Onboarding/OnboardingSlack';
import OnboardingWebex from 'src/pagesDashboard/Onboarding/OnboardingWebex';
import OnboardingComplete from 'src/pagesDashboard/Onboarding/OnboardingComplete';
import EmailPulseReview from 'src/pagesDashboard/EmailPulseReview/EmailPulseReview';

import UserProfileProvider from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import TeamProvider from 'src/pagesDashboard/Team/context/TeamProvider';
import GoalsProvider from 'src/context/Goals/GoalsProvider';
import OrganizationProvider from 'src/pagesDashboard/Organization/context/OrganizationProvider';
import DashProvider from 'src/pagesDashboard/Dash/context/DashProvider';
import CompanyDashProvider from 'src/pagesDashboard/CompanyDash/context/Provider';
import DashTeamProvider from 'src/pagesDashboard/DashTeam/context/DashTeamProvider';
import SettingsProvider from 'src/pagesDashboard/Settings/context/SettingsProvider';
import DirectReviewProvider from 'src/pagesDashboard/DirectReview/context/DirectReviewProvider';
import PulseProvider from 'src/pagesDashboard/Pulse/context/PulseProvider';
import PulseQuestionResultsProvider from 'src/pagesDashboard/PulseQuestionResults/context/PulseQuestionResultsProvider';
import NewUserReportProvider from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';

import ReviewsDue from 'src/pagesDashboard/ReviewsDue/ReviewsDue';
import SubmitFeedback from 'src/containers/SubmitOrRequestFeedback/SubmitFeedback';
import RequestFeedback from 'src/containers/SubmitOrRequestFeedback/RequestFeedback';
import SelectGiveFeedback from 'src/pagesDashboard/SelectFeedback/SelectGiveFeedback';
import SelectRequestFeedback from 'src/pagesDashboard/SelectFeedback/SelectRequestFeedback';

import Admin from 'src/containers/Admin/Admin';
import AdminStats from 'src/pagesDashboard/AdminStats/AdminStats';
import Billing from 'src/pagesDashboard/Billing/Billing';
import QuestionBuilder from 'src/pagesDashboard/QuestionBuilder/QuestionBuilder';
import OrganizationSettings from 'src/pagesDashboard/OrganizationSettings/OrganizationSettings';
import FeedbackDashboard from 'src/containers/UserProfile/FeedbackDashboard/FeedbackDashboard';
import AnalyticsDashboard from 'src/containers/UserProfile/AnalyticsDashboard/AnalyticsDashboard';
import UserDashOverviewTab from 'src/containers/UserProfile/Overview/Overview';
import UserDashActivityTab from 'src/containers/UserProfile/Activity/Activity';
import MyDashReportsTab from 'src/containers/Dash/MyReports/MyReports';
import UserDashReportsTab from 'src/containers/UserProfile/Reports/Reports';
import UserDashGoalsTab from 'src/containers/UserProfile/Goals/Goals';
import UserDashAiAssistTab from 'src/containers/UserProfile/AiAssist/AiAssist';
import UserDashProfileTab from 'src/containers/UserProfile/Profile/Profile';
import CompanyDashFeedbackTab from 'src/pagesDashboard/CompanyDash/components/OrganizationFeedback';
import CompanyDashAnalyticsTab from 'src/pagesDashboard/CompanyDash/components/OrganizationAnalytics';
import CompanyDashReportsTab from 'src/pagesDashboard/CompanyDash/components/OrganizationReports';
import CompanyDashParticipationTab from 'src/pagesDashboard/CompanyDash/OrganizationParticipation';
import BulkReports from 'src/pagesDashboard/CompanyDash/components/BulkReports';
import ReviewDetails from 'src/containers/Reviews/ReviewDetails';
import FeedbackDetails from 'src/containers/Feedback/FeedbackDetails/FeedbackDetails';
import EditReview from 'src/containers/Reviews/EditReview';
import NewUserReportLayout from 'src/pagesDashboard/NewUserReport/layouts/NewUserReportLayout';
import PAGE_TYPES from 'src/constants/pageTypes';
import OrganizationEvents from 'src/pagesDashboard/OrganizationSettings/OrganizationEvents';
import COMMON_CONSTANTS from 'common/commonConstants';
import TaskList from 'src/pages/TaskList/TaskList';
import NotificationList from 'src/pages/NotificationList/NotificationList';
import PulseReviewsDue from 'src/pagesDashboard/PulseReviewDue/PulseReviewDue';
import HomeComponent from 'src/pagesDashboard/HomePage/HomePage';
import MobileHomeComponent from 'src/pagesDashboard/HomePage/components/mobile/HomePage';
import DirectFeedback from 'src/pagesDashboard/DirectFeedback/DirectFeedback';
import EditFeedback from 'src/containers/Feedback/FeedbackDetails/EditFeedback';
import { PerformanceBuilderProvider } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import BuilderErrorBoundary from 'src/pages/PerformanceBuilder/BuilderErrorBoundary';
import { ErrorUI } from 'src/components/index';
import MobileSubmitFeedback from 'src/containers/SubmitOrRequestFeedback/mobile/SubmitFeedback';
import MobileRequestFeedback from 'src/containers/SubmitOrRequestFeedback/mobile/RequestFeedback';
import MobileTeam from 'src/pagesDashboard/Team/mobile/Team';
import apiUtils from 'src/services/apiUtils';
import TransitionReview from '../pagesDashboard/TransitionReview/TransitionReview';

const {
  SIDEBAR_PAGES: {
    HOME,
    INBOX,
    DASHBOARDS,
    TRACKING,
    PEOPLE,
    PROFILE,
    ORGANIZATION
  }
} = COMMON_CONSTANTS;

const DashboardHomeRedirector = () => {
  const navigate = useNavigate();
  useEffect(() => navigate(appUtils.getHomeRoute()), []);
};

const DashboardMeRedirector = () => {
  const navigate = useNavigate();
  useEffect(() => navigate(appUtils.getDashRoute()), []);
};

const DashboardUserRedirector = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => navigate(appUtils.getDashRoute(id)), []);
};

const CompanyAnalyticsRedirection = () => {
  const navigate = useNavigate();
  useEffect(() => navigate('/dashboard/organization/analytics'), []);
};

// const isAdPage = () => /^\/$/.test(window.location.pathname);

// const DashPage = (props) => (
//   <DashProvider>
//     <Dash {...props} />
//   </DashProvider>
// );

const TaskListPage = (props) => <TaskList {...props} />;

const NotificationListPage = (props) => <NotificationList {...props} />;

const mobileSize = appUtils.getMobileSize();
const isMobile = mobileSize;

const HomePage = (props) => (isMobile ? <MobileHomeComponent {...props} /> : <HomeComponent {...props} />);

const SelfFeedbackDashboardPage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <FeedbackDashboard />
    </Dash>
  </DashProvider>
);

const UserFeedbackDashboardPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <FeedbackDashboard />
    </UserProfileV2>
  </UserProfileProvider>
);

const SelfAnalyticsDashboardPage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <AnalyticsDashboard />
    </Dash>
  </DashProvider>
);

const UserAnalyticsDashboardPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <AnalyticsDashboard />
    </UserProfileV2>
  </UserProfileProvider>
);

const DashOverviewPage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <UserDashOverviewTab />
    </Dash>
  </DashProvider>
);

const DashParticipationPage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <UserDashActivityTab />
    </Dash>
  </DashProvider>
);

const DashReportsPage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <MyDashReportsTab />
    </Dash>
  </DashProvider>
);

const DashGoalsPage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <UserDashGoalsTab />
    </Dash>
  </DashProvider>
);

const DashAiAssistPage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <UserDashAiAssistTab />
    </Dash>
  </DashProvider>
);

const DashProfilePage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <UserDashProfileTab />
    </Dash>
  </DashProvider>
);

const CompanyDashFeedbackPage = (props) => (
  <CompanyDashProvider>
    <DashCompany {...props}>
      <CompanyDashFeedbackTab />
    </DashCompany>
  </CompanyDashProvider>
);

const CompanyDashAnalyticsPage = (props) => (
  <CompanyDashProvider>
    <DashCompany {...props}>
      <CompanyDashAnalyticsTab />
    </DashCompany>
  </CompanyDashProvider>
);

const CompanyDashParticipationPage = (props) => (
  <CompanyDashProvider>
    <DashCompany {...props}>
      <CompanyDashParticipationTab />
    </DashCompany>
  </CompanyDashProvider>
);

const CompanyDashReportsPage = (props) => (
  <CompanyDashProvider>
    <DashCompany {...props}>
      <CompanyDashReportsTab />
    </DashCompany>
  </CompanyDashProvider>
);

const CompanyDashCreateReportsPage = (props) => (
  <CompanyDashProvider>
    <DashCompany {...props}>
      <BulkReports />
    </DashCompany>
  </CompanyDashProvider>
);

const GoalsPage = (props) => (
  <GoalsProvider>
    <Goals {...props} />
  </GoalsProvider>
);

const DashTeamPage = (props) => (
  <DashTeamProvider>
    <DashTeam {...props} />
  </DashTeamProvider>
);

const OrganizationPage = (props) => (
  <OrganizationProvider>
    <Organization {...props} />
  </OrganizationProvider>
);

const UserProfileOverviewPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <UserDashOverviewTab />
    </UserProfileV2>
  </UserProfileProvider>
);

const UserProfileParticipationPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <UserDashActivityTab />
    </UserProfileV2>
  </UserProfileProvider>
);

const UserProfileReportsPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <UserDashReportsTab />
    </UserProfileV2>
  </UserProfileProvider>
);

const UserProfileSettingsPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <UserDashProfileTab />
    </UserProfileV2>
  </UserProfileProvider>
);

const UserProfileGoalsPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <UserDashGoalsTab />
    </UserProfileV2>
  </UserProfileProvider>
);

const UserProfileAiAssistPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <UserDashAiAssistTab />
    </UserProfileV2>
  </UserProfileProvider>
);

const UserReportPage = (props) => (
  <UserProfileProvider>
    <UserReport {...props} />
  </UserProfileProvider>
);

const TeamPage = (props) => (isMobile ? (
  <TeamProvider>
    <MobileTeam props={props} />
  </TeamProvider>
) : (
  <TeamProvider>
    <Team props={props} />
  </TeamProvider>
));

const ReviewViewPage = (props) => <ReviewDetails {...props} />;

const FeedbackViewPage = (props) => <FeedbackDetails {...props} />;

const ReviewEditPage = (props) => <EditReview {...props} />;

const FeedbackEditPage = (props) => <EditFeedback {...props} />;

const SettingsPage = (props) => (
  <SettingsProvider>
    <Settings {...props} />
  </SettingsProvider>
);

const NotificationSettingsPage = (props) => <NotificationSettings {...props} />;

const DirectReviewPage = (props) => (
  <DirectReviewProvider>
    <DirectReview {...props} />
  </DirectReviewProvider>
);

const PulsePage = (props) => (
  <PulseProvider>
    <Pulse {...props} />
  </PulseProvider>
);

const PulseQuestionResultsPage = (props) => (
  <PulseQuestionResultsProvider>
    <PulseQuestionResults {...props} />
  </PulseQuestionResultsProvider>
);

const EmailReviewPage = (props) => <EmailReview {...props} />;
const EmailGoalPage = (props) => <EmailGoal {...props} />;

const EmailReviewTransitionPage = (props) => <TransitionReview {...props} />;

const EmailPulsePage = (props) => <EmailPulseReview {...props} />;

const ViewGoalPage = (props) => <GoalSettings {...props} mode='view' />;
const EditGoalPage = (props) => <GoalSettings {...props} mode='edit' />;
const NewGoalPage = (props) => <GoalSettings {...props} mode='new' />;

const EditReportTemplate = (props) => <ReportTemplates {...props} />;

const PerformanceBuilderPage = (props) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/builder') navigate(`/builder/new${search}`);
  }, [pathname, navigate, search]);

  return (
    <PerformanceBuilderProvider>
      <BuilderErrorBoundary {...props}>
        <Outlet />
      </BuilderErrorBoundary>
    </PerformanceBuilderProvider>
  );
};

const NewReportsFlow = (props) => (
  <NewUserReportProvider>
    <UserProfileProvider>
      <DashProvider>
        <NewUserReportLayout {...props} />
      </DashProvider>
    </UserProfileProvider>
  </NewUserReportProvider>
);

const EditPulsePage = (props) => (
  <PulseQuestionSettings {...props} mode='edit' />
);
const NewPulsePage = (props) => <PulseQuestionSettings {...props} mode='new' />;

const SubmitFeedbackFlow = (props) => (isMobile ? (
  <MobileSubmitFeedback {...props}>
    <SelectGiveFeedback type='feedback' {...props} />
  </MobileSubmitFeedback>
) : (
  <SubmitFeedback {...props}>
    <SelectGiveFeedback {...props} />
  </SubmitFeedback>
));

const RequestFeedbackFlow = (props) => (isMobile ? (
  <MobileRequestFeedback>
    <SelectRequestFeedback {...props} />
  </MobileRequestFeedback>
) : (
  <RequestFeedback>
    <SelectRequestFeedback {...props} />
  </RequestFeedback>
));

const redirectGetSpeedBackToWorkStory = () => {
  try {
    const { pathname } = window.location;
    const { search } = window.location;

    if (appUtils.isOnGetSpeedback()) {
      const newUrl = `https://app.workstory.team${pathname}${search}`;
      window.location.replace(newUrl);
    }
  } catch (error) {
    return null;
  }
};

const Home = () => <Outlet />;

const NoMatch = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      return window.location.replace('https://home.workstory.team/');
    }
    window.location.replace('/login');
  }, []);
  return <div>Redirecting...</div>;
};

const routes = ({ flags, permissions }) => createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Home />} errorElement={<ErrorUI />}>
      <Route
        path='/dashboard/onboarding/:inviteToken'
        element={<OnboardingSetup />}
      />
      <Route
        path='/dashboard/onboarding/video'
        element={<OnboardingVideo />}
      />
      <Route
        path='/dashboard/onboarding/comms'
        element={<OnboardingComms />}
      />
      <Route
        path='/dashboard/onboarding/msteams'
        element={<OnboardingMSTeams />}
      />
      <Route
        path='/dashboard/onboarding/slack'
        element={<OnboardingSlack />}
      />
      <Route
        path='/dashboard/onboarding/webex'
        element={<OnboardingWebex />}
      />
      <Route
        path='/dashboard/onboarding/complete'
        element={<OnboardingComplete />}
      />
      <Route
        path='/sign-up'
        element={<SignUp pageType={PAGE_TYPES.SIGNUP} />}
      />
      <Route path='/login' element={<SignUp pageType={PAGE_TYPES.LOGIN} />} />
      <Route
        path='/forgot-password'
        element={<SignUp pageType={PAGE_TYPES.FORGOT_PASSWORD} />}
      />
      <Route
        path='/recover-password/:token'
        element={<SignUp pageType={PAGE_TYPES.RECOVER_PASSWORD} />}
      />
      <Route
        path='/dashboard/home/*'
        element={<Dashboard component={HomePage} page={HOME} />}
      />
      <Route
        path='/dashboard/inbox/tasks'
        element={<Dashboard page={INBOX.TASKS} component={TaskListPage} />}
      />
      <Route
        path='/dashboard/inbox/notifications'
        element={(
          <Dashboard
            page={INBOX.NOTIFICATIONS}
            component={NotificationListPage}
          />
          )}
      />
      <Route
        path='/dashboard/inbox/reviews'
        element={
          <Dashboard page={INBOX.REVIEWS_DUE} component={ReviewsDue} />
          }
      />
      <Route
        path='/dashboard/inbox/pulse'
        element={
          <Dashboard page={INBOX.PULSE_DUE} component={PulseReviewsDue} />
          }
      />
      <Route
        path='/dashboard/kudos'
        element={<Dashboard component={Kudos} page='dashboard-kudos' />}
      />
      <Route
        path='/dashboard/reviews/due'
        element={<Dashboard component={ReviewsDue} />}
      />
      <Route
        path='/dashboard/submit-feedback/*'
        element={<Dashboard component={SubmitFeedbackFlow} />}
      />
      <Route
        path='/dashboard/request-feedback/*'
        element={<Dashboard component={RequestFeedbackFlow} />}
      />
      <Route
        path='/dashboard/organization/chart/*'
        element={(
          <Dashboard
            component={OrganizationPage}
            page={ORGANIZATION.ORGANIZATION_CHART}
          />
          )}
      />
      {/* <Route
      path='/dashboard/profile/:id/overview'
      element={<Dashboard component={UserProfileOverviewPage} />}
    /> */}
      {flags.showUserDashboardTabActivity ? (
        <Route
          path='/dashboard/profile/:id/participation'
          element={<Dashboard component={UserProfileParticipationPage} />}
        />
      ) : null}
      {flags.showUserDashboardTabAnalytics ? (
        <Route
          path='/dashboard/profile/:id/analytics'
          element={<Dashboard component={UserAnalyticsDashboardPage} />}
        />
      ) : null}
      <Route
        path='/dashboard/profile/:id/reports'
        element={<Dashboard component={UserProfileReportsPage} />}
      />
      <Route
        path='/dashboard/profile/:id/information/*'
        element={<Dashboard component={UserProfileSettingsPage} />}
      />
      {flags.showUserDashboardTabGoals ? (
        <Route
          path='/dashboard/profile/:id/goals'
          element={<Dashboard component={UserProfileGoalsPage} />}
        />
      ) : null}
      <Route
        path='/dashboard/profile/:id/ai'
        element={<Dashboard component={UserProfileAiAssistPage} />}
      />
      <Route
        path='/dashboard/people'
        element={<Dashboard component={TeamPage} page={PEOPLE.ALL_PEOPLE} />}
      />
      <Route
        path='/dashboard/goals'
        element={<Dashboard component={GoalsPage} page={TRACKING.GOALS} />}
      />
      <Route
        path='/dashboard/goal/view/:userId/:goalId'
        element={<Dashboard component={ViewGoalPage} />}
      />
      <Route
        path='/dashboard/goal/edit/:userId/:goalId'
        element={<Dashboard component={EditGoalPage} />}
      />
      <Route
        path='/dashboard/goal/new/:userId'
        element={<Dashboard component={NewGoalPage} />}
      />
      <Route
        path='/dashboard/me/feedback'
        element={(
          <Dashboard
            component={SelfFeedbackDashboardPage}
            page={DASHBOARDS.MY_DASHBOARD}
          />
          )}
      />
      <Route
        path='/dashboard/profile/:id/feedback'
        element={<Dashboard component={UserFeedbackDashboardPage} />}
      />
      {/* <Route
      path='/dashboard/me/overview'
      element={(
        <Dashboard
          component={DashOverviewPage}
          page={DASHBOARDS.MY_DASHBOARD}
        />
      )}
    /> */}
      <Route
        path='/dashboard/me/participation'
        element={(
          <Dashboard
            page={DASHBOARDS.MY_DASHBOARD}
            component={DashParticipationPage}
          />
          )}
      />
      {flags.showMydashboardTabAnalytics ? (
        <Route
          path='/dashboard/me/analytics'
          element={(
            <Dashboard
              component={SelfAnalyticsDashboardPage}
              page={DASHBOARDS.MY_DASHBOARD}
            />
            )}
        />
      ) : null}
      <Route
        path='/dashboard/me/reports'
        element={(
          <Dashboard
            component={DashReportsPage}
            page={DASHBOARDS.MY_DASHBOARD}
          />
          )}
      />
      <Route
        path='/dashboard/me/goals'
        element={(
          <Dashboard
            component={DashGoalsPage}
            page={DASHBOARDS.MY_DASHBOARD}
          />
          )}
      />
      <Route
        path='/dashboard/me/ai'
        element={(
          <Dashboard
            component={DashAiAssistPage}
            page={DASHBOARDS.MY_DASHBOARD}
          />
          )}
      />
      <Route
        path='/dashboard/me/information/*'
        element={(
          <Dashboard
            component={DashProfilePage}
            page={DASHBOARDS.MY_DASHBOARD}
          />
          )}
      />
      <Route
        path='/dashboard/team'
        element={(
          <Dashboard
            component={DashTeamPage}
            page={DASHBOARDS.TEAM_DASHBOARD}
          />
          )}
      />
      <Route
        path='/dashboard/organization/feedback'
        element={(
          <Dashboard
            component={CompanyDashFeedbackPage}
            page={DASHBOARDS.ORGANIZATION_DASHBOARD}
          />
          )}
      />
      <Route
        path='/dashboard/organization/analytics'
        element={(
          <Dashboard
            component={CompanyDashAnalyticsPage}
            page={DASHBOARDS.ORGANIZATION_DASHBOARD}
          />
          )}
      />
      <Route
        path='/dashboard/organization/participation'
        element={(
          <Dashboard
            component={CompanyDashParticipationPage}
            page={DASHBOARDS.ORGANIZATION_DASHBOARD}
          />
          )}
      />
      <Route
        path='/dashboard/organization/reports'
        element={(
          <Dashboard
            component={CompanyDashReportsPage}
            page={DASHBOARDS.ORGANIZATION_DASHBOARD}
          />
          )}
      />
      <Route
        path='/dashboard/organization/reports/create'
        element={(
          <Dashboard
            component={CompanyDashCreateReportsPage}
            page={DASHBOARDS.ORGANIZATION_DASHBOARD}
          />
          )}
      />
      <Route
        path='/dashboard/settings'
        element={
          <Dashboard component={SettingsPage} page={PROFILE.MY_SETTINGS} />
          }
      />
      <Route
        path='/dashboard/settings/notifications'
        element={(
          <Dashboard
            component={NotificationSettingsPage}
            page={PROFILE.MY_NOTIFICATIONS}
          />
          )}
      />
      <Route
        path='/dashboard/reviews/notify-participation/:revieweeId'
        element={<Dashboard component={NotifyRevieweeParticipation} />}
      />
      {permissions.isSuperUser ? (
        <>
          <Route
            path='/dashboard/admin'
            element={<Dashboard component={Admin} />}
          />

          <Route
            path='/dashboard/admin/stats'
            element={<Dashboard component={AdminStats} />}
          />
        </>
      ) : null}

      <Route
        path='/dashboard/organization/questions'
        element={(
          <Dashboard
            component={QuestionBuilder}
            page={ORGANIZATION.ORGANIZATION_QUESTIONS}
          />
          )}
      />
      <Route
        path='/dashboard/organization/settings'
        element={(
          <Dashboard
            component={OrganizationSettings}
            page={ORGANIZATION.ORGANIZATION_SETTINGS}
          />
          )}
      />
      <Route
        path='/dashboard/organization/events'
        element={(
          <Dashboard
            component={OrganizationEvents}
            page={ORGANIZATION.ORGANIZATION_EVENTS}
          />
          )}
      />
      <Route
        path='/dashboard/reportTemplate/add'
        element={<Dashboard component={EditReportTemplate} />}
      />
      <Route
        path='/dashboard/reportTemplate/edit/:templateId'
        element={<Dashboard component={EditReportTemplate} />}
      />
      <Route
        path='/dashboard/reviews/view/:reviewId'
        element={<Dashboard component={ReviewViewPage} />}
      />
      <Route
        path='/dashboard/reviews/edit/:reviewId'
        element={<Dashboard component={ReviewEditPage} />}
      />
      <Route
        path='/dashboard/feedback/view/:feedbackId'
        element={<Dashboard component={FeedbackViewPage} />}
      />
      <Route
        path='/dashboard/feedback/edit/:feedbackId'
        element={<Dashboard component={FeedbackEditPage} />}
      />
      <Route
        path='/dashboard/email-review/:id/:answerId/:score/:companyid'
        element={<EmailReviewPage />}
      />
      <Route
        path='/dashboard/email-review-transition'
        element={<EmailReviewTransitionPage />}
      />
      <Route
        path='/dashboard/email-goal/:goalId/:assignee/:companyid/:answerId'
        element={<EmailGoalPage />}
      />
      <Route
        path='/dashboard/submit-review/:revieweeId/:roleId/:questions'
        element={<Dashboard component={DirectReviewPage} />}
      />
      <Route
        path='/dashboard/submit-feedback/:reviewees/feedback'
        element={<Dashboard component={DirectFeedback} />}
      />
      <Route
        path='/dashboard/pulse'
        element={
          <Dashboard component={PulsePage} page={TRACKING.QUESTIONS} />
          }
      />
      <Route
        path='/dashboard/pulse/new'
        element={<Dashboard component={NewPulsePage} />}
      />
      <Route
        path='/dashboard/pulse/edit/:pulseQuestionId'
        element={<Dashboard component={EditPulsePage} />}
      />
      <Route
        path='/dashboard/pulse/:questionId'
        element={(
          <Dashboard
            component={PulseQuestionResultsPage}
            page={TRACKING.QUESTIONS}
          />
          )}
      />
      <Route
        path='/dashboard/pulse-review/:pulseId/:score/:label/:companyid'
        element={<EmailPulsePage />}
      />
      <Route
        path='/dashboard/profile/:userId/report/'
        element={(
          <Dashboard
            path='/dashboard/profile/:userId/report'
            component={NewReportsFlow}
            parentPage='team'
            sidebar={false}
          />
          )}
      >
        <Route
          path='dashboard/profile/:userId/report/new'
          element={<NewUserReport />}
        />
        <Route path=':reportId' element={<NewUserReport />} />
        <Route
          path=':reportId/open-feedback'
          element={<NewUserReportFeedback />}
        />
        <Route
          path=':reportId/performance-categories'
          element={<NewUserReportPerformanceCategories />}
        />
        <Route
          path=':reportId/goals-and-objectives'
          element={<NewUserReportGoalsAndObjectives />}
        />
        <Route
          path=':reportId/performance-summary'
          element={<NewUserReportSummary />}
        />
        <Route path=':reportId/preview' element={<NewUserReportPreview />} />
        <Route path=':reportId/share' element={<NewUserReportShare />} />
      </Route>
      <Route path='/builder' element={<PerformanceBuilderPage />}>
        <Route
          path='/builder/new'
          element={<PerformanceBuilder page='builder' />}
        />
        <Route
          path='/builder/:editToken/edit'
          element={<PerformanceBuilder page='edit' />}
        />
        <Route
          path='/builder/:reportId/view'
          element={<PerformanceBuilder page='view' />}
        />
        <Route
          path='/builder/:revieweeId/group-view/:cycleId?'
          element={<PerformanceBuilder page='group-view' />}
        />
        <Route
          path='/builder/dashboard'
          element={<PerformanceBuilder page='dashboard' />}
        />
      </Route>
      <Route
        path='/dashboard/billing'
        element={<Dashboard component={Billing} page='billing' />}
      />
      <Route
        path='/success'
        element={<Success pageType={PAGE_TYPES.CREATED_ACCOUNT} />}
      />
      <Route
        path='/success'
        element={<Success pageType={PAGE_TYPES.LOGGED_INTO_ACCOUNT} />}
      />
      <Route
        path='/verify/:token'
        element={<Success pageType={PAGE_TYPES.VERIFY_EMAIL} />}
      />
      <Route
        path='/saved-password'
        element={<Success pageType={PAGE_TYPES.UPDATED_PASSWORD} />}
      />
      <Route
        path='/recovery-email'
        element={<Success pageType={PAGE_TYPES.SENT_RECOVERY_PASS_EMAIL} />}
      />
      <Route path='/slack' element={<Slack />} />
      <Route path='/slack-intro' element={<SlackSignUp />} />
      <Route path='/beta/:name' element={<DocumentsRedirect />} />
      <Route path='/intro' element={<IntroRedirect />} />
      <Route path='/b/:name' element={<RedirectPage />} />
      <Route
        path='/dashboard'
        element={(
          <Dashboard
            component={DashboardHomeRedirector}
            page={DASHBOARDS.MY_DASHBOARD}
          />
          )}
      />
      <Route
        path='/dashboard/me/*'
        element={(
          <Dashboard
            component={DashboardMeRedirector}
            page={DASHBOARDS.MY_DASHBOARD}
          />
          )}
      />
      <Route
        path='/dashboard/profile/:id/*'
        element={<Dashboard component={DashboardUserRedirector} />}
      />
      <Route
        path='/dashboard/organization/*'
        element={(
          <Dashboard
            component={CompanyAnalyticsRedirection}
            page={DASHBOARDS.ORGANIZATION_DASHBOARD}
          />
          )}
      />
      <Route path='*' element={<NoMatch />} />
    </Route>
  )
);

const AppRoutes = () => {
  const handleRoute = (location) => {
    const pageRoute = get(location, 'pathname');
    if (location) {
      if (pageRoute === '/dashboard/login') {
        return window.location.replace('/login');
      }
      redirectGetSpeedBackToWorkStory();

      if (!pageRoute) {
        return;
      }

      // if (
      //   pageRoute === '/360-reviews'
      //   || pageRoute === '/feedback'
      //   || pageRoute === '/performance-reviews'
      //   || pageRoute === '/best-practices'
      //   || pageRoute === '/transparency'
      //   || pageRoute === '/slack'
      //   || pageRoute === '/blogs'
      //   || pageRoute === '/knowledge'
      //   || pageRoute === '/terms'
      //   || pageRoute === '/privacy'
      //   || pageRoute.match(/\/faq/)
      // ) {
      //   if (process.env.NODE_ENV === 'production') {
      //     return window.location.replace('https://home.workstory.team/');
      //   }
      //   return window.location.replace('/login');
      // }
    }
    window.scrollTo(0, 0);
  };

  const isSuperUser = appUtils.isSuperUser();

  const {
    showUserDashboardTabActivity,
    showUserDashboardTabGoals,
    showMydashboardTabAnalytics,
    showUserDashboardTabAnalytics
  } = useFlags();

  useEffect(() => {
    handleRoute(window.location);
  }, []);

  const permissions = {
    isSuperUser
  };

  const { pathname } = window.location;
  const isLoggedIn = appUtils.isLoggedIn();
  const loggedUser = appUtils.getLoggedUser();
  const token = get(loggedUser, 'jwt.token', null);

  if (!apiUtils.isPublicRoute(pathname) && (!isLoggedIn || !token)) {
    appUtils.logUserOut();
    return false;
  }

  return (
    <RouterProvider
      router={routes({
        flags: {
          showUserDashboardTabActivity,
          showUserDashboardTabGoals,
          showMydashboardTabAnalytics,
          showUserDashboardTabAnalytics
        },
        permissions
      })}
    />
  );
};

export default AppRoutes;
