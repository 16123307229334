import React, { useState, useEffect, useRef } from 'react';
import { Select, DateRange } from 'src/components/';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonDateUtils from 'common/commonDateUtils';

if (!window.speedChartList) {
  window.speedChartList = [];
}

export const LABEL_AS = {
  DEFAULT: 'default',
  WORDED: 'worded'
};

const FilterByDate = ({
  id,
  dateRangeSelected,
  onSelectDateRange,
  classes,
  topClasses,
  optionClasses,
  optionsWidth,
  containerClasses,
  wrapperClasses,
  prefix = 'Filter by',
  postfix = '',
  optionPrefix = '',
  optionPostfix = '',
  hideKeys = [],
  disabled = false,
  labelAs = LABEL_AS.DEFAULT
}) => {
  const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

  const dateRangeRef = useRef();

  const [showDateRange, setShowDateRange] = useState(false);

  const options = Object.values(DATE_RANGE_FILTERS)
    .filter((o) => !hideKeys.includes(o.key))
    .map((o) => {
      let labelText = o.label;

      if (labelAs === LABEL_AS.WORDED) {
        labelText = o.wordedLabel;
      }

      const label = `${optionPrefix} ${labelText} ${optionPostfix}`;
      return {
        ...o,
        label
      };
    });

  const selectedOption = options.find((o) => o.key === dateRangeSelected.value);

  let title = '';

  if (selectedOption.key !== 'custom') {
    let labelText = selectedOption.label;

    if (labelAs === LABEL_AS.WORDED) {
      labelText = selectedOption.wordedLabel;
    }

    title = `${prefix} ${labelText} ${postfix}`;
  } else {
    title += `${commonDateUtils.dateToMonthDayYearFormat(
      new Date(dateRangeSelected.start * 1000)
    )}`;
    if (
      !dateRangeSelected.end
      || commonDateUtils.isUnixToday(dateRangeSelected.end)
    ) {
      title += ' - Today';
    } else {
      title += ` - ${commonDateUtils.dateToMonthDayYearFormat(
        new Date(dateRangeSelected.end * 1000)
      )}`;
    }
  }

  const today = new Date();
  const parsedToday = Date.parse(today);

  useEffect(() => {
    if (showDateRange && dateRangeRef.current) {
      dateRangeRef.current.click();
    }
  }, [showDateRange, dateRangeRef.current]);

  const startAndEndDates = {
    [DATE_RANGE_FILTERS.LAST_MONTH.key]: {
      start: commonDateUtils.getDateFromDaysAgo(
        DATE_RANGE_FILTERS.LAST_MONTH.days
      ).unix,
      end: commonDateUtils.getUnixDateNow()
    },
    [DATE_RANGE_FILTERS.THREE_MONTHS.key]: {
      start: commonDateUtils.getDateFromDaysAgo(
        DATE_RANGE_FILTERS.THREE_MONTHS.days
      ).unix,
      end: commonDateUtils.getUnixDateNow()
    },
    [DATE_RANGE_FILTERS.SIX_MONTHS.key]: {
      start: commonDateUtils.getDateFromDaysAgo(
        DATE_RANGE_FILTERS.SIX_MONTHS.days
      ).unix,
      end: commonDateUtils.getUnixDateNow()
    },
    [DATE_RANGE_FILTERS.ONE_YEAR.key]: {
      start: commonDateUtils.getDateFromDaysAgo(
        DATE_RANGE_FILTERS.ONE_YEAR.days
      ).unix,
      end: commonDateUtils.getUnixDateNow()
    },
    [DATE_RANGE_FILTERS.ALL_TIME.key]: {}
  };

  return (
    <>
      <Select
        id={id}
        disabled={disabled}
        variant='shadow'
        containerClasses={containerClasses ?? ''}
        wrapperClasses={wrapperClasses ?? ''}
        customVariantClasses={topClasses ?? ''}
        optionClasses={optionClasses ?? ''}
        optionsWidth={optionsWidth ?? 'standard'}
        classes={classes ?? 'w-60'}
        title={title}
        options={options}
        onChange={(option) => {
          if (option.key === 'custom') {
            setShowDateRange(true);
            return;
          }
          const { start, end } = startAndEndDates[option.key];
          onSelectDateRange(
            option.key,
            start ? parseInt(start, 10) : start,
            end ? parseInt(end, 10) : end
          );
        }}
      />

      {showDateRange ? (
        <div
          className='relative -ml-[calc(276px-100%)]'
          id='filterByDate-daterange-container'
        >
          <DateRange
            ref={dateRangeRef}
            parentElId='filterByDate-daterange-container'
            calendarPosition='bottom'
            datePickerClasses='litepicker-top-8'
            containerClasses='m-0'
            inputClasses='hidden'
            iconClasses='hidden'
            innerClasses='float-right'
            maxDate={parsedToday}
            startDate={null}
            endDate={null}
            onSelect={(startArg, endArg) => {
              const startUnix = startArg
                ? commonDateUtils.getFirstUnixOfDateFromUnix(
                  commonDateUtils.dateToUnix(startArg)
                )
                : null;
              const endUnix = endArg
                ? commonDateUtils.getLastUnixOfDateFromUnix(
                  commonDateUtils.dateToUnix(endArg)
                )
                : null;

              onSelectDateRange(
                'custom',
                parseInt(startUnix, 10),
                parseInt(endUnix, 10)
              );
              setShowDateRange(false);
            }}
            onHide={() => setShowDateRange(false)}
          />
        </div>
      ) : null}
    </>
  );
};

export default FilterByDate;
