import React, { useEffect } from 'react';
import uuidv4 from 'uuid/v4';
import { Controller, useFieldArray } from 'react-hook-form';
import { Select, XButton } from 'src/components/index';
import commonQuestions from 'common/commonQuestions';
import { MC_ANSWER_CHOICES } from 'src/pagesDashboard/Pulse/containers/PulseQuestion/context/constants';
import PULSE_CONSTANTS from 'common/pulseConstants';

const PulseChoiceSection = ({
  id,
  disabled,
  labelText,
  errorMessage,
  formData,
  mode
}) => {
  const {
    control, setValue, getValues, register, watch
  } = formData;
  const { fields, append, remove } = useFieldArray({
    name: 'answerChoices',
    control
  });
  const formValues = watch();

  const { answerType, answerChoices } = formValues;

  const renderChoiceSelect = () => {
    if (answerType.key === PULSE_CONSTANTS.TYPES.MC && answerChoices) {
      return (
        <div className='mb-10 md:mb-16 flex justify-between flex-col md:flex-row'>
          <label
            htmlFor={id}
            className='text-gray-500 mb-4 md:my-auto uppercase font-bold text-xl'
          >
            {labelText}
          </label>
          <div className='w-full max-w-34rem'>
            <Controller
              name='answerChoices'
              control={control}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <div className='w-full inline-block align-top'>
                    <Select
                      id='pulse-choice-section-answer-choices-filter'
                      disabled={disabled}
                      variant='shadow'
                      title={value.label}
                      options={MC_ANSWER_CHOICES}
                      onChange={onChange}
                    />
                  </div>
                );
              }}
            />
            <div className='text-red whitespace-no-wrap flex items-center mt-2 absolute'>
              {errorMessage}
            </div>
          </div>
        </div>
      );
    }
    if (
      answerType.key === PULSE_CONSTANTS.TYPES.CUSTOM
      && Array.isArray(answerChoices)
    ) {
      return (
        <div className='mb-10 md:mb-16 flex justify-between flex-col md:flex-row'>
          <label
            htmlFor={id}
            className='text-gray-500 mb-4 md:mb-auto mt-4 uppercase font-bold text-xl'
          >
            {labelText}
          </label>
          <div className='w-full max-w-34rem'>
            {fields.map((field, index) => (
              <div className='mt-4 ml-1 flex' key={`${field.id}-choice`}>
                <input
                  disabled={disabled}
                  type='text'
                  placeholder={`Answer ${index + 1}`}
                  className='w-full'
                  {...register(`answerChoices.${index}.label`, {
                    required: 'Choice is required'
                  })}
                />
                {mode === 'new' && fields.length > 2 ? (
                  <div className='align-top inline-block'>
                    <XButton
                      onClick={() => {
                        remove(index);
                      }}
                    />
                  </div>
                ) : null}
              </div>
            ))}
            {mode === 'new' && answerChoices.length < 10 ? (
              <button
                className='font-bold mt-3 ml-1 focus:outline-none text-purple text-underline'
                type='button'
                onClick={() => {
                  append({
                    id: uuidv4(),
                    score: commonQuestions
                      .getScoreAtIndex(answerChoices, answerChoices.length + 1)
                      .toString(),
                    label: ''
                  });
                }}
              >
                Add Answer
              </button>
            ) : null}
          </div>
          <div className='text-red whitespace-no-wrap flex items-center mt-2 absolute'>
            {errorMessage}
          </div>
        </div>
      );
    }
    return null;
  };

  return <section>{renderChoiceSelect()}</section>;
};
export default PulseChoiceSection;
