import React from 'react';

const ProgressBar = ({
  progress = 0,
  outerClasses = null,
  barClasses = null,
  tooltip = true
}) => {
  let progressValue = progress;
  if (progress > 100) progressValue = 100;
  if (progress < 0) progressValue = 0;
  return (
    <div className={`${outerClasses || 'p-1 w-24 h-4'} tooltip`}>
      {tooltip && (
        <span className='bg-black text-white tooltip-text -mt-8'>
          {`${progressValue}%`}
        </span>
      )}
      <div className='flex overflow-hidden h-full w-full rounded-lg bg-transparent-grey'>
        <div
          style={{ width: `${progressValue}%` }}
          className={`${barClasses || 'bg-purple'} h-full`}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
