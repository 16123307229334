import React, { useState } from 'react';
import { useReviews } from 'src/queries/reviews';
import { useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import commonDateUtils from 'common/commonDateUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import {
  Base, toast, Select, XButton, Pagination
} from 'src/components/';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';

const { REVIEW_STATUS } = COMMON_CONSTANTS;

const STATUS_OPTIONS = Object.values(REVIEW_STATUS).map((v) => ({
  id: v,
  value: v,
  label: v
}));
const DEFAULT_STATUS_OPTION = STATUS_OPTIONS.find((v) => v.id === 'Sent');

const yesterdayUnix = commonDateUtils.getUnixDateNow() - 60 * 60 * 24;
const now = commonDateUtils.getUnixDateNow();
const initialStartDate = commonDateUtils.unixToTimedateFormat(yesterdayUnix);
const initialEndDate = commonDateUtils.unixToTimedateFormat(now);

const ReviewsTable = () => {
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const [filters, setFilters] = useState({
    status: DEFAULT_STATUS_OPTION,
    startDate: initialStartDate,
    endDate: initialEndDate
  });

  const {
    data: { reviews, pagination },
    isFetching: isFetchingReviews,
    isError: isErrorReviews
  } = useReviews({
    companyId: company.id,
    filters: {
      status: filters.status.value,
      ...(filters.startDate
        ? { start: commonDateUtils.dateToUnix(filters.startDate) }
        : null),
      ...(filters.endDate
        ? { end: commonDateUtils.dateToUnix(filters.endDate) }
        : null)
    },
    options: {
      page: 1,
      size: 10
    }
  });

  const isFetching = isFetchingCompany || isFetchingReviews || isFetchingTree;
  const isError = isErrorCompany || isErrorReviews || isErrorTree;
  const isReady = company
    && company.id
    && tree
    && tree.id
    && reviews
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  return (
    <Base classes={STYLE.CONTAINER_WHITE} loading={false}>
      <h5 className='font-bold'>Reviews</h5>
      <div>
        <div className='w-1/2 inline-block text-left'>
          <p className='mb-0'>Superuser view of reviews</p>
          <p className='font-bold'>
            Number of reviews:
            {pagination?.total}
          </p>
        </div>
        <div className='w-1/2 inline-block text-right align-top'>
          <p className='text-purple font-bold'>Superuser View Only</p>
        </div>
      </div>

      <div className='mb-2'>
        <div className='align-top inline-block mr-3'>
          <div className='inline-block align-top w-40'>
            <Select
              id='reviews-table-status-filter'
              title={filters?.status?.label || 'Filter by status'}
              options={STATUS_OPTIONS}
              onChange={(option) => {
                setFilters({ ...filters, status: option });
              }}
            />
          </div>

          <div className='inline-block ml-3'>
            <span className='inline-block mx-2 mt-1'>From</span>
            <input
              onChange={(e) => {
                setFilters({
                  ...filters,
                  startDate: e.target.value
                });
              }}
              value={filters.startDate}
              type='datetime-local'
              min='09:00'
              max='23:00'
              required
            />
          </div>

          <div className='inline-block'>
            <span className='inline-block mx-2 mt-1'>To</span>
            <input
              onChange={(e) => {
                console.log('ss', e.target.value);

                setFilters({
                  ...filters,
                  endDate: e.target.value
                });
              }}
              value={filters.endDate}
              type='datetime-local'
              min='09:00'
              max='23:00'
              required
            />
          </div>
        </div>
      </div>

      <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'>
        <p className='mb-0 pl-1 w-1/6 inline-block font-bold'>Id</p>
        <p className='mb-0 w-1/6 inline-block font-bold'>Your timezone</p>
        <p className='mb-0 w-1/6 inline-block font-bold'>GMT</p>
        <p className='mb-0 w-1/6 inline-block font-bold'>Reviewer</p>
        <p className='mb-0 w-1/6 inline-block font-bold'>Reviewee</p>
        <p className='mb-0 w-1/6 inline-block font-bold'>Run, Delete</p>
      </div>

      <div className='min-h-18rem'>
        {reviews
          && reviews.map((review) => {
            const reviewerNode = commonTreeUtils.findNodeById(
              tree,
              review.reviewer
            );
            const revieweeNode = commonTreeUtils.findNodeById(
              tree,
              review.reviewee
            );
            const scheduledDateTimestamp = commonDateUtils.dateToUnix(
              new Date(review.scheduledDate)
            );
            const myDate = commonDateUtils.unixToMonthDayYearTimeFormat(
              scheduledDateTimestamp
            );
            const gmtDate = commonDateUtils.unixToMonthDayYearTimeFormatUTC(
              scheduledDateTimestamp
            );
            return (
              <div>
                <p
                  onClick={() => {
                    appUtils.copyTextToClipboard(review._id);
                  }}
                  className='cursor-pointer pl-2 w-1/6 inline-block truncate overflow-ellipsis'
                >
                  {review._id}
                </p>
                <p className='w-1/6 inline-block'>{myDate}</p>
                <p className='w-1/6 inline-block'>{gmtDate}</p>
                <p className='w-1/6 inline-block truncate overflow-ellipsis'>
                  {reviewerNode?.name}
                </p>
                <p className='w-1/6 inline-block truncate overflow-ellipsis'>
                  {revieweeNode?.name}
                </p>
              </div>
            );
          })}
      </div>
      {/* {
        !isFetchingReviews ? (
          <Pagination
            name='Reviews'
            totalPages={pagination.pages}
            currentPage={pagination.current}
            selectPage={(e) => { setOptions({ ...options, page: parseInt(e, 10) }) }}
            isLoading={isFetchingReviews}
          />
        ) : null
      } */}
    </Base>
  );
};

export default ReviewsTable;
