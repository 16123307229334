import React, { useContext, useState } from 'react';
import { get } from 'lodash';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import { appUtils, Base } from 'src/components/';
import { Table, AlertModal } from 'src/componentsTailwind/index';
import { TYPES } from 'src/componentsTailwind/Table/Table';
import CircledIcon from 'src/components/CircledIcon/CircledIcon';
import CircleV2 from 'src/components/Circle/CircleV2';
import { useAccountsParticipation } from 'src/queries/account';
import GrowingBarChartSVG from 'src/assets/svg/growing-bar-chart.svg';
import STYLE from 'src/constants/style';
import COMMON_CONSTANTS from 'common/commonConstants';

const { USER_STATE } = COMMON_CONSTANTS;

const ReviewPartipationRanking = () => {
  const loggedUserId = appUtils.getLoggedUserId();
  const { context, updateContext } = useContext(CompanyDashContext);

  const {
    range, filters, currentPages, currentSort
  } = context;
  const { managerId } = filters;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserAccount, setSelectedUserAccount] = useState(null);

  const currentPage = currentPages.reviewParticipationRanking;
  const sort = currentSort.reviewParticipationRanking;
  const updateSort = (field, newSort) => updateContext({
    currentSort: {
      ...currentSort,
      reviewParticipationRanking: { field, order: newSort }
    },
    currentPages: {
      ...currentPages,
      reviewParticipationRanking: 1
    }
  });
  const updatePage = (newPage) => updateContext({
    currentPages: {
      ...currentPages,
      reviewParticipationRanking: newPage
    }
  });

  const {
    data: reviewers,
    meta: {
      page: { totalPages, totalItems }
    },
    isFetching: isFetchingAccountsParticipation
  } = useAccountsParticipation(
    {
      managerId,
      start: range.start,
      end: range.end,
      notStatus: [USER_STATE.UNASSIGNED]
    },
    {
      page: {
        number: currentPage,
        size: 10
      },
      sort: {
        field: sort.field,
        order: sort.order
      },
      include: {
        account: true
      }
    }
  );

  const columns = [
    {
      label: 'Rank',
      tooltip: false,
      span: 0.5
    },
    {
      label: 'Name',
      tooltip: true
    },
    {
      label: 'Title',
      tooltip: true
    },
    {
      label: 'Reviews Completed',
      tooltip: false
    },
    {
      label: 'Feedback Rate',
      tooltip: false,
      sort: {
        ...sort,
        isSelected: sort.field === 'completionAverage'
      },
      type: TYPES.SORTABLE,
      setSort: (order) => updateSort('completionAverage', order)
    },
    {
      label: 'Skip Rate',
      tooltip: false,
      sort: { ...sort, isSelected: sort.field === 'skipRate' },
      type: TYPES.SORTABLE,
      setSort: (order) => updateSort('skipRate', order),
      span: 0.5
    }
  ];

  const rows = reviewers.map((user, index) => {
    const {
      account: {
        _id: userId, name, title, imageUrl
      },
      completionAverage,
      reviewed,
      sent,
      skipRate
    } = user;

    let rankLabel = null;

    if (sort.order === 'asc') {
      rankLabel = totalItems - (currentPage - 1) * 10 - index;
    } else {
      rankLabel = (currentPage - 1) * 10 + index + 1;
    }

    const reviewsCompletedLabel = sent !== 0 ? `${reviewed} / ${sent}` : 0;
    const reviewsCompletedProgress = sent !== 0 ? Math.trunc((reviewed / sent) * 100) : 0;
    const completionLabel = completionAverage ? `${completionAverage}%` : `0%`;
    const skipRateLabel = skipRate ? `${skipRate}%` : '0%';
    const profileUrl = loggedUserId === userId
      ? `/dashboard/me/participation`
      : `/dashboard/profile/${userId}/participation`;

    return [
      { label: rankLabel, labelClasses: 'font-bold' },
      {
        image: imageUrl,
        label: name,
        type: TYPES.IMAGE_WITH_TEXT,
        onClick: () => {
          setSelectedUserAccount({
            id: userId,
            name,
            firstName: name.split(' ')[0],
            imageUrl,
            profileUrl
          });
          setIsModalOpen(true);
        }
      },
      {
        label: title
      },
      {
        label: reviewsCompletedLabel,
        type: TYPES.PROGRESS_BAR,
        progress: reviewsCompletedProgress
      },
      {
        label: completionLabel
      },
      {
        label: skipRateLabel
      }
    ];
  });

  const pagination = {
    currentPage,
    setCurrentPage: updatePage,
    totalPages
  };

  const isFetching = isFetchingAccountsParticipation;

  return (
    <>
      <AlertModal
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
        onAction={() => {
          window.open(selectedUserAccount.profileUrl, '_blank');
          setIsModalOpen(false);
          setSelectedUserAccount(null);
        }}
        title={get(selectedUserAccount, 'name', 'View More Details')}
        content={`View ${get(selectedUserAccount, 'firstName', 'this user')}'s dashboard see more details.`}
        actionText='View Dashboard'
        isLoading={isFetching}
        type='success'
        customIcon={(
          <CircleV2
            imageUrl={get(selectedUserAccount, 'imageUrl', null)}
            size='sm'
          />
        )}
      />
      <Base
        classes={`${STYLE.CONTAINER_WHITE} !p-4`}
        relative
        loading={isFetchingAccountsParticipation}
      >
        <CircledIcon svg={<GrowingBarChartSVG className='text-purple' />} />
        <p className='text-xl text-black font-bold mb-0 mr-8'>
          Review Participation Ranking
        </p>
        <p className='text-md font-normal my-2 leading-5 text-bluish-gray mb-6'>
          Ranking Team Members based on review completion rates.
        </p>
        <Table
          rowClasses='!px-0 [&>*:first-child]:!pl-4 [&>*:last-child]:!pr-0'
          headClasses='[&_*]:!text-sm [&_*]:!truncate [&>*:first-child]:!pl-0 [&>*:last-child]:!pr-0'
          columns={columns}
          rows={rows}
          pagination={pagination}
        />
      </Base>
    </>
  );
};
export default ReviewPartipationRanking;
