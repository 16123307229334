/* eslint-disable react/no-array-index-key */
import React, { cloneElement, useMemo } from 'react';
import { get } from 'lodash';
import DOMPurify from 'dompurify';
import { appUtils, Button, Select } from 'src/components/index';
import ProfileCircleSVG from 'src/assets/svg/profileCircle.svg';

export const ITEM_TYPES = {
  TEXT: 'text',
  INPUT: 'input',
  EMPTY: 'empty'
};

export const INPUT_ACTION_TYPES = {
  SUBMIT: 'submit',
  SELECT: 'select'
};

const renderCircle = ({ imageSrc, icon }) => (
  <div className='flex min-w-fit min-h-fit py-2 pr-2 items-center justify-center relative z-10 bg-white overflow-hidden'>
    {imageSrc && (
      <img
        src={imageSrc}
        className='min-w-[48px] max-w-[48px] min-h-[48px] max-h-[48px] rounded-full grow-0 shrink-0'
        alt='default'
      />
    )}
    {icon
      && cloneElement(icon, {
        className:
          'min-w-[48px] max-w-[48px] min-h-[48px] max-h-[48px] rounded-full grow-0 shrink-0'
      })}
  </div>
);

const RenderItem = ({ item }) => useMemo(() => {
  const {
    type, label, underlabel, imageSrc, icon
  } = item;

  if (type === ITEM_TYPES.EMPTY) {
    return (
      <div className='group relative flex items-start w-full'>
        {renderCircle({ imageSrc: appUtils.getProfilePictureSrc() })}
        <span className='flex flex-col'>
          <span className='text-sm text-black font-bold leading-5 m-0 [&_p]:text-sm [&_p]:text-black [&_p]:font-bold [&_p]:leading-5 [&_p]:m-0'>
            {label || 'No data available'}
          </span>
          <span className='text-xs font-medium text-gray-500 leading-5 m-0 [&_p]:text-xs [&_p]:font-medium [&_p]:text-gray-500 [&_*]:leading-5 [&_p]:m-0'>
            {underlabel || 'No data available'}
          </span>
        </span>
      </div>
    );
  }

  let component = <span className='w-[100px] h-[50px] bg-mid-gray' />;

  let circle = <ProfileCircleSVG />;
  if (icon) circle = renderCircle({ icon });
  if (imageSrc) {
    circle = renderCircle({ imageSrc });
  }

  if (type === ITEM_TYPES.TEXT) {
    const { text } = item;
    const sanitizedText = () => ({
      __html: DOMPurify.sanitize(text)
    });
    component = (
      <div
        data-test-id='text'
        className='ql-editor p-0 m-0 [&_p]:m-0'
        dangerouslySetInnerHTML={sanitizedText()}
      />
    );
  }

  if (type === ITEM_TYPES.INPUT) {
    const {
      placeholder = '',
      actions = [],
      value = '',
      onChange = () => {},
      disabled = false,
      id = null
    } = item;
    component = (
      <div className='flex flex-col gap-1 ml-1'>
        <textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          className='w-full h-28 p-2 border border-mid-gray rounded-lg resize-none'
          disabled={disabled}
          {...(id ? { id } : {})}
        />
        <div className='flex justify-end gap-1 mt-2'>
          {actions.map((action) => {
            const {
              label: actionLabel,
              type: actionType,
              disabled: actionDisabled
            } = action;

            if (actionType === INPUT_ACTION_TYPES.SUBMIT) {
              const { onClick: onClickSubmit = () => {} } = action;
              return (
                <Button
                  key={`action-${actionLabel}`}
                  variant='purple-2'
                  disabled={actionDisabled}
                  onClick={onClickSubmit}
                  classes='!py-1 ml-2'
                >
                  {actionLabel}
                </Button>
              );
            }

            if (actionType === INPUT_ACTION_TYPES.SELECT) {
              const {
                onChange: onChangeSelect = () => {},
                options: selectOptions = [],
                id: selectId = null,
                showSearch = false,
                onSearch = null,
                onDropdownClose = null
              } = action;
              return (
                <div key={`action-${actionLabel}`}>
                  <Select
                    id={`render-item-${actionLabel}-filter`}
                    disabled={actionDisabled}
                    title={actionLabel}
                    options={selectOptions}
                    onChange={onChangeSelect}
                    showSearch={showSearch}
                    onSearch={onSearch}
                    onDropdownClose={onDropdownClose}
                    classes='w-60'
                    optionsWidth='fit'
                    maxOptionWidth='500px'
                    {...(id ? { id: selectId } : {})}
                  />
                </div>
              );
            }

            return null;
          })}
        </div>
      </div>
    );
  }

  return (
    <div
      data-test-row-type={type}
      data-test-row-label={label}
      data-test-row-text={get(item, 'text', '')}
      className='group relative flex items-start w-full'
    >
      {circle}
      <span className='flex flex-col w-full'>
        <span
          data-test-id='label'
          className='text-sm text-black font-bold leading-5 m-0 [&_p]:text-sm [&_p]:text-black [&_p]:font-bold [&_p]:leading-5 [&_p]:m-0'
        >
          {label}
        </span>
        <span className='text-xs font-medium text-gray-500 leading-5 m-0 [&_p]:text-xs [&_p]:font-medium [&_p]:text-gray-500 [&_*]:leading-5 [&_p]:m-0'>
          {underlabel}
        </span>
        <div className='rounded-md w-fit h-fit'>{component}</div>
      </span>
    </div>
  );
}, [item]);

const CircleItems = ({ items = [] }) => (
  <ol className='w-full h-full pt-2'>
    {items.map((item, index) => (
      <li
        key={`item-${item.id}`}
        className={`relative ${index !== items.length - 1 ? 'pb-8' : ''}`}
      >
        {index !== items.length - 1 ? (
          <div className='absolute left-[23px] h-full w-0.5 bg-mid-gray' />
        ) : null}
        <RenderItem item={item} />
      </li>
    ))}
  </ol>
);

export default CircleItems;
