import React, { useEffect, useRef, useState } from 'react';
import {
  Base, Button, Select, toast
} from 'src/components';
import {
  formatNoteText,
  isNoteEmpty
} from 'src/pagesDashboard/NewUserReport/utils';
import Quill from 'src/components/Quill/Quill';
import COMMON_CONSTANTS from 'common/commonConstants';

const { REPORT_NOTE_TYPES } = COMMON_CONSTANTS;

const AddNoteNewReport = ({
  reportId,
  noteType,
  includeText = '',
  clearIncludeText = () => {},
  meta = {},
  parentValues = {},
  isOpenByDefault,
  onFieldOpen,
  onSave,
  templatesOptions,
  generateNoteCallback = async () => {},
  isGenerateNoteLoading,
  templateSelectorId
}) => {
  const noteRef = useRef();
  const scrollTriggerRef = useRef(false);

  const [showTextEditor, setShowTextEditor] = useState(false);
  const [text, setText] = useState('');
  const [noteTemplateId, setNoteTemplateId] = useState(null);

  const scrollToNote = () => {
    if (noteRef.current) {
      const { x, y } = noteRef.current.getBoundingClientRect();
      scrollTo(x, window.scrollY + y - 100);
    }
  };

  const onChangeNote = (note) => {
    setText(note);
  };

  useEffect(() => {
    if (showTextEditor) onFieldOpen();
  }, [showTextEditor]);

  useEffect(() => {
    if (!showTextEditor && includeText.length) {
      setShowTextEditor(true);
      scrollToNote();
      scrollTriggerRef.current = !scrollTriggerRef.current;
    }

    if (includeText.length) {
      const newText = text + includeText;
      setText(newText);
      scrollToNote();
      scrollTriggerRef.current = !scrollTriggerRef.current;
    }
  }, [includeText]);

  const noteTemplateTitle = noteTemplateId
    ? templatesOptions.find((a) => a.id === noteTemplateId)?.label
    : 'No Template';

  const onChangeTemplate = (templateId) => {
    // when we clear the React-Quill text area, it still keeps the string '<p><br></p>')
    if (!isNoteEmpty(text)) {
      const answer = confirm('You are going to lose your current note content');
      if (!answer) {
        return;
      }
    }
    setNoteTemplateId(templateId);
    const templateContent = templatesOptions.find(
      (a) => a.id === templateId
    )?.content;
    setText(templateContent);
  };

  useEffect(() => {
    if (isOpenByDefault) {
      setShowTextEditor(true);
    }
  }, [isOpenByDefault]);

  const { noteCount, dataCount } = parentValues;
  const isGenerateAiNoteDisabled = isGenerateNoteLoading
    || ([
      REPORT_NOTE_TYPES.FEEDBACK,
      REPORT_NOTE_TYPES.CATEGORY,
      REPORT_NOTE_TYPES.GOAL
    ].includes(noteType)
      && (noteCount >= 5 || dataCount < 5));

  const openTextEditor = () => {
    setShowTextEditor(true);
    scrollToNote();
  };

  const closeTextEditor = () => {
    setShowTextEditor(false);
    setText('');
    const { x } = noteRef.current.getBoundingClientRect();
    scrollTo(x, window.scrollY - 500);
  };

  const onClickGenerateNote = async () => {
    try {
      const result = await generateNoteCallback();
      const { note } = result;
      setText(note);
      openTextEditor();
    } catch (error) {
      toast.error('AI note generation failed! Try again later.');
    }
  };

  return (
    <>
      <div
        className={`flex justify-center gap-10 ${showTextEditor ? 'invisible h-0' : 'visible'}`}
      >
        <button
          type='button'
          onClick={openTextEditor}
          className='shadow text-center bg-success-green hover:bg-success-green-hover disabled:bg-[#bcbdbd] text-white text-md cursor-pointer
      hover:text-white font-bold transition-colors duration-300 rounded p-3 px-3 mb-5 max-w-180 self-center'
          disabled={isGenerateNoteLoading}
        >
          Add Additional Note
        </button>
        {noteType !== REPORT_NOTE_TYPES.SUMMARY ? (
          <button
            type='button'
            onClick={onClickGenerateNote}
            className='shadow text-center bg-success-blue hover:bg-success-blue-hover disabled:bg-[#bcbdbd] text-white text-md cursor-pointer
      hover:text-white font-bold transition-colors duration-300 rounded p-3 px-3 mb-5 min-w-[205.583px] self-center tooltip'
            disabled={isGenerateAiNoteDisabled}
          >
            {isGenerateAiNoteDisabled && !isGenerateNoteLoading ? (
              <span className='bg-black text-white tooltip-text ml-[205.583px] -mt-3'>
                <div className='h-full flex flex-col items-start'>
                  <p className='m-0'>Each section requires</p>
                  <div className='flex flex-col items-start'>
                    <ul className='list-outside list-disc ml-5 mb-0'>
                      <li className='text-justify'>
                        <p className='m-0'>
                          at least
                          <span className='font-bold'> 5 </span>
                          pieces of input
                        </p>
                      </li>
                      <li className='text-justify'>
                        <p className='m-0'>
                          no more than
                          <span className='font-bold'> 5 </span>
                          notes
                        </p>
                      </li>
                    </ul>
                    <p className='m-0'>to allow for AI note generation</p>
                  </div>
                </div>
              </span>
            ) : null}
            {isGenerateNoteLoading
              ? 'Generating Note...'
              : 'Generate Note with AI ✨'}
          </button>
        ) : null}
      </div>
      <Base
        ref={noteRef}
        id={`add-note-${reportId}`}
        className={`${showTextEditor ? 'visible' : 'invisible h-0'}`}
      >
        <div
          className={`flex flex-col gap-4 mt-6 w-full justify-center text-black
        ${
          showTextEditor
            ? ''
            : 'cursor-pointer hover:bg-purple hover:text-white font-bold'
        } transition-colors duration-300 rounded pt-3 h-35rem`}
        >
          {templatesOptions && templatesOptions.length ? (
            <Select
              id={templateSelectorId}
              options={templatesOptions}
              title={noteTemplateTitle}
              onChange={(option) => onChangeTemplate(option.id)}
              classes='!md:w-72'
            />
          ) : (
            ''
          )}

          <Quill value={text} onChange={onChangeNote} className='h-30rem' />
          <div className='mt-10 md:mt-6'>
            <div className='w-1/2 text-left inline-block'>
              {!isOpenByDefault ? (
                <Button
                  variant='transparent'
                  onClick={() => {
                    closeTextEditor();
                    setNoteTemplateId(null);
                    clearIncludeText();
                  }}
                >
                  Close
                </Button>
              ) : null}
            </div>

            <div className='w-1/2 text-right inline-block'>
              <Button
                classes={`${
                  isNoteEmpty(text)
                    ? ''
                    : 'bg-success-green hover:bg-success-green-hover'
                } text-white text-lg mt-4 px-4 py-2 rounded-sm font-bold answer transition-colors duration-300`}
                variant='custom'
                onClick={() => {
                  onSave(formatNoteText(text), noteType, reportId, meta);
                  closeTextEditor();
                  onChangeNote('');
                  setNoteTemplateId(null);
                  clearIncludeText();
                }}
                disabled={isNoteEmpty(text)}
              >
                Save note
              </Button>
            </div>
          </div>
        </div>
      </Base>
    </>
  );
};

export default AddNoteNewReport;
