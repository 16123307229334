import { get, isNil } from 'lodash';
import COMMON_CONSTANTS from 'common/commonConstants';

const { GOALS, getGoalStatusKeyByValue, getGoalStatusValueByKey } = COMMON_CONSTANTS;

const utils = {};

// utils.getProgressPrefix = (goalType) => {
//   if (goalType === GOALS.TYPES.BINARY) {
//     return '';
//   }

//   if (goalType === GOALS.TYPES.DOLLAR) {
//     return '$';
//   }

//   if (goalType === GOALS.TYPES.NUMBER) {
//     return '';
//   }

//   if (goalType === GOALS.TYPES.PERCENT) {
//     return '%';
//   }

//   return '';
// };

utils.getGoalStatusLabelFromLatestUpdate = (goal, companyid) => {
  if (!goal) return '-';

  const { status, updates = [] } = goal;

  const latestUpdate = updates.length
    ? updates[updates.length - 1]
    : { status };
  const latestStatus = latestUpdate.status;

  const goalStatusKey = getGoalStatusKeyByValue(latestStatus);
  return getGoalStatusValueByKey(goalStatusKey, companyid);
};

utils.getGoalStatusValueFromLatestUpdate = (goal) => {
  if (!goal) return '-';

  const { status, updates = [] } = goal;

  const latestUpdate = updates.length
    ? updates[updates.length - 1]
    : { status };
  const latestStatus = latestUpdate.status;

  return latestStatus;
};

utils.getGoalProgressLabel = (goal) => {
  if (!goal) return '-';

  const { type: goalType, progress, updates = [] } = goal;

  const latestUpdate = updates.length
    ? updates[updates.length - 1]
    : { progress };
  const latestProgress = latestUpdate.progress;

  if (goalType === GOALS.TYPES.BINARY) {
    if (Number(latestProgress) === 0 || latestProgress === 'Not done') {
      return 'Not done';
    }
    if (Number(latestProgress) === 1 || latestProgress === 'Done') {
      return 'Done';
    }
  }

  if (goalType === GOALS.TYPES.DOLLAR) {
    return `$${latestProgress}`;
  }

  if (goalType === GOALS.TYPES.NUMBER) {
    return latestProgress;
  }

  if (goalType === GOALS.TYPES.PERCENT) {
    return `${latestProgress}%`;
  }

  return '-';
};

// builds the goals tree given a list of goals (with a `parent` attribute)
utils.getGoalsTree = (goals) => {
  const allUserGoalIds = goals.map((g) => g.id);
  const tree = [];
  goals.forEach((g) => {
    if (!g.parent) {
      return tree.push(g);
    }
    if (!allUserGoalIds.includes(g.parent)) {
      tree.push(g);
    }
  });

  return tree;
};

utils.getGoalType = (goalType) => {
  if (goalType === GOALS.TYPES.BINARY) {
    return '%';
  }
  if (goalType === GOALS.TYPES.DOLLAR) {
    return '$';
  }
  if (goalType === GOALS.TYPES.NUMBER) {
    return '#';
  }
  if (goalType === GOALS.TYPES.PERCENT) {
    return '%';
  }
};

utils.getGoalFrequency = (goal) => {
  const goalFrequency = goal.frequency;
  const frequencyKey = Object.keys(GOALS.FREQUENCY).find((key) => {
    const obj = GOALS.FREQUENCY[key];
    if (obj.value === goalFrequency) {
      return true;
    }
    return false;
  });
  const frequencyObj = GOALS.FREQUENCY[frequencyKey];
  let frequency = null;

  if (frequencyObj.value === GOALS.FREQUENCY.WEEKLY.value) {
    frequency = 'a week';
  }
  if (frequencyObj.value === GOALS.FREQUENCY.EVERY_10_DAYS.value) {
    frequency = frequencyObj.value;
  }
  if (frequencyObj.value === GOALS.FREQUENCY.BIMONTHLY.value) {
    frequency = frequencyObj.value;
  }
  if (frequencyObj.value === GOALS.FREQUENCY.EVERY_3_WEEKS.value) {
    frequency = frequencyObj.value;
  }
  if (frequencyObj.value === GOALS.FREQUENCY.MONTHLY.value) {
    frequency = 'a month';
  }
  if (frequencyObj.value === GOALS.FREQUENCY.EVERY_3_MONTHS.value) {
    frequency = frequencyObj.value;
  }

  return frequency;
};

utils.getGoalProgressOption = (goal) => {
  const progress = get(goal, 'lastUpdate.progress');

  if (isNil(progress)) {
    if (goal.type === GOALS.TYPES.BINARY) {
      return { value: '0', label: 'Not done' };
    }
    return goal.progress;
  }
  if (goal.type === GOALS.TYPES.BINARY) {
    return {
      value: progress,
      label: utils.getGoalProgressLabel(goal)
    };
  }
  return Number(progress);
};

utils.getGoalStatusOptionFromLatestUpdate = (goal, goalStatuses) => {
  let lastStatus = get(goal, 'lastUpdate.status');
  if (isNil(lastStatus)) {
    lastStatus = goal.status;
  }
  const statusOption = goalStatuses.find((st) => st.value === lastStatus);
  return statusOption;
};

utils.getGoalProgressValue = (goal) => {
  const { type: goalType, progress, updates = [] } = goal;

  const latestUpdate = updates.length
    ? updates[updates.length - 1]
    : { progress };
  const latestProgress = latestUpdate.progress;

  if (isNil(latestProgress)) {
    return progress;
  }
  if (goalType === GOALS.TYPES.BINARY) {
    return latestProgress;
  }
  return Number(latestProgress);
};

export default utils;
