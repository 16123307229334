import React, { useContext } from 'react';
import { Base } from 'src/components/index';
import STYLE from 'src/constants/style';
import { useCompany } from 'src/queries/company';
import PresentationSVG from 'src/assets/svg/presentation.svg';
import CircledIcon from 'src/components/CircledIcon/CircledIcon';
import { useAccountsScore } from 'src/queries/account';
import commonDateUtils from 'common/commonDateUtils';
import { BADGE_COLOR_CLASSES } from 'src/componentsTailwind/tailwindConstants';
import { Line } from 'react-chartjs-2';
import { isFinite, get, uniqBy } from 'lodash';
import COMMON_CONSTANTS from 'common/commonConstants';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import LeftArrowSVG from 'src/assets/left-arrow.svg';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

const RangeTeamPerformance = () => {
  const { context } = useContext(CompanyDashContext);
  const { range, filters } = context;
  const { managerId } = filters;

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  let sampleBy = 'month';
  if (range.value === DATE_RANGE_FILTERS.LAST_MONTH.key) {
    sampleBy = 'week';
  } else if (range.value === DATE_RANGE_FILTERS.THREE_MONTHS.key) {
    sampleBy = 'month';
  } else if (range.value === DATE_RANGE_FILTERS.SIX_MONTHS.key) {
    sampleBy = 'month';
  } else if (range.value === DATE_RANGE_FILTERS.ONE_YEAR.key) {
    sampleBy = 'month';
  } else if (range.value === DATE_RANGE_FILTERS.ALL_TIME.key) {
    sampleBy = 'year';
  }

  const {
    data: teamSampledScores,
    isFetching: isFetchingteamSampledScores,
    isRefetching: isRefetchingteamSampledScores,
    isError: isErrorteamSampledScores
  } = useAccountsScore(
    {
      ids: [managerId],
      managerId,
      ...(range.start && {
        start: range.start,
        end: range.end
      })
    },
    {
      sampleBy,
      role: 'reviewee',
      bundleCategories: true,
      merge: true
    },
    {
      keepPreviousData: true
    }
  );

  const isInitialFetchingTeamSampledScores = isFetchingteamSampledScores && !isRefetchingteamSampledScores;
  const isFetching = isFetchingCompany || isInitialFetchingTeamSampledScores;
  const isError = isErrorCompany || isErrorteamSampledScores;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const [teamScore] = teamSampledScores;
  const { avgScoreReceived, avgScoreLabel, samples } = teamScore;

  const graphOptions = {
    plugins: {
      legend: {
        display: false,
        usePointStyle: true
      },
      tooltip: {
        yAlign: 'bottom',
        boxPadding: 4
      }
    },
    elements: {
      point: {
        radius: 6,
        hoverRadius: 8,
        backgroundColor: '#1DBF73'
      }
    },
    scales: {
      y: {
        display: false,
        max: 110,
        min: -10
      },
      x: {
        grid: { display: false },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          autoSkip: false
        }
      }
    }
  };

  const { value } = range;
  let weekCount = 0;
  if (value === DATE_RANGE_FILTERS.LAST_MONTH.key) {
    weekCount = 4;
  }

  let monthCount = 0;
  if (value === DATE_RANGE_FILTERS.THREE_MONTHS.key) {
    monthCount = 3;
  } else if (value === DATE_RANGE_FILTERS.SIX_MONTHS.key) {
    monthCount = 6;
  } else if (value === DATE_RANGE_FILTERS.ONE_YEAR.key) {
    monthCount = 12;
  }

  let yearCount = 0;
  if (range.value === DATE_RANGE_FILTERS.ALL_TIME.key) {
    yearCount = 4;
  }

  const samplesToPlot = uniqBy(
    samples.map((sample, index) => {
      let date = null;
      if (weekCount) {
        const startDate = commonDateUtils.unixToDate(sample.start);
        const endDate = commonDateUtils.unixToDate(sample.end);
        date = `${commonDateUtils.dateToMonthDayFormat(startDate, '')} - ${commonDateUtils.dateToMonthDayFormat(endDate, '')}`;
      } else if (monthCount) {
        date = commonDateUtils.dateToMonthYearFormat(
          commonDateUtils.unixToDate(sample.end)
        );
      } else if (yearCount) {
        date = commonDateUtils.unixToDate(sample.end).getFullYear();
      }

      if (!isFinite(sample.avgScoreReceived)) {
        const prevSamples = samples.slice(0, index).reverse();
        const latestValidSample = prevSamples.find((prevSample) => isFinite(prevSample.avgScoreReceived));
        return {
          ...sample,
          date,
          avgScoreReceived: get(latestValidSample, 'avgScoreReceived', 0)
        };
      }

      return {
        ...sample,
        date
      };
    }),
    'date'
  );

  let labels = [];
  labels = samplesToPlot.map((sample) => sample.date);

  const data = labels.map((label) => {
    const sampleIndex = samplesToPlot.findIndex((s) => s.date === label);
    if (sampleIndex === -1) {
      const prevSamples = samples.slice(0, sampleIndex).reverse();
      const latestValidSample = prevSamples.find((prevSample) => isFinite(prevSample.avgScoreReceived));
      return get(latestValidSample, 'avgScoreReceived', 0);
    }
    const sample = samplesToPlot[sampleIndex];
    return get(sample, 'avgScoreReceived', 0);
  });

  const graphData = {
    labels,
    datasets: [
      {
        data,
        borderColor: '#1DBF73',
        tension: 0,
        pointBackgroundColor: '#1DBF73',
        pointBorderColor: 'rgba(255,255,255,1)',
        pointRadius: 6,
        pointHoverRadius: 8
      }
    ]
  };

  const hasData = isFinite(avgScoreReceived);
  const hasNumericData = hasData && data.some(isFinite);
  const thisSampleData = data[data.length - 1];
  const lastSampleData = data[data.length - 2];
  const hasLastSampleDiffNumericData = isFinite(thisSampleData) || isFinite(lastSampleData);

  let avgScoreFromLastSample = 0;
  let avgScoreDiffFromLastSampleLabel = 'Same';
  let avgScoreDiffBadgeClasses = `${BADGE_COLOR_CLASSES.GRAY} [&_svg]:hidden border-[2px] border-gray-700/20`;

  if (hasLastSampleDiffNumericData) {
    avgScoreFromLastSample = thisSampleData - lastSampleData;
    if (avgScoreFromLastSample > 0) {
      avgScoreDiffFromLastSampleLabel = `+${avgScoreFromLastSample}%`;
      avgScoreDiffBadgeClasses = `${BADGE_COLOR_CLASSES.GREEN} [&_svg]:rotate-90 border-[2px] border-green-700/20`;
    } else if (avgScoreFromLastSample < 0) {
      avgScoreDiffFromLastSampleLabel = `${avgScoreFromLastSample}%`;
      avgScoreDiffBadgeClasses = `${BADGE_COLOR_CLASSES.RED} [&_svg]:-rotate-90 border-[2px] border-red-700/20`;
    }
  }

  const avgScoreReceivedlabel = isFinite(avgScoreReceived)
    ? `${hasNumericData ? `${avgScoreReceived}, ` : ' '}${avgScoreLabel}`
    : avgScoreLabel;

  let versusLabel = 'vs last ';
  if (weekCount) {
    versusLabel += 'week';
  } else if (monthCount) {
    versusLabel += 'month';
  } else if (yearCount) {
    versusLabel += 'year';
  }

  const isLoading = isFetchingCompany || isFetchingteamSampledScores;
  return (
    <Base
      classes={`${STYLE.CONTAINER_WHITE} !p-4`}
      relative
      loading={isLoading}
    >
      <CircledIcon svg={<PresentationSVG className='text-purple' />} />
      <p className='text-xl text-black font-bold mb-0 mr-8'>
        Overall Performance Score
      </p>
      <p className='text-md font-normal my-2 leading-5 text-bluish-gray'>
        Team average performance score by date.
      </p>
      <p className='text-[#101828] font-[700] text-[24px] leading-[28px] m-0 py-[5px]'>
        {avgScoreReceivedlabel}
      </p>
      {hasNumericData && (
        <span className='max-w-fit my-[4px] flex items-center gap-1'>
          <span
            className={`rounded-2xl flex items-center text-left px-2 ${avgScoreDiffBadgeClasses}`}
          >
            <LeftArrowSVG className='transform text-inherit scale-[0.7] -ml-1' />
            <span className='text-xs font-medium italic'>
              {avgScoreDiffFromLastSampleLabel}
            </span>
          </span>
          <span className='m-0'>{versusLabel}</span>
        </span>
      )}
      {hasNumericData ? <Line data={graphData} options={graphOptions} /> : null}
    </Base>
  );
};

export default RangeTeamPerformance;
