import React, { useContext } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTree } from 'src/queries/tree';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { useUserFeedback } from 'src/containers/UserProfile/FeedbackDashboard/queries';
import { useAccount } from 'src/queries/account';
import commonPermissions from 'common/commonPermissions';
import FeedbackFeed from 'src/containers/UserProfile/FeedbackDashboard/components/FeedbackFeed';
import MonthlySummary from 'src/containers/UserProfile/FeedbackDashboard/components/MonthlySummary';
import ReportPDFLink from 'src/containers/UserProfile/FeedbackDashboard/components/ReportPDFLink';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import { appUtils, Pagination } from 'src/components/index';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';
import commonViewPermissions from 'common/commonViewPermissions';
import { get } from 'lodash';
import MobileFeedbackDashboard from 'src/containers/UserProfile/FeedbackDashboard/components/mobile/FeedbackDashboard';

const FeedbackDashboard = ({ userId }) => {
  const loggedUserId = appUtils.getLoggedUserId();
  const isMyProfile = userId === loggedUserId;
  const { context, updateContext } = useContext(
    isMyProfile ? DashContext : UserProfileContext
  );
  const { viewerId } = context;

  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');

  const {
    data: userAccount,
    isFetching: isFetchingUserAccount,
    isError: isErrorUserAccount
  } = useAccount(userId);

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const { range, filters } = context;
  const {
    data: { userFeedbacks, pagination: feedbackPagination },
    isFetching: isFetchingFeedbacks,
    isRefetching: isRefetchingFeedbacks,
    isError: isErrorFeedbacks
  } = useUserFeedback(
    userId,
    {
      range,
      filters: {
        ...filters,
        includeAnonymousReviews: true
      },
      pages: {
        page: get(context, 'currentPages.profileFeedbacks', 1),
        pageSize: 10
      },
      ...(viewerId && { viewerId })
    },
    { keepPreviousData: true }
  );

  const {
    showFeedbackTabPerformanceReviewSection,
    showMonthlySummaryOnProfile
  } = useFlags();

  const isInitialFetchingFeedbacks = isFetchingFeedbacks && !isRefetchingFeedbacks;
  const isFetching = isFetchingTree
    || isFetchingLoggedAccount
    || isInitialFetchingFeedbacks
    || isFetchingUserAccount;
  const isError = isErrorTree
    || isErrorLoggedAccount
    || isErrorFeedbacks
    || isErrorUserAccount;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const selectPage = (page) => {
    appUtils.scrollToTop();
    updateContext({
      currentPages: {
        ...context.currentPages,
        profileFeedbacks: parseInt(page, 10)
      }
    });
  };

  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    userId
  ]);
  const canViewScores = commonViewPermissions.canViewUserScores(
    tree,
    loggedUserId,
    userId
  );
  const canViewMonthlySummary = (canManageAccount || canViewScores) && showMonthlySummaryOnProfile;

  const shouldRenderToggle = !isMyProfile && canViewMonthlySummary;

  const pdfLink = userAccount.resources.find(
    (resource) => resource.type === 'pdf' && resource.link !== ''
  );

  const mobileSize = appUtils.getMobileSize();
  const isMobile = mobileSize;

  if (isMobile) {
    return (
      <MobileFeedbackDashboard
        feedbacks={userFeedbacks}
        isLoading={isFetchingFeedbacks}
        reviewee={{
          id: userId,
          name: userAccount.firstName
        }}
        showToggle={shouldRenderToggle}
        viewerId={viewerId}
        setViewerId={(id) => updateContext({ viewerId: id })}
        renderPagination={() => (
          <div className='mt-4'>
            <Pagination
              name='UserFeedback'
              totalPages={feedbackPagination.pages}
              currentPage={feedbackPagination.current}
              selectPage={selectPage}
              isLoading={isFetchingFeedbacks}
            />
          </div>
        )}
      />
    );
  }

  return (
    <div className='h-full w-full flex gap-3'>
      <div className='w-2/3'>
        <FilterView
          userId={userId}
          show={['reviewers', 'roles', 'categories', 'date']}
          alwaysDisplayFields={['reviewers', 'roles', 'categories', 'date']}
          classes='flex-wrap'
          wrapperClasses='w-60'
          displayFlyout
          displayClearFiltersBtn
        />
        <FeedbackFeed
          feedbacks={userFeedbacks}
          isLoading={isFetchingFeedbacks}
          reviewee={{
            id: userId,
            name: userAccount.firstName,
            label: userAccount.firstName
          }}
          showInput
          showToggle={shouldRenderToggle}
          viewerId={viewerId}
          setViewerId={(id) => updateContext({
            viewerId: id
          })}
          renderPagination={() => (
            <div className='mt-4'>
              <Pagination
                name='UserFeedback'
                totalPages={feedbackPagination.pages}
                currentPage={feedbackPagination.current}
                selectPage={selectPage}
                isLoading={isFetchingFeedbacks}
              />
            </div>
          )}
        />
      </div>
      <div className='w-1/3'>
        {canViewMonthlySummary ? (
          <MonthlySummary
            user={{
              id: userId,
              name: userAccount.firstName
            }}
          />
        ) : null}
        {showFeedbackTabPerformanceReviewSection && pdfLink ? (
          <ReportPDFLink pdfLink={pdfLink} />
        ) : null}
      </div>
    </div>
  );
};

export default FeedbackDashboard;
