import React, { useContext, useState } from 'react';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import commonViewPermissions from 'common/commonViewPermissions';
import FilterByReviewers from 'src/containers/UserProfile/FilterView/FilterByReviewers';
import FilterByReviewees from 'src/containers/UserProfile/FilterView/FilterByReviewees';
import FilterByRoles from 'src/containers/UserProfile/FilterView/FilterByRoles';
import FilterByRevieweeRoles from 'src/containers/UserProfile/FilterView/FilterByRevieweeRoles';
import FilterByCategories from 'src/containers/UserProfile/FilterView/FilterByCategories';
import FilterByRevieweeCategories from 'src/containers/UserProfile/FilterView/FilterByRevieweeCategories';
import FilterByStatus from 'src/containers/UserProfile/FilterView/FilterByStatus';
import FilterByDate from 'src/containers/UserProfile/FilterView/FilterByDate';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import commonDateUtils from 'common/commonDateUtils';
import commonPermissions from 'common/commonPermissions';
import { Button } from 'src/components';
import { useAccount } from 'src/queries/account';
import { isEmpty } from 'lodash';
import FiltersFlyout from 'src/pagesDashboard/CompanyDash/components/FiltersFlyout';
import COMMON_CONSTANTS from 'common/commonConstants';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

const FilterView = ({
  userId,
  show = [],
  alwaysDisplayFields = [],
  options = {},
  direction = 'row',
  showLabels = false,
  wrapperClasses = '',
  displayClearFiltersBtn = false,
  displayFlyout = false,
  customContext,
  classes,
  selectClasses
}) => {
  const {
    data: { myTreeRow, tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');

  const { isReport } = options;
  const isMyProfile = !isReport && myTreeRow.id === userId;

  const dashContext = useContext(DashContext);
  const userProfileContext = useContext(UserProfileContext);

  let defaultContext = null;
  if (isEmpty(dashContext)) {
    defaultContext = UserProfileContext;
  }
  if (isEmpty(userProfileContext)) {
    defaultContext = DashContext;
  }
  if (!isEmpty(customContext)) {
    defaultContext = customContext;
  }

  const {
    context,
    updateContext,
    resetPages = () => {}
  } = useContext(defaultContext);

  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  const isFetching = isFetchingTree || isFetchingCompany || isFetchingLoggedAccount;
  const isError = isErrorTree || isErrorCompany || isErrorLoggedAccount;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }
  const { id: companyid } = company;

  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    userId
  ]);
  const canViewFilters = canManageAccount
    || commonViewPermissions.canViewUserProfileFilters(tree, myTreeRow.id, userId);

  if (!canViewFilters) {
    return null;
  }

  const setRange = (value, start, end) => {
    const startValue = commonDateUtils.getFirstUnixOfDateFromUnix(
      start || commonDateUtils.dateToUnix('2020-01-01')
    );
    const endValue = commonDateUtils.getLastUnixOfDateFromUnix(
      end || commonDateUtils.dateToUnix(new Date())
    );

    if (isReport || value === 'custom') {
      updateContext({
        range: {
          value: 'custom',
          start: startValue,
          end: endValue
        }
      });
      return resetPages();
    }

    updateContext({
      range: {
        value,
        start: startValue,
        end: undefined
      },
      currentPage: 1
    });
    return resetPages();
  };

  const updateFields = (fields) => {
    const newFilters = {
      ...context.filters,
      ...fields
    };

    updateContext({
      filters: newFilters,
      historicReviews: { ...context.historicReviews, page: 1 },
      currentPage: 1
    });
    resetPages();
  };

  const { filters, range } = context;

  const toggleFlyout = () => setIsFlyoutOpen(!isFlyoutOpen);

  const countObjectItems = (obj, fields) => {
    const count = Object.keys(obj).reduce((acc, key) => {
      if (!fields.includes(key)) return acc;
      const isArrayField = Array.isArray(obj[key]);

      if (!isArrayField && obj[key]) return acc + 1;
      if (isArrayField && obj[key].length) return acc + 1;

      return acc;
    }, 0);
    return count;
  };

  const allAvailableFields = show
    .map((field) => {
      if (field === 'reviewers') return ['reviewerIds', 'reviewerGroup'];
      if (field === 'reviewees') return ['reviewees', 'revieweeGroup'];
      if (field === 'roles') return 'roles';
      if (field === 'multi-categories' || field === 'categories') return 'category';
      if (field === 'sentiment') return 'sentiment';
      if (field === 'teams') return 'managerId';
      if (field === 'teams') return 'managerId';
      if (field === 'date') return 'date';
      return null;
    })
    .flat()
    .filter((item) => item);

  const dateRangeCount = allAvailableFields.includes('date')
    && range.value !== DATE_RANGE_FILTERS.THREE_MONTHS.key
    ? 1
    : 0;
  const countAllFilters = countObjectItems(filters, allAvailableFields) + dateRangeCount;

  const clearFilters = () => {
    const resetFieldsObj = {};
    allAvailableFields.forEach((field) => {
      if (field !== 'date') {
        // date is in a diff object
        resetFieldsObj[field] = null;
      }
    });

    updateFields(resetFieldsObj);
    setRange(
      DATE_RANGE_FILTERS.THREE_MONTHS.key,
      commonDateUtils.getFirstUnixOfDate(
        commonDateUtils.getDateFromDaysAgo(DATE_RANGE_FILTERS.THREE_MONTHS.days)
          .date
      ),
      undefined
    );
  };

  return (
    <div className='flex flex-row mb-2'>
      {displayFlyout && (
        <FiltersFlyout
          isOpen={isFlyoutOpen}
          setIsOpen={setIsFlyoutOpen}
          showFields={show}
          filters={{ ...filters, range: context.range }}
          updateDate={setRange}
          updateFn={updateFields}
          isMyProfile={isMyProfile}
          userId={userId}
        />
      )}
      <div
        className={`flex ${
          direction === 'col' ? 'flex-col' : ''
        } gap-3 ${classes ?? ''}`}
      >
        {show.includes('reviewers') && (
          <div
            className={`${wrapperClasses} ${alwaysDisplayFields.includes('reviewers') ? '' : 'hidden xl:block'}`}
          >
            {showLabels && (
              <div className='text-sm font-semibold'>Reviewers</div>
            )}
            <FilterByReviewers
              id='filter-view-reviewers'
              isMyProfile={isMyProfile}
              userId={userId}
              updateFn={updateFields}
              reviewerIds={filters.reviewerIds}
              reviewerGroup={filters.reviewerGroup}
              customSelectClasses={selectClasses}
            />
          </div>
        )}
        {show.includes('reviewees') && (
          <div
            className={`${wrapperClasses} ${alwaysDisplayFields.includes('reviewees') ? '' : 'hidden md:block'}`}
          >
            {showLabels && (
              <div className='text-sm font-semibold'>Reviewees</div>
            )}
            <FilterByReviewees
              id='filter-view-reviewees'
              isMyProfile={isMyProfile}
              userId={userId}
              updateFn={updateFields}
              revieweeIds={filters.revieweeIds}
              revieweeGroup={filters.revieweeGroup}
              customSelectClasses={selectClasses}
            />
          </div>
        )}
        {show.includes('roles') && (
          <div
            className={`${wrapperClasses} ${alwaysDisplayFields.includes('roles') ? '' : 'hidden desktop:block'}`}
          >
            {showLabels && <div className='text-sm font-semibold'>Roles</div>}
            <FilterByRoles
              id='filter-view-roles'
              isMyProfile={isMyProfile}
              userId={userId}
              roles={filters.roles}
              updateFn={updateFields}
              customSelectClasses={selectClasses}
            />
          </div>
        )}
        {show.includes('reviewee-roles') && (
          <div
            className={`${wrapperClasses} ${alwaysDisplayFields.includes('reviewee-roles') ? '' : 'hidden xl:block'}`}
          >
            {showLabels && (
              <div className='text-sm font-semibold'>Reviewee Roles</div>
            )}
            <FilterByRevieweeRoles
              id='filter-view-reviewee-roles'
              isMyProfile={isMyProfile}
              userId={userId}
              roles={filters.roles}
              updateFn={updateFields}
              customSelectClasses={selectClasses}
            />
          </div>
        )}
        {/* {(show.includes('categories') || show.includes('multi-categories')) && (
          <div className={`${wrapperClasses}`}>
            {showLabels && (
              <div className='text-sm font-semibold'>Categories</div>
            )}
            <FilterByCategories
              id='filter-view-categories'
              isMyProfile={isMyProfile}
              userId={userId}
              multiSelect={show.includes('multi-categories')}
              category={filters.category}
              updateFn={updateFields}
              customSelectClasses={selectClasses}
            />
          </div>
        )} */}
        {show.includes('reviewee-categories') && (
          <div
            className={`${wrapperClasses} ${alwaysDisplayFields.includes('reviewee-categories') ? '' : 'hidden xl:block'}`}
          >
            {showLabels && (
              <div className='text-sm font-semibold'>Reviewee Categories</div>
            )}
            <FilterByRevieweeCategories
              id='filter-view-reviewee-categories'
              isMyProfile={isMyProfile}
              userId={userId}
              category={filters.category}
              updateFn={updateFields}
              customSelectClasses={selectClasses}
            />
          </div>
        )}
        {show.includes('status') && (
          <div
            className={`${wrapperClasses} ${alwaysDisplayFields.includes('status') ? '' : 'hidden xl:block'}`}
          >
            {showLabels && <div className='text-sm font-semibold'>Status</div>}
            <FilterByStatus
              id='filter-view-status'
              isMyProfile={isMyProfile}
              companyid={companyid}
              userId={userId}
              status={filters.status}
              updateFn={updateFields}
              customSelectClasses={selectClasses}
            />
          </div>
        )}
        {show.includes('date') && (
          <div
            className={`${wrapperClasses} ${alwaysDisplayFields.includes('date') ? '' : 'hidden 3xl:block'}`}
          >
            {showLabels && <div className='text-sm font-semibold'>Date</div>}
            <FilterByDate
              id='filter-view-range'
              dateRangeSelected={context.range}
              onSelectDateRange={setRange}
              classes={selectClasses}
            />
          </div>
        )}
      </div>
      {displayClearFiltersBtn || displayFlyout ? (
        <div className='flex my-auto mr-4 min-w-44 ml-auto justify-end'>
          {displayClearFiltersBtn && countAllFilters ? (
            <Button
              variant='custom'
              classes='text-center mr-4'
              onClick={clearFilters}
            >
              <span className='text-zinc-600 my-auto'>Clear All</span>
            </Button>
          ) : null}

          {displayFlyout && (
            <Button
              variant='custom'
              classes='text-center'
              onClick={toggleFlyout}
            >
              <span className='text-purple'>
                More filters
                {countAllFilters ? ` (${countAllFilters})` : null}
              </span>
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default FilterView;
