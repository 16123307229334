import React, { useContext, useState } from 'react';
import { appUtils, Base } from 'src/components/index';
import STYLE from 'src/constants/style';
import { useCompany } from 'src/queries/company';
import PresentationSVG from 'src/assets/svg/presentation.svg';
import CircledIcon from 'src/components/CircledIcon/CircledIcon';
import LabeledToggle from 'src/components/LabeledToggle/LabeledToggle';
import {
  COMPARE,
  useAccount,
  useAccountScoreComparison
} from 'src/queries/account';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import commonQuestionsUtils from 'common/commonQuestionsUtils';
import { BADGE_COLOR_CLASSES } from 'src/componentsTailwind/tailwindConstants';
import { Line } from 'react-chartjs-2';
import commonDateUtils from 'common/commonDateUtils';
import { isEmpty, get, isNumber } from 'lodash';
import commonChartUtils from 'common/chart/chart';
import NoData from 'src/containers/UserProfile/NoData/NoData';
import CategoryFeedbackFlyout from 'src/containers/UserProfile/AnalyticsDashboard/components/CategoryFeedbackFlyout';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonPermissions from 'common/commonPermissions';
import { useTree } from 'src/queries/tree';

const { VIEW_SCORES_PERMISSIONS } = COMMON_CONSTANTS;

const CompetencyScores = ({
  userId = appUtils.getLoggedUserId(),
  showToggle = false,
  viewerId = appUtils.getLoggedUserId(),
  setViewerId = () => {}
}) => {
  const loggedUserId = appUtils.getLoggedUserId();
  const isMyProfile = userId === loggedUserId;
  const { context } = useContext(
    isMyProfile ? DashContext : UserProfileContext
  );
  const { range, filters } = context;
  const {
    roles: roleIds,
    category: categoryId,
    reviewerIds,
    reviewerGroup
  } = filters;

  const [flyoutCategoryId, setFlyoutCategoryId] = useState(null);
  const [isCategoryFeedbackFlyoutOpen, setIsCategoryFeedbackFlyoutOpen] = useState(false);

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: userAccount,
    isFetching: isFetchingUserAccount,
    isError: isErrorUserAccount
  } = useAccount(userId);

  const {
    data: viewerAccount,
    isFetching: isFetchingViewerAccount,
    isError: isErrorViewerAccount
  } = useAccount(viewerId);

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: comparisonScore,
    isFetching: isFetchingComparisonScore,
    isRefetching: isRefetchingComparisonScore,
    isError: isErrorComparisonScore
  } = useAccountScoreComparison(
    {
      id: userId,
      compare: COMPARE.COMPANY,
      ...(roleIds && { roleIds }),
      ...(categoryId && { categoryIds: [categoryId] }),
      ...(range.start && {
        start: range.start,
        end: range.end
      }),
      ...(!isEmpty(reviewerIds) && { reviewerIds }),
      ...(reviewerGroup && { reviewerGroup })
    },
    {
      sampleBy: commonChartUtils.getChartGroupBy(range.start, range.end),
      bundleCategories: true,
      role: 'reviewee',
      viewerId
    },
    {
      enabled: Boolean(userId),
      keepPreviousData: true
    }
  );

  const isInitialFetchingComparisonScore = isFetchingComparisonScore && !isRefetchingComparisonScore;
  const isFetching = isFetchingUserAccount
    || isFetchingCompany
    || isInitialFetchingComparisonScore
    || isFetchingViewerAccount
    || isFetchingTree;
  const isError = isErrorUserAccount
    || isErrorCompany
    || isErrorComparisonScore
    || isErrorViewerAccount
    || isErrorTree;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const { bundledCategories, samples } = comparisonScore;
  const hasData = !isEmpty(bundledCategories);

  const graphOptions = {
    plugins: {
      legend: false,
      tooltip: {
        yAlign: 'bottom',
        boxPadding: 4
      }
    },
    scales: {
      y: {
        display: false,
        max: 110,
        min: -10
      },
      x: {
        display: false
      }
    }
  };

  const canManageUser = commonPermissions.canManageUsers(viewerAccount, tree, [
    userId
  ]);
  const companyViewScorePermissions = get(
    company,
    'settings.viewScores',
    VIEW_SCORES_PERMISSIONS.MANAGERS
  );
  const isViewingAsUser = viewerId === userId;
  const canViewScores = isViewingAsUser
    ? companyViewScorePermissions === VIEW_SCORES_PERMISSIONS.ALL
    : canManageUser;
  const canViewComparisonBadge = canViewScores && canManageUser;

  const isLoading = isFetchingUserAccount
    || isFetchingCompany
    || isFetchingComparisonScore
    || isFetchingViewerAccount;
  return (
    <>
      <CategoryFeedbackFlyout
        width={40}
        userId={userId}
        range={range}
        filters={{
          ...filters,
          includeAnonymousReviews: true
        }}
        viewerId={viewerId}
        categoryId={flyoutCategoryId}
        isOpen={isCategoryFeedbackFlyoutOpen}
        setIsOpen={setIsCategoryFeedbackFlyoutOpen}
        showXButton={false}
      />
      <Base
        classes={`${STYLE.CONTAINER_WHITE} !p-4`}
        relative
        loading={isLoading}
      >
        <CircledIcon svg={<PresentationSVG className='text-purple' />} />
        <p className='text-xl text-black font-bold mb-0 mr-8'>
          Competency Scores
        </p>
        <p className='m-0 text-sm font-normal mt-2 mb-4 leading-5 text-bluish-gray'>
          Real-time categories for
          {' '}
          {isMyProfile ? 'yourself' : `${userAccount.firstName}'s`}
          {' '}
          based on
          performance review responses from others.
        </p>
        {showToggle ? (
          <LabeledToggle
            labels={[
              {
                id: userId,
                text: `${userAccount.firstName}'s View`
              },
              {
                id: loggedUserId,
                text: `Manager View`
              }
            ]}
            selectedId={viewerId}
            onChange={setViewerId}
          />
        ) : null}
        <div className='border-b border-mid-gray my-3 transform -translate-x-[16px] w-[calc(100%+32px)]' />
        {hasData ? (
          <div className='w-full grid grid-cols-4 gap-4'>
            {bundledCategories.map((categoryScores) => {
              const {
                _id, avgScore, avgScoreDiff, avgScoreLabel
              } = categoryScores;
              const category = commonQuestionsUtils.findCategory(
                company.questions,
                _id
              );

              let avgScoreDiffLabel = 'No comparison data';
              let avgScoreDiffBadgeClasses = BADGE_COLOR_CLASSES.GRAY;
              if (avgScoreDiff > 0) {
                avgScoreDiffLabel = `+${avgScoreDiff}% vs others`;
                avgScoreDiffBadgeClasses = BADGE_COLOR_CLASSES.GREEN;
              } else if (avgScoreDiff < 0) {
                avgScoreDiffLabel = `${avgScoreDiff}% vs others`;
                avgScoreDiffBadgeClasses = BADGE_COLOR_CLASSES.RED;
              }

              const categorySamples = samples
                .map((sample) => {
                  const categorySample = sample.bundledCategories.find(
                    (sampleCategory) => sampleCategory._id === _id
                  );
                  if (isEmpty(categorySample)) {
                    return null;
                  }

                  return {
                    start: sample.start,
                    end: sample.end,
                    ...categorySample
                  };
                })
                .filter(Boolean);

              const categorySamplesToPlot = categorySamples.map(
                (sample, index) => {
                  if (isEmpty(sample.avgScore) && !isNumber(sample.avgScore)) {
                    const prevSamples = samples.slice(0, index).reverse();
                    const latestValidSample = prevSamples.find((prevSample) => isNumber(prevSample.avgScore));
                    return {
                      ...sample,
                      avgScore: get(latestValidSample, 'avgScore', 0)
                    };
                  }
                  return sample;
                }
              );

              const labels = [
                ...categorySamplesToPlot.map(({ start }) => commonDateUtils.unixToTimeAgo(start)),
                'Today'
              ];
              const data = [
                ...categorySamplesToPlot.map((sample) => sample.avgScore),
                avgScore
              ];
              const hasNumericData = data.some(isNumber);
              const isApplicable = avgScoreLabel !== 'Not applicable';
              const hasGraphData = hasNumericData && isApplicable;

              const graphData = {
                labels,
                datasets: [
                  {
                    data,
                    borderColor: '#5B7FFF',
                    tension: 0.75,
                    cubicInterpolationMode: 'monotone',
                    pointRadius: 0
                  }
                ]
              };

              return (
                <Base
                  classes={`${STYLE.CONTAINER_WHITE} ${isApplicable ? 'hover:!bg-background-gray' : ''} !p-[10px]`}
                  key={_id}
                >
                  <button
                    className='flex flex-col items-start text-left truncate'
                    onClick={() => {
                      setIsCategoryFeedbackFlyoutOpen(true);
                      setFlyoutCategoryId(_id);
                    }}
                    disabled={!isApplicable}
                  >
                    <div className='*:m-0 *:py-[5px] truncate w-full'>
                      <p className='text-[#101828] font-[400] text-[16px] leading-[28px] truncate'>
                        {category.label}
                      </p>
                      {canViewScores && hasNumericData ? (
                        <p className='text-[#101828] font-[700] text-[24px] leading-[28px] truncate'>
                          {isApplicable ? avgScore : 'N/A'}
                        </p>
                      ) : null}
                      <p className='text-[#727272] font-[400] text-[16px] leading-[17px] truncate'>
                        {avgScoreLabel}
                      </p>
                    </div>
                    {canViewScores && isApplicable && (
                      <>
                        {canViewComparisonBadge ? (
                          <span
                            className={`rounded-md px-2 py-[2px] my-[4px] ${avgScoreDiffBadgeClasses} max-w-fit`}
                          >
                            <div className='m-0 leading-4 text-xs font-medium'>
                              {avgScoreDiffLabel}
                            </div>
                          </span>
                        ) : null}
                        {hasGraphData ? (
                          <Line data={graphData} options={graphOptions} />
                        ) : null}
                      </>
                    )}
                  </button>
                </Base>
              );
            })}
          </div>
        ) : (
          <NoData userId={userId} />
        )}
      </Base>
    </>
  );
};

export default CompetencyScores;
