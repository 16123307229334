import React, { useState, useEffect } from 'react';
import { get, isNumber, isEmpty } from 'lodash';
import DOMPurify from 'dompurify';
import Checkbox from 'src/components/Checkbox/Checkbox';
import Pagination from 'src/components/Pagination/Pagination';
import { BADGE_COLOR_CLASSES } from 'src/componentsTailwind/tailwindConstants';
import { DropMenu } from 'src/componentsTailwind/index';
import ProgressBar from 'src/components/ProgressBar/ProgressBar';
import CircleV2 from 'src/components/Circle/CircleV2';
import Arrow from 'src/assets/keyboard_arrow_up.svg';

export const TYPES = {
  ACTION: 'action',
  BADGES: 'badges',
  CHECKBOX: 'checkbox',
  IMAGE: 'image',
  DROP_MENU: 'drop-menu',
  PROGRESS_BAR: 'progress-bar',
  SORTABLE: 'sortable',
  HTML: 'html',
  IMAGE_WITH_TEXT: 'image-with-text'
};

const defaultRenderEmptyPlaceholder = (message = 'No data available') => (
  <div className='flex justify-center items-center h-52 w-full'>
    <p className='text-black text-md m-0'>{message}</p>
  </div>
);

const Table = ({
  columns,
  rows,
  wrapperClasses = 'py-1',
  headClasses = '',
  rowClasses = '',
  bodyClasses = 'bg-white',
  pagination = {},
  placeholderMessage = 'No data available',
  renderEmptyPlaceholder = defaultRenderEmptyPlaceholder
}) => {
  const { currentPage, setCurrentPage, totalPages } = pagination;
  const isPaginated = !isEmpty(pagination) && totalPages > 1;

  const [isExpansionNeeded, setIsExpansionNeeded] = useState({});
  const [expandedRows, setExpandedRows] = useState({});

  const isRowExpanded = (id) => {
    if (!id) return false;
    const row = expandedRows[id];

    return !!row;
  };

  useEffect(() => {
    if (rows && rows.length) {
      const flatRows = rows.flat();
      const newState = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const r of flatRows) {
        const { id } = r;
        const el = document.getElementById(`${id}_html_text_row`);
        if (el?.scrollHeight > el?.parentElement?.scrollHeight) {
          newState[id] = true;
        } else {
          newState[id] = false;
        }

        if (isRowExpanded(id)) newState[id] = true;
      }
      setIsExpansionNeeded({ ...newState });
    }
  }, [rows, window.innerWidth]);

  const toggleRowExpansion = (id) => {
    const isExpanded = isRowExpanded(id);

    if (isExpanded) {
      setExpandedRows({ ...expandedRows, [id]: false });
      return;
    }
    setExpandedRows({ ...expandedRows, [id]: true });
  };

  const renderHeader = (headerData, index = null) => {
    const { paddingless = false } = headerData;
    const colSpan = isNumber(headerData.span) ? headerData.span * 10 : 1 * 10;

    if (headerData.type === TYPES.CHECKBOX) {
      return (
        <th key={`header-${index ?? Math.random()}`} colSpan={colSpan}>
          <span
            className={`flex justify-center items-center  ${headerData.hidden ? 'hidden' : ''}`}
          >
            <Checkbox
              onChange={headerData.onChange}
              value={headerData.value}
              disabled={headerData.disabled}
            />
          </span>
        </th>
      );
    }

    if (headerData.type === TYPES.SORTABLE) {
      const { label, sort, setSort } = headerData;
      return (
        <th key={`header-${index ?? Math.random()}`} colSpan={colSpan}>
          <span
            className={`flex items-center  ${headerData.hidden ? 'hidden' : ''}`}
          >
            {label && (
              <div className='text-left text-sm font-semibold text-gray-900 leading-7'>
                {label}
              </div>
            )}
            <button
              onClick={() => setSort(sort.order === 'desc' ? 'asc' : 'desc')}
              className='focus:outline-none flex flex-col h-7'
            >
              <Arrow
                className={`scale-75 ${sort.isSelected && sort.order === 'asc' ? 'text-black' : 'text-gray-900/50'}`}
              />
              <Arrow
                className={`transform rotate-180 scale-75 ${sort.isSelected && sort.order === 'desc' ? 'text-black' : 'text-gray-900/50'}`}
              />
            </button>
          </span>
        </th>
      );
    }

    return (
      <th
        key={`header-${index ?? Math.random()}`}
        className={paddingless ? 'px-0 pb-0 !pt-3' : 'px-0 py-3'}
        colSpan={colSpan}
      >
        <div
          className={`text-left text-sm font-semibold text-gray-900 ${headerData.hidden ? 'hidden' : ''}`}
        >
          {headerData.label}
        </div>
      </th>
    );
  };

  const renderCell = (column, cellData, index = null) => {
    const paddingless = get(column, 'paddingless', false) || get(cellData, 'paddingless', false);
    const colSpan = isNumber(column.span) ? column.span * 10 : 1 * 10;
    const tooltip = get(column, 'tooltip', true);

    if (cellData.type === TYPES.ACTION) {
      const {
        hidden,
        disabled,
        icon,
        discrete = false,
        classes = ''
      } = cellData;
      return (
        <td
          key={`cell-${index ?? Math.random()}`}
          className={`align-top ${paddingless ? 'p-0' : 'px-0 py-3'}`}
          colSpan={colSpan}
        >
          <span className='flex justify-left'>
            <button
              className={`tooltip relative pr-4 disabled:text-mid-gray enabled:hover:text-indigo-900 text-center text-sm font-medium ${hidden ? 'hidden' : ''} ${discrete ? 'text-gray-500' : 'text-purple-600'} ${classes}`}
              onClick={cellData.onClick}
              disabled={disabled}
            >
              {tooltip && (
                <span className='tooltip-text w-fit bg-black text-white text-xs font-medium whitespace-nowrap -top-[40px] left-[0%]'>
                  {cellData.label}
                </span>
              )}
              {icon}
              {cellData.label}
            </button>
          </span>
        </td>
      );
    }

    if (cellData.type === TYPES.BADGES) {
      return (
        <td
          key={`cell-${index ?? Math.random()}`}
          className={`align-top ${paddingless ? 'p-0 ' : 'px-0 py-3'}`}
          colSpan={colSpan}
        >
          <div className='flex flex-wrap gap-1 mt-0.5 items-center'>
            {cellData.badges.map(
              (
                {
                  label,
                  labelClasses = '',
                  badgeClasses = '',
                  containerClasses = '',
                  colorClasses = BADGE_COLOR_CLASSES.GREEN
                },
                badgeIndex
              ) => (
                <button
                  key={`badge-${badgeIndex}`}
                  disabled
                  className={`!relative ${containerClasses}`}
                >
                  <span
                    className={`tooltip rounded-md px-2 py-[2px] ring-1 ring-inset w-fit truncate ${badgeClasses} ${colorClasses}`}
                  >
                    {tooltip && (
                      <span className='tooltip-text w-fit bg-black text-white text-xs font-medium whitespace-nowrap -top-[35px] left-[0%]'>
                        {label}
                      </span>
                    )}
                    <div
                      className={`m-0 leading-4 text-xs font-medium truncate max-w-[100px] ${labelClasses}`}
                    >
                      {label}
                    </div>
                  </span>
                </button>
              )
            )}
          </div>
        </td>
      );
    }

    if (cellData.type === TYPES.CHECKBOX) {
      return (
        <td key={`cell-${index ?? Math.random()}`} colSpan={colSpan}>
          <span className='flex justify-center items-center'>
            <Checkbox
              onChange={cellData.onChange}
              value={cellData.value}
              disabled={cellData.disabled}
            />
          </span>
        </td>
      );
    }

    if (cellData.type === TYPES.IMAGE) {
      const { imageClasses = '' } = cellData;
      return (
        <td
          key={`cell-${index ?? Math.random()}`}
          className='py-3'
          colSpan={colSpan}
        >
          <div className='flex items-center justify-center m-auto w-full h-full'>
            <img
              className={`object-cover grow-0 shrink-0 size-fit ${imageClasses}`}
              src={cellData.imageSrc}
              alt='row'
            />
          </div>
        </td>
      );
    }

    if (cellData.type === TYPES.DROP_MENU) {
      const {
        items = [],
        label = 'Actions',
        direction = 'left',
        disabled = false,
        ifEmpty = 'disable'
      } = cellData;

      if (ifEmpty === 'hide' && isEmpty(items)) {
        return (
          <td
            key={`cell-${index ?? Math.random()}`}
            className={`align-top ${paddingless ? 'p-0 ' : 'px-0'}`}
            colSpan={colSpan}
          />
        );
      }

      return (
        <td
          key={`cell-${index ?? Math.random()}`}
          className={`align-top ${paddingless ? 'p-0 ' : 'px-0 py-3'}`}
          colSpan={colSpan}
        >
          <DropMenu
            label={label}
            items={items}
            direction={direction}
            disabled={disabled || (ifEmpty === 'disable' && isEmpty(items))}
          />
        </td>
      );
    }

    if (cellData.type === TYPES.PROGRESS_BAR) {
      const {
        progress,
        label = null,
        outerClasses = 'p-1 w-24 h-4',
        barClasses = 'bg-[#1DBF73]'
      } = cellData;

      return (
        <td
          key={`cell-${index ?? Math.random()}`}
          className={`align-top ${paddingless ? 'p-0 ' : 'px-0 py-3'}`}
          colSpan={colSpan}
        >
          <span className='flex items-center gap-2'>
            <ProgressBar
              progress={progress}
              outerClasses={outerClasses}
              barClasses={barClasses}
              tooltip={tooltip}
            />
            <p className='min-w-1/20 max-w-1/4 m-0 text-gray-500 text-sm font-bold truncate'>
              {label}
            </p>
          </span>
        </td>
      );
    }

    if (cellData.type === TYPES.HTML) {
      const { label, id } = cellData;

      const isLabelAnElement = typeof label === 'object';

      const sanitizedText = () => {
        if (typeof label === 'string') {
          const formattedLabel = label.replace(
            '<p>',
            "<p style='font-size: 0.875rem'/>"
          );
          return {
            __html: DOMPurify.sanitize(formattedLabel)
          };
        }
        return { __html: label };
      };

      return (
        <td
          key={`cell-${index ?? Math.random()}`}
          className={`align-top ${paddingless ? 'p-0 ' : 'px-0 py-3'}`}
          colSpan={colSpan}
        >
          {isLabelAnElement ? (
            label
          ) : (
            <div
              className={`ql-editor p-0 m-0 pr-8 h-fit ${
                isRowExpanded(id) ? '' : 'multiline-ellipsis'
              }`}
              id={`${id}_html_text_row`}
              dangerouslySetInnerHTML={sanitizedText()}
            />
          )}

          {isExpansionNeeded[id] ? (
            <button
              className='text-purple font-bold text-md'
              type='button'
              onClick={() => toggleRowExpansion(id)}
            >
              {isRowExpanded(id) ? 'Collapse' : 'Read more'}
            </button>
          ) : null}
        </td>
      );
    }

    if (cellData.type === TYPES.IMAGE_WITH_TEXT) {
      const {
        hidden,
        disabled,
        icon,
        discrete = false,
        classes = '',
        image,
        label,
        imageSize,
        underlabel,
        underlabelClasses = ''
      } = cellData;

      const userRow = (
        <div className='flex flex-row gap-1 items-center'>
          <CircleV2
            imageUrl={image}
            size={imageSize ?? 'xs'}
            customClasses='shadow-inner-md shadow-md object-cover m-0 mr-1'
          />
          <div className='text-left'>
            <span className='text-sm m-0 text-left'>{label}</span>
            {underlabel ? (
              <div
                className={`m-0 text-gray-400 text-xs truncate leading-4 ${underlabelClasses}`}
              >
                {underlabel}
              </div>
            ) : null}
          </div>
        </div>
      );

      return (
        <td
          key={`cell-${index ?? Math.random()}`}
          className={`align-top ${paddingless ? 'p-0' : 'px-0 py-[0.57rem]'}`}
          colSpan={colSpan}
        >
          <span className='flex justify-left'>
            <button
              className={`tooltip relative pr-4 disabled:text-mid-gray enabled:hover:text-indigo-900 text-center text-sm font-medium ${hidden ? 'hidden' : ''} ${discrete ? 'text-gray-500' : 'text-purple-600'} ${classes} truncate`}
              onClick={cellData.onClick}
              disabled={disabled}
            >
              {tooltip && (
                <span className='tooltip-text w-fit bg-black text-white text-xs font-medium whitespace-nowrap -top-[40px] left-[0%]'>
                  {cellData.label}
                </span>
              )}
              {icon}
              {userRow}
            </button>
          </span>
        </td>
      );
    }

    return (
      <td
        key={`cell-${index ?? Math.random()}`}
        className={`align-top ${paddingless ? 'p-0 ' : 'px-0 py-3'}`}
        colSpan={colSpan}
      >
        <span className='tooltip relative pr-4'>
          {tooltip && (
            <span className='tooltip-text w-fit bg-black text-white text-xs font-medium whitespace-nowrap -top-[35px] left-[0%]'>
              {cellData.label}
              {cellData.underlabel ? ` - ${cellData.underlabel}` : ''}
            </span>
          )}
          <div
            className={`m-0 text-gray-500 text-sm ${column.multiline ? 'multiline-ellipsis' : 'truncate'} ${cellData.labelClasses || ''}`}
          >
            {cellData.label}
            {cellData.underlabel ? (
              <div
                className={`m-0 text-gray-400 text-xs truncate leading-4 ${cellData.underlabelClasses || ''}`}
              >
                {cellData.underlabel}
              </div>
            ) : null}
          </div>
        </span>
      </td>
    );
  };

  const isDataEmpty = isEmpty(rows);
  return (
    <div className={`${wrapperClasses} !inline-block`}>
      <div className='overflow-x-hidden w-full'>
        <table className='w-full max-w-[100%] table-fixed'>
          <thead className={headClasses}>
            <tr className={rowClasses || ''}>
              {columns.map((column, colIndex) => renderHeader(column, colIndex))}
            </tr>
            <tr className='block border-[0.5px] border-gray-300 w-screen mb-4' />
          </thead>

          <tbody className={bodyClasses}>
            {rows.map((row, rowIndex) => {
              let classes = '';

              const { meta } = get(row, '0', {});
              if (!isEmpty(meta)) {
                const { classes: metaClasses } = meta;
                if (!isEmpty(metaClasses)) classes = metaClasses;
              }

              if (!isEmpty(rowClasses)) {
                classes = classes.concat(` ${rowClasses}`);
              }

              return (
                <tr className={classes} key={`row-${rowIndex}`}>
                  {columns.map((column, colIndex) => renderCell(column, row[colIndex], colIndex))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {isDataEmpty ? <>{renderEmptyPlaceholder(placeholderMessage)}</> : null}
      </div>
      {isPaginated ? (
        <Pagination
          name='Table'
          totalPages={totalPages}
          currentPage={currentPage}
          selectPage={setCurrentPage}
        />
      ) : null}
    </div>
  );
};

export default Table;
