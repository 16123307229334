import React, { useContext, useState } from 'react';
import { isFinite, get } from 'lodash';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import { appUtils, Base } from 'src/components/';
import { Table, AlertModal } from 'src/componentsTailwind/index';
import { TYPES } from 'src/componentsTailwind/Table/Table';
import { BADGE_COLOR_CLASSES } from 'src/componentsTailwind/tailwindConstants';
import CircledIcon from 'src/components/CircledIcon/CircledIcon';
import { COMPARE, useAccountsScoreComparison } from 'src/queries/account';
import GrowingBarChartSVG from 'src/assets/svg/growing-bar-chart.svg';
import CircleV2 from 'src/components/Circle/CircleV2';
import STYLE from 'src/constants/style';
import COMMON_CONSTANTS from 'common/commonConstants';

const { USER_STATE } = COMMON_CONSTANTS;

const TeamMemberPerformance = () => {
  const { context, updateContext } = useContext(CompanyDashContext);
  const {
    range, filters, currentPages, currentSort
  } = context;
  const { managerId } = filters;

  const currentPage = currentPages.teamMemberPerformance;
  const sort = currentSort.teamMemberPerformance;

  const {
    data: scores,
    meta: { page },
    isFetching: isFetchingScores,
    isRefetching: isRefetchingScores,
    isError: isErrorScores
  } = useAccountsScoreComparison(
    {
      managerId,
      ...(range.start && {
        start: range.start,
        end: range.end
      }),
      notStatus: [USER_STATE.UNASSIGNED],
      compare: COMPARE.COMPANY
    },
    {
      include: { account: true },
      page: {
        number: currentPage,
        size: 10
      },
      sort: {
        field: sort.field,
        order: sort.order
      }
    },
    {
      keepPreviousData: true
    }
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserAccount, setSelectedUserAccount] = useState(null);

  const isInitialFetchingScores = isFetchingScores && !isRefetchingScores;
  const isFetching = isInitialFetchingScores;
  const isError = isErrorScores;
  const isReady = !isFetching && !isError;
  if (!isReady) {
    return null;
  }

  const columns = [
    {
      label: 'Rank',
      span: 0.5,
      tooltip: false
    },
    {
      label: 'Name'
    },
    {
      label: 'Title'
    },
    {
      label: 'Performance Score',
      type: TYPES.SORTABLE,
      sort: { ...sort, isSelected: true },
      setSort: (newSort) => {
        updateContext({
          currentSort: {
            ...currentSort,
            teamMemberPerformance: { field: 'avgScoreReceived', order: newSort }
          },
          currentPages: {
            ...currentPages,
            teamMemberPerformance: 1
          }
        });
      },
      tooltip: false
    },
    {
      label: 'vs Team Avg',
      span: 0.5,
      tooltip: false
    }
  ];

  const rows = scores.map((score, index) => {
    const {
      account, avgScoreReceived, avgScoreLabel, avgScoreReceivedDiff
    } = score;

    const {
      _id: accountId, name, title, imageUrl
    } = account;

    let badgeColorClasses = BADGE_COLOR_CLASSES.GRAY;
    let comparisonLabel = '-';

    if (isFinite(avgScoreReceivedDiff)) {
      if (avgScoreReceivedDiff > 0) {
        badgeColorClasses = BADGE_COLOR_CLASSES.GREEN;
        comparisonLabel = `+${avgScoreReceivedDiff}`;
      } else if (avgScoreReceivedDiff < 0) {
        badgeColorClasses = BADGE_COLOR_CLASSES.RED;
        comparisonLabel = avgScoreReceivedDiff;
      }
    } else {
      comparisonLabel = 'N/A';
    }

    const { totalItems } = page;
    let rank = null;

    if (sort.order === 'asc') {
      rank = totalItems - (currentPage - 1) * 10 - index;
    } else {
      rank = (currentPage - 1) * 10 + index + 1;
    }

    return [
      {
        meta: {
          accountId
        },
        label: rank,
        labelClasses: 'font-bold'
      },
      {
        image: imageUrl,
        label: name,
        type: TYPES.IMAGE_WITH_TEXT,
        onClick: () => {
          setSelectedUserAccount({
            id: account._id,
            name,
            firstName: name.split(' ')[0],
            imageUrl
          });
          setIsModalOpen(true);
        }
      },
      {
        label: title
      },
      ...(isFinite(avgScoreReceived)
        ? [
          {
            progress: avgScoreReceived,
            label: avgScoreReceived,
            type: TYPES.PROGRESS_BAR
          }
        ]
        : [
          {
            label: avgScoreLabel
          }
        ]),
      {
        badges: [
          {
            label: comparisonLabel,
            colorClasses: badgeColorClasses,
            labelClasses: '!font-bold',
            badgeClasses: '!w-[100%] !rounded-full',
            containerClasses: 'w-[40px]'
          }
        ],
        type: TYPES.BADGES
      }
    ];
  });

  const isLoading = isFetchingScores;
  const pagination = {
    currentPage,
    setCurrentPage: (p) => updateContext({
      currentPages: {
        ...currentPages,
        teamMemberPerformance: p
      }
    }),
    totalPages: page.totalPages
  };
  return (
    <>
      <AlertModal
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
        onAction={() => {
          window.open(appUtils.getDashRoute(selectedUserAccount.id), '_blank');
          setIsModalOpen(false);
          setSelectedUserAccount(null);
        }}
        title={get(selectedUserAccount, 'name', 'View More Details')}
        content={`View ${get(selectedUserAccount, 'firstName', 'this user')}'s dashboard see more details.`}
        actionText='View Dashboard'
        isLoading={isFetching}
        type='success'
        customIcon={(
          <CircleV2
            imageUrl={get(selectedUserAccount, 'imageUrl', null)}
            size='sm'
          />
        )}
      />
      <Base
        classes={`${STYLE.CONTAINER_WHITE} !p-4`}
        relative
        loading={isLoading}
      >
        <CircledIcon svg={<GrowingBarChartSVG className='text-purple' />} />
        <p className='text-xl text-black font-bold mb-0 mr-8'>
          Team Member Performance Score Ranking
        </p>
        <p className='text-md font-normal my-2 leading-5 text-bluish-gray mb-6'>
          Real-time performance ranking for the team based on up-to-date review
          data.
        </p>
        <Table
          rowClasses='!px-0 [&>*:first-child]:!pl-4 [&>*:last-child]:!pr-0'
          headClasses='[&_*]:!text-sm [&_*]:!truncate [&>*:first-child]:!pl-0 [&>*:last-child]:!pr-0'
          columns={columns}
          rows={rows}
          pagination={pagination}
        />
      </Base>
    </>
  );
};

export default TeamMemberPerformance;
