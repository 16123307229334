import React, { useContext } from 'react';
import { useTree } from 'src/queries/tree';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { HistoricReviews, appUtils } from 'src/components';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useAccount } from 'src/queries/account';
import commonPermissions from 'common/commonPermissions';
import CompetencyScores from 'src/containers/UserProfile/AnalyticsDashboard/components/CompetencyScores';
import OverallScores from 'src/containers/UserProfile/AnalyticsDashboard/components/OverallScores';
import { get } from 'lodash';
import { useCompany } from 'src/queries/company';
import MobileUserAnalytics from 'src/containers/Dash/UserFeedback/mobile/Analytics';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import { useReviews } from 'src/queries/reviews';
import RangeComparedScores from 'src/containers/UserProfile/AnalyticsDashboard/components/RangeComparedScores';

const { VIEW_SCORES_PERMISSIONS } = COMMON_CONSTANTS;

const AnalyticsDashboard = ({ userId }) => {
  const loggedUserId = appUtils.getLoggedUserId();
  const isMyProfile = userId === loggedUserId;
  const { context, updateContext } = useContext(
    isMyProfile ? DashContext : UserProfileContext
  );
  const { viewerId: contextViewerId } = context;
  const viewerId = contextViewerId || loggedUserId;

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const { showDashboardItems } = useFlags();
  const {
    data: viewerAccount,
    isFetching: isFetchingViewerAccount,
    isError: isErrorViewerAccount
  } = useAccount(viewerId);

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    reviewerIds, reviewerGroup, category, roles
  } = get(
    context,
    'filters',
    {}
  );
  const { start, end, value } = get(context, 'range', {});

  const {
    data: { reviews, pagination: reviewsPagination },
    isFetching: isFetchingHistoricReviews,
    isRefetching: isRefetchingHistoricReviews,
    isError: isErrorHistoricReviews
  } = useReviews({
    userId,
    filters: {
      reviewerIds,
      reviewerGroup,
      category,
      roles,
      range: value,
      start,
      end,
      includeNA: true,
      includeAnonymous: true,
      includeEmptyComments: true
    },
    viewerId,
    options: {
      page: get(context, 'historicReviews.page', 1),
      size: get(context, 'historicReviews.pageSize', 1)
    },
    queryOptions: { keepPreviousData: true }
  });

  const isInitialFetchingHistoricReviews = isFetchingHistoricReviews && !isRefetchingHistoricReviews;
  const isFetching = isFetchingTree
    || isFetchingCompany
    || isFetchingViewerAccount
    || isInitialFetchingHistoricReviews;
  const isError = isErrorTree
    || isErrorCompany
    || isErrorViewerAccount
    || isErrorHistoricReviews;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const mobileSize = appUtils.getMobileSize();
  const isMobile = mobileSize;

  if (isMyProfile && isMobile) {
    return <MobileUserAnalytics />;
  }

  const canManageUser = commonPermissions.canManageUsers(viewerAccount, tree, [
    userId
  ]);
  const shouldRenderMyProfile = isMyProfile;
  const shouldRenderUserProfile = !isMyProfile && showDashboardItems;

  const companyViewScorePermissions = get(
    company,
    'settings.viewScores',
    VIEW_SCORES_PERMISSIONS.MANAGERS
  );
  const isViewingAsUser = viewerId === userId;
  const canViewScores = isViewingAsUser
    ? companyViewScorePermissions === VIEW_SCORES_PERMISSIONS.ALL
    : canManageUser;
  const canViewHistoricReviews = !isViewingAsUser && canViewScores;

  return (
    <>
      {(shouldRenderMyProfile || shouldRenderUserProfile) && (
        <div className='h-full w-full flex gap-3'>
          <div className='w-2/3'>
            <FilterView
              userId={userId}
              show={['reviewers', 'roles', 'categories', 'date']}
              alwaysDisplayFields={['reviewers', 'roles', 'categories', 'date']}
              classes='!w-full !justify-between'
              wrapperClasses='!w-1/3'
              selectClasses='!w-full'
            />
            <CompetencyScores
              userId={userId}
              showToggle={shouldRenderUserProfile}
              viewerId={contextViewerId}
              setViewerId={(id) => updateContext({ viewerId: id })}
            />
            {canViewScores ? (
              <RangeComparedScores userId={userId} viewerId={viewerId} />
            ) : null}
          </div>
          <div className='w-1/3'>
            <OverallScores userId={userId} viewerId={viewerId} />
          </div>
        </div>
      )}

      {canViewHistoricReviews && (
        <>
          <HistoricReviews
            showVisibleTooltip
            userId={userId}
            reviews={reviews}
            isLoading={isFetchingHistoricReviews}
            showRequestReview={canManageUser}
            pagination={{
              totalPages: reviewsPagination.pages,
              currentPage: reviewsPagination.current,
              selectPage: (p) => updateContext({
                historicReviews: {
                  ...context.historicReviews,
                  page: parseInt(p, 10)
                }
              })
            }}
          />
        </>
      )}
    </>
  );
};

export default AnalyticsDashboard;
