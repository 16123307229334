import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

export default {
  range: {
    value: DATE_RANGE_FILTERS.THREE_MONTHS.key,
    start: commonDateUtils.getFirstUnixOfDate(
      commonDateUtils.getDateFromDaysAgo(DATE_RANGE_FILTERS.THREE_MONTHS.days)
        .date
    ),
    end: undefined
  },
  isFlyoutOpen: false,
  reviewers: [],
  reviewees: [],
  roleIds: [],
  reportIsSelf: undefined,
  reportStatus: [],
  topPerformers: {
    page: 1,
    size: 10
  },
  bottomPerformers: {
    page: 1,
    size: 10
  },
  topParticipants: {
    page: 1,
    size: 10
  },
  bottomParticipants: {
    page: 1,
    size: 10
  },
  completionAvg75to100: {
    page: 1,
    size: 5
  },
  completionAvg50to74: {
    page: 1,
    size: 5
  },
  completionAvg25to49: {
    page: 1,
    size: 5
  },
  completionAvg0to24: {
    page: 1,
    size: 5
  },
  filters: {
    reviewerIds: null,
    reviewerGroup: null,
    revieweeIds: null,
    revieweeGroup: null,
    category: null,
    roles: null,
    managerId: null,
    sentiment: null,
    answer: null
  },
  currentPage: 1,
  currentPages: {
    teamMemberPerformance: 1,
    reviewParticipationRanking: 1,
    historicReviewsFeed: 1,
    constructiveReviewRanking: 1
  },
  currentSort: {
    teamMemberPerformance: { field: 'avgScoreReceived', order: 'desc' },
    reviewParticipationRanking: { field: 'completionAverage', order: 'desc' },
    constructiveReviewRanking: { field: 'avgScoreGiven', order: 'desc' }
  }
};
