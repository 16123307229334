import React, { cloneElement, useEffect } from 'react';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
// import Header from 'src/containers/CompanyDash/Header/Header'; // DEPRECATE
import STYLE from 'src/constants/style';
import { Base, Select, toast } from 'src/components';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import OrganizationTabNavigator, {
  TABS
} from 'src/components/TabNavigator/OrganizationTabNavigator';
import { useNavigate } from 'react-router-dom';
import { exportCompanyQuery } from 'src/pagesDashboard/CompanyDash/queries/queries';
import { COMPANY_EXPORT_VIEWS } from 'common/commonCompanyUtils';

const { ACCESS } = COMMON_CONSTANTS;

const CompanyDash = ({ children }) => {
  const loggedUser = appUtils.getLoggedUser();

  const navigate = useNavigate();

  const isAdmin = loggedUser.access === ACCESS.ADMIN;

  useEffect(() => {
    if (!isAdmin) {
      console.error(
        'User does not have access to company dash, redirecting to /dashboard/me/feedback'
      );
      toast.error('You do not have access to this page');
      navigate(appUtils.getDashRoute());
    }
  }, [isAdmin]);

  const { mutateAsync: exportData, isLoading: isLoadingExportData } = exportCompanyQuery(COMPANY_EXPORT_VIEWS.ORGANIZATION);

  const exportFn = async () => {
    await exportData('Organization');
    toast.show('Export successful');
  };

  const action = async (option) => {
    try {
      const isLoading = isLoadingExportData;
      if (isLoading) return toast.error('Please wait for the current action to complete!');

      if (option.id === 'excel') {
        await exportFn();
      }
    } catch (error) {
      return toast.error(error);
    }
  };

  const actionOptions = [{ id: 'excel', label: 'Export Data' }];
  const tabNavigatorActions = [
    {
      tabs: [TABS.ANALYTICS],
      hidden: !actionOptions.length,
      component: (
        <Select
          title='Actions'
          classes='w-36 h-[39.5px]'
          variant='shadow'
          onChange={async (option) => action(option)}
          options={actionOptions}
          optionsWidth='fit'
        />
      )
    }
  ];

  return (
    isAdmin && (
      <>
        <HeaderV2 overtitle='Dashboards' title='Organization' />
        <OrganizationTabNavigator actions={tabNavigatorActions} />
        <Base classes={STYLE.BASE}>{cloneElement(children)}</Base>
      </>
    )
  );
};

export default CompanyDash;
