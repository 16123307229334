import { datadogRum } from '@datadog/browser-rum';
import { get } from 'lodash';

const utils = {};

const isDatadogEnabled = () => process.env.ENABLE_DATADOG === 'true';

utils.init = () => {
  try {
    if (!isDatadogEnabled()) {
      return console.log('Datadog is disabled (enable from env config)');
    }
    datadogRum.init({
      applicationId: '6c6e19b5-477b-4c69-a0b9-cf5cf5cd802b',
      clientToken: 'pube82cac1ee2cd9fc3cde93b1d2385b433',
      site: 'us5.datadoghq.com',
      service: 'workstory-fe',
      // proxy: `${process.env.SERVICE}/admin/datadog`,
      env: process.env.NODE_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: '1.1.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      beforeSend: (event, context) => {
        // collect a RUM resource's response headers
        // if (event.type === 'resource' && event.resource.type === 'fetch') {
        //     event.context.responseHeaders = Object.fromEntries(context.response.headers)
        // }
        // console.log('******* beforeSend event', { event, context });
        if (get(event, 'usr.isSuperUser')) {
          // console.log('Datadog not recording superusers.');
          return false;
        }
        return true;
      }
    });
    datadogRum.addRumGlobalContext('referrer', document.referrer);
    datadogRum.startSessionReplayRecording();
    console.log('DataDog initialized.');
  } catch (error) {
    console.error('datadog.initDatadog', error);
    return false;
  }
};

utils.setUser = (user) => {
  try {
    if (!isDatadogEnabled()) {
      return;
    }
    datadogRum.setUserProperty('userId', user.id);
    datadogRum.setUserProperty('companyid', user.companyid);
    datadogRum.setUserProperty('email', user.email);
    datadogRum.setUserProperty('name', user.name);
    datadogRum.setUserProperty('access', user.access);
    datadogRum.setUserProperty('isSuperUser', user.isSuperUser);
    datadogRum.setUserProperty('status', user.status);
    datadogRum.setUserProperty('end', process.env.ENV);
    // console.log('Datadog user set', user);
  } catch (error) {
    console.error('datadog.setUser', error);
    return null;
  }
};

export default utils;
