import React, { useContext } from 'react';
import { useCompanyFeedback } from 'src/queries/feedback';
import FeedbackFeed from 'src/containers/UserProfile/FeedbackDashboard/components/FeedbackFeed';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';
import { appUtils, Pagination } from 'src/components/index';

const OrganizationFeedback = () => {
  const loggedUserId = appUtils.getLoggedUserId();

  const { context, updateContext } = useContext(CompanyDashContext);

  const feedbackFilters = {
    managerId: context.filters.managerId,
    includeUnassignedUsers: false,
    start: context.range.start,
    end: context.range.end,
    author: context.filters.reviewerIds,
    recipient: context.filters.revieweeIds,
    revieweeGroup: context.filters.revieweeGroup,
    reviewerGroup: context.filters.reviewerGroup,
    reviewerIds: context.filters.reviewerIds,
    roles: context.filters.roles,
    sentiment: context.filters.sentiment,
    category: context.filters.category
  };

  const options = {
    page: context.currentPage,
    size: 50,
    joinReviews: true
  };

  const {
    data: { feedbacks, pagination } = {},
    isFetching: isFetchingFeedbacks
    // isRefetching: isRefetchingFeedbacks
  } = useCompanyFeedback(
    { ...feedbackFilters, options },
    { keepPreviousData: true }
  );

  const selectPage = (page) => {
    appUtils.scrollToTop();
    updateContext({ currentPage: parseInt(page, 10) });
  };

  // const isInitialFetchingFeedbacks = isFetchingFeedbacks && !isRefetchingFeedbacks;
  // const isFetching = isInitialFetchingFeedbacks;

  return (
    <div className='flex flex-col w-full'>
      <FilterView
        userId={loggedUserId}
        show={[
          'reviewers',
          'reviewees',
          'roles',
          'multi-categories',
          'teams',
          'sentiment',
          'date'
        ]}
        alwaysDisplayFields={['reviewers']}
        customContext={CompanyDashContext}
        classes='flex-wrap'
        wrapperClasses='ml-0 pl-0'
        displayFlyout
        displayClearFiltersBtn
      />
      <FeedbackFeed
        feedbacks={feedbacks}
        isLoading={isFetchingFeedbacks}
        emptyText='Nothing here yet! Once the team recevied applicable feedback, it will be displayed here.'
        showUserSelect
        renderPagination={() => (
          <div className='mt-4'>
            <Pagination
              name='UserFeedback'
              totalPages={pagination.pages}
              currentPage={pagination.current}
              selectPage={selectPage}
              isLoading={isFetchingFeedbacks}
            />
          </div>
        )}
        subtitle='Real-time list of feedback comments shared for the team from performance reviews and feedback submissions.'
      />
    </div>
  );
};

export default OrganizationFeedback;
