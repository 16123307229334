import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { get, isEmpty, isNumber } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useTree } from 'src/queries/tree';
import {
  useReviewsV2,
  patchReviewQuery,
  QUERY_KEYS as REVIEWS_QUERY_KEYS
} from 'src/queries/reviews';
import { QUERY_KEYS as SCORE_QUERY_KEYS } from 'src/queries/score';
import { QUERY_KEYS as FEEDBACK_QUERY_KEYS } from 'src/queries/feedback';
import { useCompany } from 'src/queries/company';
import { useAccount } from 'src/queries/account';
import FeedbackQuill from 'src/components/Quill/FeedbackQuill';
import {
  Button, Base, Circle, Select, Radio, toast
} from 'src/components';
import DeleteReviewModal from 'src/components/HistoricReviews/DeleteReviewModal';
import { formatNoteText } from 'src/pagesDashboard/NewUserReport/utils';
import { getAvgSentimentLabel } from 'common/commonNlpUtils';
import commonPermissions from 'common/commonPermissions';
import commonReviewUtils from 'common/commonReviewUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonQuestions from 'common/commonQuestions';
import commonUtils from 'common/commonUtils';
import commonQuestionUtils from 'common/commonQuestionsUtils';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';
import CrossSVG from 'src/assets/cross.svg';

const {
  REVIEW_ANSWER_TYPE,
  REVIEW_STATUS,
  IM_NOT_SURE,
  ACCESS,
  SHARE_REVIEW_WITH,
  renderShareCommentsVisibilityOptions
} = COMMON_CONSTANTS;

const displayFormErrors = (errors) => {
  Object.keys(errors).forEach((key) => {
    const val = errors[key];
    const message = get(val, 'message');
    if (!message) {
      return;
    }
    toast.error(message);
  });
};

const emptyAnswer = {
  id: null,
  label: null
};

const emptySentiment = {
  id: 'N/A',
  label: 'N/A',
  value: null
};

const EditReview = () => {
  const navigate = useNavigate();
  const { reviewId } = useParams();
  const queryClient = useQueryClient();
  const close = () => navigate(-1);

  const [showDeleteReviewModal, setShowDeleteReviewModal] = useState(false);

  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: reviews,
    isFetching: isFetchingReviews,
    isError: isErrorReviews
  } = useReviewsV2(
    {
      ids: [reviewId]
    },
    {
      page: {
        size: 1
      },
      include: {
        reviewer: true,
        reviewee: true
      }
    }
  );

  const review = get(reviews, '0', {});

  const isFetching = isFetchingReviews
    || isFetchingCompany
    || isFetchingTree
    || isFetchingLoggedAccount;
  const isError = isErrorCompany || isErrorTree || isErrorReviews || isErrorLoggedAccount;
  const isReady = company
    && company.id
    && tree
    && tree.id
    && !isEmpty(review)
    && !isFetching
    && !isError;

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    mode: 'onChange'
  });

  const formValues = watch();

  const { mutateAsync: updateReview, isLoading: isLoadingUpdateReview } = patchReviewQuery();

  const [answerOptions, setAnswerOptions] = useState([]);
  const [isFreeText, setIsFreeText] = useState(false);

  useEffect(() => {
    if (isEmpty(review) && !isFetchingReviews) {
      toast.error('Review not found');
      navigate(-1);
    }
    if (!isEmpty(review) && !isFetchingReviews) {
      const { reviewee, reviewer } = review;

      if (
        !commonPermissions.canViewFeedback({
          tree,
          revieweeId: reviewee._id,
          reviewerId: reviewer._id,
          account: loggedAccount
        })
      ) {
        toast.error('You do not have enough permissions to edit this review');
        navigate(appUtils.getDashRoute());
      }
    }
  }, [isFetchingReviews, loggedAccount, navigate, review, tree]);

  useEffect(() => {
    if (
      isEmpty(formValues)
      && !isEmpty(review)
      && !isEmpty(company.questions)
      && !isFetchingReviews
    ) {
      const { sentiment } = review;

      const formattedReviewComments = review.comments
        ? review.comments.replaceAll('\n', '<br/>')
        : review.comments;

      const { questionId } = review;
      const COMPANY_QUESTIONS = company.questions;
      const questionObj = commonQuestions.getQuestion(
        questionId,
        COMPANY_QUESTIONS.QUESTIONS
      );

      const isFtxt = questionObj.answers.type === REVIEW_ANSWER_TYPE.FREE_TEXT;
      setIsFreeText(isFtxt);

      const options = [
        ...questionObj.answers.custom
          .sort((a, b) => (a.score < b.score ? 1 : -1))
          .map((a) => ({
            id: a.id,
            label: a.value
          })),
        {
          id: REVIEW_ANSWER_TYPE.NOT_AVAIL,
          label: IM_NOT_SURE
        }
      ];
      setAnswerOptions(options);

      const initialValues = {
        sentimentScore: isNumber(sentiment.score)
          ? {
            id: sentiment.score,
            label: sentiment.score,
            value: sentiment.score
          }
          : emptySentiment,
        sentimentRange: getAvgSentimentLabel(sentiment.score),
        sentimentReason: isNumber(sentiment.score) ? sentiment.reason : null,
        answer: isFtxt
          ? emptyAnswer
          : options.find((a) => a.label === review.answer),
        comments: formattedReviewComments,
        commentVisible: review.commentVisible,
        reason: ''
      };

      reset(initialValues);
    }
  }, [company, formValues, isFetchingReviews, isFreeText, reset, review]);

  if (!isReady) {
    return null;
  }

  const { reviewee, sentiment } = review;

  const visibilityOptions = renderShareCommentsVisibilityOptions({
    revieweeName: reviewee.name
  });
  const validVisibilityOptions = commonQuestionUtils.getShareCommentOptions(
    company.settings.reviewVisibilityOptions,
    visibilityOptions
  );

  const sentimentScoreOptions = Array.from(
    { length: (100 - -100) / 5 + 1 },
    (_, i) => i * 5 - 100
  ).map((i) => ({
    id: i.toString(),
    label: i.toString(),
    value: i
  }));

  const isAnonymous = review.commentVisible === SHARE_REVIEW_WITH.ANONYMOUS;
  const hasSentiment = isNumber(sentiment.score);

  if (!hasSentiment) sentimentScoreOptions.unshift(emptySentiment);

  const error = () => displayFormErrors(errors);

  const saveUpdate = async () => {
    const {
      reason,
      comments,
      commentVisible,
      answer,
      sentimentScore,
      sentimentReason: newSentimentReason
    } = watch();

    let formattedComments = comments;
    const plainComments = commonReviewUtils
      .getPlainTextFromHTML(formattedComments)
      .replace(' ', '');
    if (!plainComments.length) formattedComments = null;

    const newIsNA = answer.id && answer.id === REVIEW_ANSWER_TYPE.NOT_AVAIL;
    const updateData = {
      reviewId: review._id,
      reason,
      comments: formattedComments,
      commentVisible,
      answerId: answer.id,
      isNA: newIsNA
    };

    const newSentimentScore = sentimentScore.value;
    const newSentimentRange = isNumber(newSentimentScore)
      ? getAvgSentimentLabel(newSentimentScore)
      : null;
    const updatedSentiment = {
      ...review.sentiment,
      score: newSentimentScore,
      range: newSentimentRange,
      reason: newSentimentReason
    };

    if (!commonUtils.isSame(updatedSentiment, review.sentiment)) updateData.sentiment = updatedSentiment;

    const response = await updateReview({
      action: 'UPDATE_REVIEW',
      revieweeId: reviewee._id,
      data: updateData
    });

    if (!response || !response.success) {
      console.error('Failed to edit a review', { response, review });
      return toast.error("Oops, we've run into an issue. Try again later!");
    }

    toast.show('Review updated');

    queryClient.invalidateQueries(SCORE_QUERY_KEYS.SCORE);
    queryClient.invalidateQueries(REVIEWS_QUERY_KEYS.REVIEWS);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK_FEED);
    close();
  };

  const isSaveDisabled = () => {
    if (isLoadingUpdateReview) return true;
    const {
      comments,
      commentVisible,
      sentimentScore,
      sentimentReason,
      answer,
      reason
    } = watch();

    const formattedReviewComments = review.comments
      ? review.comments.replaceAll('\n', '<br/>')
      : review.comments;

    const plainComments = commonReviewUtils.getPlainTextFromHTML(comments);
    const plainPreviousComments = commonReviewUtils.getPlainTextFromHTML(
      formattedReviewComments
    );

    const isReviewDataChanged = plainComments !== plainPreviousComments
      || commentVisible !== review.commentVisible
      || sentimentScore.value !== review.sentiment.score
      || sentimentReason !== review.sentiment.reason
      || (answer.id && answer.label !== review.answer);

    const isReasonEmpty = reason === '';
    if (!isReasonEmpty && isReviewDataChanged) return false;
    return true;
  };

  return (
    <Base
      variant='transparent'
      classes={STYLE.BASE}
      loading={isFetchingReviews || isLoadingUpdateReview}
    >
      <Base classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS}`}>
        {showDeleteReviewModal ? (
          <DeleteReviewModal
            className='mt-48'
            close={() => setShowDeleteReviewModal(false)}
            data={review}
          />
        ) : null}
        <div>
          <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-t-md relative'>
            <div className='absolute right-0'>
              <button className='mr-2 mt-2' onClick={close}>
                <CrossSVG className='w-12 h-12 cursor-pointer' />
              </button>
            </div>
          </div>
          <div className='pb-6 flex flex-col items-center'>
            <Circle
              size='xxl'
              imageUrl={reviewee.imageUrl}
              classes='mb-4 user-setting-profile-image relative h-131 w-131'
            />
            <div className='text-center'>
              <div className='text-2xl bold'>{reviewee.name}</div>
              <div className='text-base text-gray-400'>{reviewee.title}</div>
            </div>
          </div>
        </div>
        <div className='p-4'>
          <div className='inline-block w-2/5 align-top text-left pl-2'>
            <h5 className='text-black focus:outline-none text-2xl mr-5 mb-2 inline-block font-bold mt-2'>
              Edit Review
            </h5>
          </div>
        </div>
        <div className='inline-block mini:w-4/5 mx-6 pt-8'>
          {!isFreeText ? (
            <>
              <div className='flex justify-between h-10 my-10'>
                <p className='mb-0 font-bold text-base text-gray-500'>
                  CURRENT ANSWER
                </p>
                <div className='pl-8 pr-6 w-4/6'>
                  <p>{review.answer}</p>
                </div>
              </div>
              <div className='flex justify-between h-10 my-10'>
                <p className='mb-0 font-bold text-base text-gray-500'>
                  NEW ANSWER
                </p>
                <div className='pl-8 pr-6 w-4/6'>
                  <Controller
                    control={control}
                    name='answer'
                    rules={{ required: true }}
                    render={({ field }) => {
                      const title = field?.value ? field.value.label : null;
                      return (
                        <Select
                          id='edit-review-answer-filter'
                          placeholder='Select answer'
                          classes='w-full'
                          options={answerOptions}
                          title={title}
                          {...field}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}
          <div className='flex justify-between h-auto my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>COMMENTS</p>
            <div className='pl-8 pr-6 w-4/6'>
              <Controller
                control={control}
                name='comments'
                rules={{
                  required: false
                }}
                render={({ field }) => (
                  <FeedbackQuill
                    value={field.value}
                    className='flex flex-col h-20rem pb-10'
                    onChange={(value) => {
                      field.onChange(formatNoteText(value));
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className='flex justify-between my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>
              COMMENT VISIBILITY
            </p>
            <div className='w-4/6 pl-8'>
              {visibilityOptions.map((option) => {
                const isValid = validVisibilityOptions.some(
                  (ao) => ao.value === option.value
                );
                const isCurrent = review.commentVisible === option.value;
                if (!isValid && !isCurrent) return null;

                return (
                  <div key={option.value} className='mb-2 font-bold'>
                    <Controller
                      name='commentVisible'
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { value, onChange } }) => (
                        <Radio
                          name='commentVisible'
                          checked={option.value === formValues.commentVisible}
                          value={value}
                          title={option.title}
                          onChange={() => onChange(option.value)}
                          disabled={isAnonymous || !isValid}
                        />
                      )}
                    />
                    <div>
                      <p className='text-dark-grey'>{option.comments}</p>
                    </div>
                  </div>
                );
              })}
              {formValues.commentVisible === SHARE_REVIEW_WITH.ANONYMOUS ? (
                <p className='text-xs text-red'>
                  Note! Anonymous reviews cannot have their visibility updated.
                </p>
              ) : null}
            </div>
          </div>
          {/* Sentiment Score start */}
          <div className='flex justify-between h-10 my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>
              SENTIMENT SCORE
            </p>
            <div className='pl-8 pr-6 w-4/6'>
              <Controller
                control={control}
                name='sentimentScore'
                rules={{
                  required: false,
                  onChange: (e) => {
                    const { value } = e.target.value;
                    if (!value) {
                      setValue(
                        'sentimentReason',
                        hasSentiment ? sentiment.reason : null
                      );
                    }
                  }
                }}
                render={({ field }) => {
                  const title = field?.value ? field.value.label : null;
                  return (
                    <Select
                      id='edit-review-sentiment-score-filter'
                      placeholder='Select answer'
                      classes='w-full'
                      options={sentimentScoreOptions}
                      title={title}
                      {...field}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className='flex justify-between h-10 my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>
              SENTIMENT RANGE
            </p>
            <div className='pl-8 pr-6 w-4/6'>
              <p>
                {getAvgSentimentLabel(get(formValues, 'sentiment.score', null))}
              </p>
            </div>
          </div>
          {get(formValues, 'sentiment.score', null) !== null ? (
            <div className='flex justify-between h-40 my-10'>
              <p className='mb-0 font-bold text-base text-gray-500'>
                SENTIMENT REASON
              </p>
              <div className='pl-8 pr-6 w-4/6 h-20'>
                <Controller
                  control={control}
                  name='sentimentReason'
                  rules={{
                    required: false
                  }}
                  render={({ field }) => (
                    <textarea
                      disabled={
                        get(formValues, 'sentiment.score', null) === null
                      }
                      className='block w-full resize-none h-40'
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          ) : null}
          {/* Sentiment Score end */}
          <div className='flex justify-between h-40 my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>REASON *</p>
            <div className='pl-8 pr-6 h-16 w-4/6'>
              <Controller
                control={control}
                name='reason'
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <textarea
                    className='block w-full resize-none h-40'
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className='flex mt-4 p-4 justify-between items-center'>
          <div className='inline-block'>
            <Button
              onClick={() => setShowDeleteReviewModal(true)}
              variant='red'
            >
              <span className='text-lg font-bold'>Delete</span>
            </Button>
          </div>

          <div className='p-4'>
            <Button
              onClick={handleSubmit(saveUpdate, error)}
              variant='yellow'
              disabled={isSaveDisabled()}
            >
              Done
            </Button>
          </div>
        </div>
      </Base>
    </Base>
  );
};

export default EditReview;
