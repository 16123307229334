import React, { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import commonTreeUtils from 'common/commonTreeUtils';
import { Select } from 'src/components';
import { useGoal, useGoals, useGoalsLookup } from 'src/queries/goal';
import { Controller } from 'react-hook-form';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useTree } from 'src/queries/tree';
import { useSearchUsers } from 'src/queries/user';
import { useQueryClient } from 'react-query';
import { useAccounts } from 'src/queries/account';

const { USER_STATE } = COMMON_CONSTANTS;

export const getParentAssigneeOptions = (users) => {
  if (!users) return [];
  return [
    { value: null, label: 'No parent goal' },
    ...users.map((user) => ({
      value: user.id || user._id,
      label: user.name
    }))
  ];
};

export const getParentGoalOptions = (goals) => {
  if (!goals) return [];
  return [
    ...goals.map((goal) => ({
      value: goal._id,
      label: goal.title
    }))
  ];
};

// if goalId is not null, then it cannot be part of the options
// why? you cannot set this goal as a parent role of itself
const ParentGoalField = ({
  control, goalId = null, setValue, watch
}) => {
  const {
    data: goal,
    isFetching: isFetchingGoal,
    isError: isErrorGoal
  } = useGoal(goalId);

  const [userSearchText, setUserSearchText] = useState('');
  const [goalSearchText, setGoalSearchText] = useState('');

  const { data: users, isFetching: isFetchingUsers } = useAccounts(
    {
      status: [USER_STATE.ACTIVE, USER_STATE.PASSIVE]
    },
    {
      page: {
        size: 25
      },
      search: {
        enabled: true,
        field: 'name',
        value: userSearchText
      }
    }
  );

  const parentGoalAssignee = watch('parentGoalAssignee');
  const { data: assigneeGoals, isFetching: isFetchingAssigneeGoals } = useGoals(
    {
      assignees: [parentGoalAssignee.value]
    },
    {
      page: {
        size: Number.MAX_SAFE_INTEGER
      },
      search: {
        enabled: Boolean(goalSearchText),
        field: 'title',
        value: goalSearchText
      }
    },
    Boolean(parentGoalAssignee.value)
  );
  const {
    data: { children: assigneeGoalsUnder },
    isFetching: isFetchingGoalsLookup
  } = useGoalsLookup(
    {
      rootId: get(goal, '_id'),
      treeId: get(goal, 'treeId')
    },
    {
      lookup: {
        direction: 'down',
        filter: {
          assignee: parentGoalAssignee.value
        }
      }
    },
    Boolean(parentGoalAssignee.value)
  );

  const validGoals = assigneeGoals.filter(
    (g) => !assigneeGoalsUnder.find((g2) => g2._id === g._id) && g._id !== goalId
  );

  const isFetching = isFetchingGoal;
  const isError = isErrorGoal;
  const isReady = !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  return (
    <>
      <div className='flex justify-between'>
        <p className='mb-0 font-bold text-base text-gray-500'>PARENT GOAL</p>
        <div className='pl-8 pr-6 w-4/6'>
          <Controller
            name='parentGoalAssignee'
            control={control}
            rules={{ required: 'Please select an assignee!' }}
            render={({ field }) => {
              const title = get(
                field.value,
                'label',
                'Select an assignee for the parent goal'
              );
              return (
                <Select
                  id='parent-goal-assignee-filter'
                  placeholder='Select an assignee'
                  classes='w-full'
                  options={getParentAssigneeOptions(users)}
                  title={title}
                  loading={isFetchingUsers}
                  showSearch
                  onSearch={(value) => {
                    setUserSearchText(value);
                  }}
                  onDropdownClose={() => {
                    setUserSearchText('');
                    setGoalSearchText('');
                  }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('parentGoal', {
                      value: null,
                      label: 'Select Goal'
                    });
                    setUserSearchText('');
                    setGoalSearchText('');
                  }}
                />
              );
            }}
          />
        </div>
      </div>
      {Boolean(get(parentGoalAssignee, 'value'))
      && validGoals !== 'loading'
      && validGoals.length ? (
        <div className='mt-2 flex justify-end'>
          <div className='pl-8 pr-6 w-4/6'>
            <Controller
              name='parentGoal'
              control={control}
              rules={{ required: 'Please select a parent for this goal!' }}
              render={({ field }) => {
                const title = get(field.value, 'label', 'Select Goal');
                return (
                  <Select
                    id='parent-goal-parent-filter'
                    placeholder='Select a parent goal'
                    classes='w-full'
                    options={getParentGoalOptions(validGoals)}
                    title={title}
                    loading={isFetchingAssigneeGoals || isFetchingGoalsLookup}
                    showSearch
                    onSearch={(value) => {
                      setGoalSearchText(value);
                    }}
                    onDropdownClose={() => {
                      setGoalSearchText('');
                    }}
                    {...field}
                  />
                );
              }}
            />
          </div>
        </div>
        ) : null}
      {Boolean(get(parentGoalAssignee, 'value'))
      && !isFetchingAssigneeGoals
      && !isFetchingGoalsLookup
      && !validGoals.length
      && !goalSearchText ? (
        <div className='mt-2 text-lg text-red'>
          Selected user has no valid goals assigned
        </div>
        ) : null}
    </>
  );
};

export default ParentGoalField;
