import React from 'react';
import { get, isEmpty } from 'lodash';
import Pagination from 'src/components/Pagination/Pagination';
import commonUtils from 'common/commonUtils';
import uuidv4 from 'uuid/v4';

const defaultRenderEmptyPlaceholder = (message = 'No data available') => (
  <div className='flex justify-center items-center h-52 w-full'>
    <p className='text-black text-md m-0'>{message}</p>
  </div>
);

const NumericHeatmap = ({
  columns,
  rows,
  data,
  maxValue = 100,
  minValue = 20,
  color = '#3758F9',
  intervals = [],
  pagination = {},
  placeholderMessage = 'No data available',
  renderEmptyPlaceholder = defaultRenderEmptyPlaceholder
}) => {
  const isPaginated = !isEmpty(pagination);
  const { currentPage, setCurrentPage, totalPages } = pagination || {};

  const renderLabel = (labelData, position = 'side') => {
    const { label, onClick, disabled = false } = labelData;
    const isDisabled = disabled || !onClick;

    let buttonClass = 'flex items-center enabled:hover:text-indigo-900 w-[100%]';
    if (isDisabled) {
      buttonClass += ' cursor-default';
    }

    const style = {};
    let labelClass = 'm-0 text-gray-900 leading-[16px] w-full';
    let overLabel = null;
    if (position === 'top') {
      style.margin = '2px';
      buttonClass += ' tooltip';
      labelClass
        += ' text-[10px] multiline-ellipsis-2 font-semibold text-center leading-[12px]';

      overLabel = label ? (
        <span className='tooltip-text bg-black text-white text-xs font-medium whitespace-nowrap -mt-[60px]'>
          {label}
        </span>
      ) : null;
    } else if (position === 'side') {
      style.height = '56px';
      style.width = '100px';
      style.padding = '0px 16px 0px 0px';
      labelClass += ' text-xs text-right multiline-ellipsis';
    }

    return (
      <button
        className={buttonClass}
        style={style}
        onClick={onClick}
        disabled={isDisabled}
      >
        {overLabel}
        <p className={labelClass}>{label}</p>
      </button>
    );
  };

  const renderCell = (cellData) => {
    const value = get(cellData, 'value', '');

    const style = {
      height: '50px'
    };

    if (cellData) {
      const interval = intervals.find((i) => i.min <= value && i.max >= value);
      if (!isEmpty(get(interval, 'color', null))) {
        style.backgroundColor = interval.color;
      } else {
        const opacity = isFinite(value) && value >= minValue
          ? value / maxValue
          : minValue / maxValue;
        const finalColor = commonUtils.changeHexOpacity(color, opacity);
        style.backgroundColor = finalColor;
      }
      style.color = '#FFFFFF';
    } else {
      style.backgroundColor = '#f0f0f0';
      style.color = '#000000';
    }

    return (
      <div
        className='text-xs font-semibold text-center rounded-[4px] flex items-center justify-center m-[2px]'
        style={style}
      >
        {value}
      </div>
    );
  };

  const isDataEmpty = isEmpty(rows);
  return (
    <>
      <div className='!inline-block overflow-hidden'>
        <table className='table-fixed w-[100%]'>
          <tbody>
            {rows.map((row, rowIndex) => (
              <React.Fragment key={`numeric-heatmap-row-${rowIndex}`}>
                {rowIndex === 0 ? (
                  <tr
                    key={`numeric-heatmap-top-${rowIndex}`}
                    id={`top-${rowIndex}`}
                  >
                    <th
                      key='numeric-heatmap-empty-column'
                      className='w-[100px]'
                    >
                      {renderLabel({ id: 'empty', label: '' }, 'top')}
                    </th>
                    {columns.map((column) => {
                      const { id } = column;

                      return (
                        <th key={`numeric-heatmap-column-${id || uuidv4()}`}>
                          {renderLabel(column, 'top')}
                        </th>
                      );
                    })}
                  </tr>
                ) : null}
                <tr key={`numeric-heatmap-side-${rowIndex}`}>
                  <th>{renderLabel(row, 'side')}</th>
                  {columns.map((column) => {
                    const cellData = data.find(
                      (cell) => cell.rowId === row.id && cell.colId === column.id
                    );

                    const rowId = get(cellData, 'rowId', uuidv4());
                    const colId = get(cellData, 'colId', uuidv4());

                    return (
                      <td key={`${rowId}-${colId}`}>{renderCell(cellData)}</td>
                    );
                  })}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        {isDataEmpty ? <>{renderEmptyPlaceholder(placeholderMessage)}</> : null}
        {isPaginated ? (
          <Pagination
            name='Table'
            totalPages={totalPages}
            currentPage={currentPage}
            selectPage={setCurrentPage}
          />
        ) : null}
      </div>
    </>
  );
};

export default NumericHeatmap;
