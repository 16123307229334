import React, { useEffect, useState } from 'react';
import { Select } from 'src/components/';
import { useCompany } from 'src/queries/company';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';
import { getCategoryOptions } from 'src/utils/historicFilterUtils';
import LoadingSelect from 'src/containers/UserProfile/FilterView/LoadingSelect';

const getCategoryTitle = (category, categoryOptions) => {
  if (!categoryOptions) return 'No categories available';

  const isCategoryAnArray = Array.isArray(category);
  let filterTitle = isCategoryAnArray
    ? 'Filter by Categories'
    : 'Filter by Category';
  if (!category) {
    return filterTitle;
  }
  if (isCategoryAnArray) {
    if (category.length > 1) {
      filterTitle = `${category.length} category selected`;
    }
    if (category.length === 1) {
      filterTitle = `1 category selected`;
    }
  }
  if (!isCategoryAnArray) {
    const selectedCategory = categoryOptions.find((c) => c.id === category);
    return `Filter by ${selectedCategory ? selectedCategory.label : 'category'}`;
  }
  return filterTitle;
};

const FilterByCategories = ({
  id,
  isMyProfile,
  userId,
  category,
  updateFn,
  multiSelect,
  customSelectClasses,
  setIsLoading = () => {}
}) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const MyProfile = useMyProfile();
  const UserProfile = useUserProfile(userId);
  const {
    myCategories,
    isFetching: isFetchingMyCategories,
    isError: isErrorMyCategories
  } = isMyProfile ? MyProfile : UserProfile;

  const isFetching = isFetchingCompany || isFetchingMyCategories;
  const isError = isErrorCompany || isErrorMyCategories;
  const isReady = company && company.id && !isFetching && !isError;

  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    const options = getCategoryOptions(myCategories, category);
    setCategoryOptions(options);
  }, [myCategories?.length, category]);

  if (!isReady) {
    return (
      <LoadingSelect
        variant='shadow'
        title='Filter by category'
        customSelectClasses={customSelectClasses}
      />
    );
  }

  const filterCategoryTitle = getCategoryTitle(category, categoryOptions);

  const clearFilter = () => updateFn({ category: null });

  const onChange = (selectedOption) => {
    if (multiSelect) {
      const exists = category && category.includes(selectedOption.id);
      const currentCategories = category ?? [];
      const newCategories = exists
        ? currentCategories.filter(
          (curSentiment) => curSentiment !== selectedOption.id
        )
        : [...currentCategories, selectedOption.id];

      return updateFn({ category: newCategories });
    }
    updateFn({ category: selectedOption.id });
  };

  return (
    <Select
      id={id}
      variant='shadow'
      optionsWidth='wide'
      title={filterCategoryTitle}
      options={categoryOptions}
      multiselect={multiSelect}
      onChange={onChange}
      classes={customSelectClasses ?? 'w-60'}
      showXButton={Array.isArray(category) ? category.length : category}
      onClickXButton={clearFilter}
      disabled={!categoryOptions.length}
    />
  );
};

export default FilterByCategories;
