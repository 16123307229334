import React, { useEffect, useState } from 'react';
import { useAccount, useAccountsScore } from 'src/queries/account';
import STYLE from 'src/constants/style';
import COMMON_CONSTANTS from 'common/commonConstants';
import { appUtils, Base } from 'src/components/index';
import { useCompany } from 'src/queries/company';
import commonQuestionsUtils from 'common/commonQuestionsUtils';
import NumericHeatmap from 'src/containers/DashGraphs/NumericHeatmap';
import commonDateUtils from 'common/commonDateUtils';
import { useTree } from 'src/queries/tree';
import CompetencyComparisonFilters from 'src/pagesDashboard/CompanyDash/components/CompetencyComparisonFilters';
import { get, uniq } from 'lodash';
import { AlertModal } from 'src/componentsTailwind/index';
import CircleV2 from 'src/components/Circle/CircleV2';

const { USER_STATE, DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

const getDefaultFilters = (tree, company, maxCategories = 0) => {
  const defaultManagerId = get(tree, 'id', null);
  const defaultRoleIds = uniq(
    [
      ...get(tree, 'roles', []),
      ...get(tree, 'children', []).map((node) => node.roles)
    ].flat()
  );
  const companyQuestions = get(company, 'questions', {});
  const defaultRoles = defaultRoleIds.map((roleId) => commonQuestionsUtils.findRole(companyQuestions, roleId));
  const defaultCategoryIds = uniq(
    defaultRoles.map((role) => role.categories).flat()
  ).splice(0, maxCategories);

  return {
    range: {
      value: DATE_RANGE_FILTERS.THREE_MONTHS.key,
      start: commonDateUtils.getFirstUnixOfDate(
        commonDateUtils.getDateFromDaysAgo(DATE_RANGE_FILTERS.THREE_MONTHS.days)
          .date
      ),
      end: undefined
    },
    managerId: defaultManagerId,
    selectedRoleIds: defaultRoleIds,
    selectedCategoryIds: defaultCategoryIds
  };
};

const CompetencyComparison = () => {
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const {
    data: selectedUserAccount,
    isFetching: isFetchingSelectedUserAccount
  } = useAccount(selectedUserId);

  const maxCategories = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(
    getDefaultFilters(tree, company, maxCategories)
  );

  useEffect(() => setCurrentPage(1), [filters]);

  const { range, managerId, selectedCategoryIds } = filters;

  const {
    data: scores,
    meta: {
      page: { totalPages }
    },
    isFetching: isFetchingScores,
    isRefetching: isRefetchingScores,
    isError: isErrorScores
  } = useAccountsScore(
    {
      ids: [managerId],
      managerId,
      ...(range.start && {
        start: range.start,
        end: range.end
      }),
      notStatus: [USER_STATE.UNASSIGNED]
    },
    {
      bundleCategories: true,
      include: { account: true },
      page: {
        number: currentPage,
        size: 25
      }
    },
    {
      keepPreviousData: true,
      enabled: Boolean(managerId)
    }
  );

  const isInitialFetchingScores = isFetchingScores && !isRefetchingScores;
  const isFetching = isInitialFetchingScores || isFetchingCompany || isFetchingTree;
  const isError = isErrorScores || isErrorCompany || isErrorTree;
  const isReady = company && company.id && !isFetching && !isError;
  if (!isReady) {
    return null;
  }

  const companyQuestions = company.questions;
  const cols = selectedCategoryIds.map((categoryId) => {
    const category = commonQuestionsUtils.findCategory(
      companyQuestions,
      categoryId
    );
    return {
      id: categoryId,
      label: category.label
    };
  });
  const rows = [];

  const allBundledCategories = scores
    .map((score) => {
      const { account, bundledCategories } = score;
      return bundledCategories.map((bundledCategory) => {
        const { _id: categoryId, avgScore } = bundledCategory;

        if (!rows.some((row) => row.id === account._id)) {
          rows.push({
            id: account._id,
            label: account.name,
            onClick: () => {
              setSelectedUserId(account._id);
              setIsModalOpen(true);
            }
          });
        }

        return {
          value: avgScore,
          colId: categoryId,
          rowId: account._id
        };
      });
    })
    .flat();

  const isLoading = isFetchingScores || isFetchingCompany || isFetchingTree;

  const pagination = totalPages > 1 ? { totalPages, currentPage, setCurrentPage } : null;

  const intervals = [
    { min: 0, max: 20, color: '#FF5964' },
    { min: 21, max: 40, color: '#FF9EA4' },
    { min: 41, max: 60, color: '#D9D9D9' },
    { min: 61, max: 80, color: '#94D486' },
    { min: 81, max: 100, color: '#61D04B' }
  ];

  return (
    <>
      <AlertModal
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
        onAction={() => {
          window.open(appUtils.getDashRoute(selectedUserId), '_blank');
          setIsModalOpen(false);
          setSelectedUserId(null);
        }}
        title={get(selectedUserAccount, 'name', 'View More Details')}
        content={`View ${get(selectedUserAccount, 'firstName', 'this user')}'s dashboard see more details.`}
        actionText='View Dashboard'
        isLoading={isFetchingSelectedUserAccount}
        type='success'
        customIcon={(
          <CircleV2
            imageUrl={get(selectedUserAccount, 'imageUrl', null)}
            size='sm'
          />
        )}
      />
      <Base
        classes={`${STYLE.CONTAINER_WHITE} !p-4`}
        relative
        loading={isLoading}
      >
        <CompetencyComparisonFilters
          filters={filters}
          setFilters={setFilters}
          maxCategories={maxCategories}
        />
        <div className='border-b border-mid-gray my-3 transform -translate-x-[16px] w-[calc(100%+32px)]' />
        <p className='text-xl text-black font-bold mb-0 mr-8'>
          Competency Comparison
        </p>
        <p className='text-md font-normal my-2 leading-5 text-bluish-gray'>
          Viewing highest and lowest performance within shared competencies.
        </p>
        <NumericHeatmap
          columns={cols}
          rows={rows}
          data={allBundledCategories}
          maxValue={100}
          minValue={20}
          pagination={pagination}
          intervals={intervals}
          placeholderMessage='Select roles and categories to view data'
        />
      </Base>
    </>
  );
};

export default CompetencyComparison;
