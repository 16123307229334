import React, { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import commonTreeUtils from 'common/commonTreeUtils';
import { useTree } from 'src/queries/tree';
import SquaresGridSVG from 'src/assets/svg/squares-grid.svg';
import PieChartSVG from 'src/assets/svg/pie-chart.svg';
import BuilderSVG from 'src/assets/svg/builder.svg';
import SidebarButton from 'src/containers/Sidebar/SidebarButton';
import SidebarNavButton from 'src/containers/Sidebar/SidebarNavButton';
import { appUtils } from 'src/components/index';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useNavigate } from 'react-router-dom';

const {
  SIDEBAR_PAGES: { DASHBOARDS, TRACKING }
} = COMMON_CONSTANTS;

const SidebarAnalytics = ({
  page,
  permissions = {
    isSuperUser: false,
    isAdmin: false,
    isManager: false
  },
  routeCallback = () => {}
}) => {
  const {
    showSidebarDashboardsTeam,
    showSidebarBuilder
  } = useFlags();
  const navigate = useNavigate();

  const [isDashboardsExpanded, setIsDashboardsExpanded] = useState(
    Object.values(DASHBOARDS).includes(page)
  );
  const [isTrackingExpanded, setIsTrackingExpanded] = useState(
    Object.values(TRACKING).includes(page)
  );

  const {
    data: dataTree,
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree({
    cacheTime: Infinity,
    staleTime: Infinity
  });
  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = dataTree && !isFetching && !isError;
  if (!isReady) return null;

  const { tree } = dataTree;
  const loggedUserId = appUtils.getLoggedUserId();
  const treeRow = commonTreeUtils.findNodeById(tree, loggedUserId);
  const hasChildren = treeRow && treeRow.id ? Boolean(treeRow.children.length > 0) : false;

  const canSeeTeam = showSidebarDashboardsTeam && (permissions.isAdmin || hasChildren);
  return (
    <div className='w-full flex flex-col'>
      <SidebarButton
        text='Dashboards'
        icon={<SquaresGridSVG />}
        onClick={() => setIsDashboardsExpanded(!isDashboardsExpanded)}
        expanded={isDashboardsExpanded}
      />
      {isDashboardsExpanded ? (
        <>
          <SidebarNavButton
            text='Individual'
            onClick={() => {
              navigate(appUtils.getDashRoute());
              routeCallback();
            }}
            selected={page === DASHBOARDS.MY_DASHBOARD}
          />
          {canSeeTeam ? (
            <SidebarNavButton
              text='Team'
              onClick={() => {
                navigate('/dashboard/team');
                routeCallback();
              }}
              selected={page === DASHBOARDS.TEAM_DASHBOARD}
            />
          ) : null}
          {permissions.isAdmin && (
            <SidebarNavButton
              text='Organization'
              onClick={() => {
                navigate('/dashboard/organization/analytics');
                routeCallback();
              }}
              selected={page === DASHBOARDS.ORGANIZATION_DASHBOARD}
            />
          )}
        </>
      ) : null}
      {
        showSidebarBuilder && (
          <SidebarButton
            text='Review Builder'
            icon={<BuilderSVG />}
            onClick={() => {
              navigate('/builder/dashboard');
              routeCallback();
            }}
          />
        )
      }
      {(permissions.isAdmin || permissions.isManager) && (
        <>
          <SidebarButton
            text='Tracking'
            icon={<PieChartSVG />}
            onClick={() => setIsTrackingExpanded(!isTrackingExpanded)}
            expanded={isTrackingExpanded}
          />
          {isTrackingExpanded ? (
            <>
              <SidebarNavButton
                text='Goals'
                onClick={() => {
                  navigate('/dashboard/goals');
                  routeCallback();
                }}
                selected={page === TRACKING.GOALS}
              />
              {/* <SidebarNavButton
                text='Reports'
                onClick={() => {}}
                selected={page === '---'}
              /> */}
              {permissions.isAdmin && (
                <SidebarNavButton
                  text='Pulse'
                  onClick={() => {
                    navigate('/dashboard/pulse');
                    routeCallback();
                  }}
                  selected={page === TRACKING.QUESTIONS}
                />
              )}
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default SidebarAnalytics;
