import React, { createContext, useReducer } from 'react';
import { updateData } from 'src/pagesDashboard/Organization/context/actions';
import reducer from './reducer';
import initialState from './state';

export const OrganizationContext = createContext();

const OrganizationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const updateContext = (data) => {
    dispatch(updateData(data));
  };
  const resetContext = () => updateContext(initialState);

  return (
    <OrganizationContext.Provider
      value={{
        state,
        dispatch,
        updateContext,
        resetContext
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationProvider;
