import React, { useEffect, useState } from 'react';
import { isNumber } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import STYLE from 'src/constants/style';
import BulkReportsInputSection from 'src/pagesDashboard/CompanyDash/components/BulkReportsInputSection';
import commonDateUtils from 'common/commonDateUtils';
import { Controller, useForm } from 'react-hook-form';
import FilterByDate from 'src/containers/Dash/FilterView/FilterByDate';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import {
  toast, Select, Button, Base, Multiselect
} from 'src/components/';
import {
  createTeamReportsQuery,
  useBulkReportsPrepare
} from 'src/queries/reports';
import COMMON_CONSTANTS from 'common/commonConstants';
import AiTextIcon from 'src/assets/svg/ai-text-icon.svg';
import TraditionalReportIcon from 'src/assets/svg/traditional-report-icon.svg';
import { useNavigate } from 'react-router-dom';

const {
  TEAM_REPORT_TYPES,
  TEAM_REPORT_SCORES,
  DATE_RANGE_FILTERS,
  REPORT_GENERATION_TYPE
} = COMMON_CONSTANTS;

const reportTypeOptions = [
  {
    value: TEAM_REPORT_TYPES.PERFORMANCE_REVIEW,
    title: 'Performance Review'
  },
  {
    value: TEAM_REPORT_TYPES.SELF_EVALUATION,
    title: 'Self Evaluation'
  }
];

const includeScoresOptions = [
  {
    value: TEAM_REPORT_SCORES.YES,
    title: 'Yes, include scores on report'
  },
  {
    value: TEAM_REPORT_SCORES.NO,
    title: 'No, do not include scores on report'
  }
];

const dateStart = commonDateUtils.getDateFromDaysAgo(
  DATE_RANGE_FILTERS.ONE_YEAR.days
).unix;

const { SCHEDULING_STATUS } = COMMON_CONSTANTS;

const BulkReports = () => {
  const navigate = useNavigate();
  const {
    data: quietCompany,
    isFetching: isFetchingQuietCompany,
    isRefetching: isRefetchingQuietCompany,
    isPlaceholderData: isPlaceholderQuietCompany,
    refetch: refetchQuietCompany
  } = useCompany({
    bulkReports: true
  });
  const { enableCreateReviewsWithAi } = useFlags();

  const {
    data: { treeList },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    watch,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      includeScores: includeScoresOptions[0],
      reportType: reportTypeOptions[0],
      groups: [],
      dateRange: {
        value: DATE_RANGE_FILTERS.ONE_YEAR.key,
        start: dateStart,
        end: undefined
      },
      generationType: REPORT_GENERATION_TYPE.TRADITIONAL
      /* deadline: null */
    }
  });

  const {
    includeScores,
    reportType,
    groups,
    dateRange,
    generationType
    /* deadline */
  } = watch();

  const groupIds = groups
    ? groups.filter((g) => g.checked).map((g) => g.id)
    : [];

  const {
    data: { preparedReportsData },
    isFetching: isFetchingPrepareReports,
    refetch: refetchPrepareReports,
    isError: isErrorPrepareReports,
    isRefetching: isRefetchingPrepareReports,
    isPlaceholderData: isPlaceholderDataPrepareReports
  } = useBulkReportsPrepare(
    {
      groupIds,
      dateRange
    },
    {},
    { keepPreviousData: true }
  );

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  useEffect(() => {
    refetchPrepareReports();
  }, [groups, dateRange]);

  const { mutateAsync: createTeamReports, isLoading: isCreateReportLoading } = createTeamReportsQuery();

  const NOT_INITIALIZED = 'NOT_INITIALIZED';
  const [isCreatingBulkReports, setIsCreatingBulkReports] = useState(NOT_INITIALIZED);

  const syncIsCreatingBulkReports = async (intervalId) => {
    const {
      data: {
        jobs: { bulkReports: currentBulkReports }
      }
    } = await refetchQuietCompany();
    if (currentBulkReports === SCHEDULING_STATUS.IN_PROGRESS) return setIsCreatingBulkReports(true);
    if (intervalId) clearInterval(intervalId);
    setIsCreatingBulkReports(false);
  };

  useEffect(() => {
    let intervalId = null;
    const setIntervalCallback = async () => {
      await syncIsCreatingBulkReports(intervalId);
      intervalId = setInterval(
        async () => syncIsCreatingBulkReports(intervalId),
        5000
      );
    };
    setIntervalCallback();

    return () => clearInterval(intervalId);
  }, []);

  const isInitialFetchingPrepareReports = isFetchingPrepareReports
    && (isPlaceholderDataPrepareReports || !isRefetchingPrepareReports);
  const isInitialFetchingQuietCompany = isFetchingQuietCompany
    && (isPlaceholderQuietCompany || !isRefetchingQuietCompany);
  const isFetching = isInitialFetchingQuietCompany
    || isFetchingTree
    || isFetchingCompany
    || isInitialFetchingPrepareReports;
  const isError = isErrorTree || isErrorCompany || isErrorPrepareReports;
  const isReady = isCreatingBulkReports !== NOT_INITIALIZED
    && quietCompany
    && quietCompany.id
    && company
    && company.id
    && treeList
    && treeList.length
    && !isFetching
    && !isError;

  const getAllGroups = () => {
    const teams = treeList
      .filter((node) => node.children.length)
      .map((node) => ({
        id: node.id,
        name: `Team ${node.name}`
      }));
    return [...company.groups, ...teams];
  };
  const getAllGroupOptions = () => {
    if (!company.groups && !treeList.length) {
      return [];
    }
    const allGroups = getAllGroups();
    const allOptions = allGroups.map((g) => ({
      ...g,
      label: g.name,
      checked: false
    }));
    return allOptions;
  };
  useEffect(() => {
    if (!isReady) return;

    if (company && company.groups && treeList && treeList.length) {
      const allOptions = getAllGroupOptions();
      setValue('groups', allOptions);
    }
  }, [company, treeList, isReady]);

  if (!isReady) return null;

  const removeGroup = (groupObj) => {
    const uncheckedGroup = { ...groupObj, checked: false };
    const checkedGroups = groups.filter(
      (group) => group.id !== uncheckedGroup.id
    );
    setValue('groups', [...checkedGroups, uncheckedGroup]);
  };

  const addGroup = (groupObj) => {
    const checkedGroup = { ...groupObj, checked: true };
    const uncheckedGroups = groups.filter(
      (group) => group.id !== checkedGroup.id
    );
    setValue('groups', [...uncheckedGroups, checkedGroup]);
  };

  const toggleGroup = (group) => {
    const shouldRemove = groups.length ? group.checked : false;
    if (shouldRemove) {
      removeGroup(group);
      return;
    }
    addGroup(group);
  };

  const submit = async () => {
    toast.show('Creating Reviews...');
    const result = await createTeamReports({
      includeScores: includeScores.value,
      reportType: reportType.value,
      groups,
      dateRange,
      generationType
      // deadline
    });

    if (result.warning) return toast.error(result.message);
    if (!result.success) return toast.error('Failed to create reviews');

    navigate('/dashboard/organization/reports');
  };

  const renderWarning = () => {
    if (isCreateReportLoading || isCreatingBulkReports) {
      return (
        <div className='flex items-center bg-red h-6 px-2 mr-2 rounded-sm mb-2 text-white z-50 whitespace-nowrap'>
          Reviews are currently being created for your organization. Please wait
          before creating more.
        </div>
      );
    }
  };

  const { reportsToBeCreatedCount, revieweesCount } = preparedReportsData;

  const revieweesWithoutReviews = revieweesCount - reportsToBeCreatedCount;

  const isConfirmDisabled = () => {
    if (
      isFetchingTree
      || isFetchingCompany
      || isFetchingPrepareReports
      || isCreateReportLoading
      || isCreatingBulkReports
      || !reportsToBeCreatedCount
    ) {
      return true;
    }
    if (groups.every((g) => !g.checked)) {
      return true;
    }
    return false;
  };

  const reportsToBeCreatedMessage = {
    firstPart: `Action will create ${reportsToBeCreatedCount}/${revieweesCount} review(s)`,
    secondPart: `${revieweesWithoutReviews} team ${revieweesWithoutReviews === 1 ? 'member has' : 'members have'} no review data given the parameters specified above.`
  };

  const isLoading = isFetchingTree
    || isFetchingCompany
    || isFetchingPrepareReports
    || isCreateReportLoading;

  return (
    <Base classes={STYLE.CONTAINER_WHITE} loading={isLoading}>
      <div className='pl-10 pt-2'>
        <h1 className='mt-5'>Review Cycle Setup</h1>
        <div className='flex w-full justify-between'>
          <p className='text-gray-450 text-lg font-light mb-14'>
            Define the performance review parameters
          </p>
          {renderWarning()}
        </div>
        <div className='w-[70%]'>
          <BulkReportsInputSection
            id='team-report-range'
            labelText='Date Range'
            errorMessage={errors?.dateRange?.message}
          >
            <Controller
              name='dateRange'
              control={control}
              rules={{
                required: { value: true, message: 'Date Range is mandatory' }
              }}
              render={({ field: { value: fieldValue, onChange } }) => (
                <FilterByDate
                  id='team-report-range'
                  dateRangeSelected={fieldValue}
                  onSelectDateRange={(value, start, end) => onChange({ value, start, end })}
                  classes='w-full max-w-34rem'
                  customSelectClasses='h-10 w-full max-w-34rem'
                />
              )}
            />
          </BulkReportsInputSection>
          <BulkReportsInputSection
            id='team-report-type'
            labelText='Review Type'
            errorMessage={errors?.reportType?.message}
          >
            <Controller
              name='reportType'
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  id='bulk-reports-team-report-filter'
                  classes='w-full max-w-34rem'
                  variant='shadow'
                  title={value.title}
                  options={reportTypeOptions}
                  onChange={(option) => onChange(option)}
                />
              )}
            />
          </BulkReportsInputSection>
          <BulkReportsInputSection
            id='team-report-groups'
            labelText='To be Reviewed'
            errorMessage={errors?.reviewee?.message}
          >
            <Controller
              name='groups'
              control={control}
              render={() => (
                <div className='w-full max-w-34rem inline-block align-top'>
                  <Multiselect
                    disabled={isFetchingPrepareReports}
                    multiSelectSelectedItemClasses='w-8.5rem'
                    options={groups}
                    placeholder='Select Teams'
                    variant='minimal'
                    multiselect
                    onChange={(option, type) => {
                      if (type === 'select') {
                        return toggleGroup(option);
                      }
                      if (type === 'remove') {
                        return removeGroup(option);
                      }
                    }}
                  />
                </div>
              )}
            />
          </BulkReportsInputSection>
          {/* <TeamReportsInputSection
              id='team-report-deadline'
              labelText='Review Deadline'
              errorMessage={errors?.deadline?.message}
            >
              <Controller
                name='deadline'
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Review Deadline is mandatory'
                  }
                }}
                render={({ field: { value: fieldValue, onChange } }) => (
                  <TeamReportsDeadlinePicker
                    id='team-report-deadline'
                    date={fieldValue}
                    minDate={now}
                    onSelect={(value) => onChange(value)}
                    classes='w-full max-w-34rem'
                    customSelectClasses='h-10'
                  />
                )}
              />
            </TeamReportsInputSection> */}
          <BulkReportsInputSection
            id='team-report-scores'
            labelText='Include Scores?'
            errorMessage={errors?.includeScores?.message}
          >
            <Controller
              name='includeScores'
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  id='bulk-reports-scores-filter'
                  classes='w-full max-w-34rem'
                  variant='shadow'
                  title={value.title}
                  options={includeScoresOptions}
                  onChange={(option) => onChange(option)}
                />
              )}
            />
          </BulkReportsInputSection>
          {enableCreateReviewsWithAi && (
            <BulkReportsInputSection
              id='new-user-report-generation'
              type={REPORT_GENERATION_TYPE.AI}
              labelText='Report Generation'
              errorMessage={errors?.generation?.message}
            >
              <button
                type='button'
                onClick={() => setValue('generationType', REPORT_GENERATION_TYPE.AI)}
                className={`rounded-md mb-8 focus:outline-none bg-black ${
                  generationType === REPORT_GENERATION_TYPE.AI
                    ? 'border-4 border-purple'
                    : 'border-2 border-black'
                }`}
              >
                <div className='text-left px-3 py-4'>
                  <div className='flex justify-between'>
                    <p className='font-roboto-mono text-white text-xl mb-1 mt-1'>
                      WorkStoryAI
                    </p>
                    <AiTextIcon className='text-green' />
                  </div>
                  <p className='font-roboto-mono mb-1 font-bold text-white'>
                    AI-Generated Performance Review
                  </p>
                  <p className='font-roboto-mono mb-0 text-white'>
                    Automatically summarizes performance and recommends
                    opportunities for future development. Can edit later.
                  </p>
                </div>
              </button>
              <button
                type='button'
                onClick={() => setValue('generationType', REPORT_GENERATION_TYPE.TRADITIONAL)}
                className={`rounded-md focus:outline-none w-full ${
                  generationType === REPORT_GENERATION_TYPE.TRADITIONAL
                    ? 'border-4 border-purple'
                    : 'border-2 border-black'
                }`}
              >
                <div className='text-left px-3 py-4'>
                  <div className='flex justify-between'>
                    <p className='text-xl mb-1 mt-1'>Traditional</p>
                    <TraditionalReportIcon className='text-purple' />
                  </div>
                  <p className='mb-0 font-bold'>
                    Self-Authored Performance Review
                  </p>
                  <p className='mb-0'>
                    Manually review historic data while crafting your own
                    thoughts on performance and growth opportunities.
                  </p>
                </div>
              </button>
            </BulkReportsInputSection>
          )}
        </div>
        <div className='flex justify-end mb-3'>
          {isNumber(reportsToBeCreatedCount)
          && groups.some((g) => g.checked) ? (
            <div className='w-1/2 flex flex-col'>
              <p className='text-base font-semibold mb-0'>
                {reportsToBeCreatedMessage.firstPart}
              </p>
              {revieweesWithoutReviews ? (
                <p className='text-sm text-gray-400 font-semibold mb-0'>
                  {reportsToBeCreatedMessage.secondPart}
                </p>
              ) : null}
            </div>
            ) : null}
          <div className='w-1/2 flex justify-end'>
            <Button
              classes='mr-3 bg-white text-black text-lg font-bold px-8 py-2.5 border rounded border-black'
              type='button'
              variant='custom'
              onClick={() => navigate('/dashboard/organization/reports')}
            >
              Cancel
            </Button>
            <Button
              classes='mr-3 rounded'
              disabled={isConfirmDisabled()}
              type='submit'
              variant='purple'
              onClick={submit}
            >
              Create Reviews
            </Button>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default BulkReports;
