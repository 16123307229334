import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import Impersonate from 'src/containers/Admin/Impersonate';
import SuperUserInfo from 'src/containers/Admin/SuperUserInfo';
import STYLE from 'src/constants/style';
import {
  toast,
  Base,
  Select,
  appUtils,
  LoadingOverlay,
  Checkbox
} from '../../components';
import tableHeaders from '../Organization/tableHeaders';
import { appThunks, adminThunks } from '../../thunks';
import './Admin.scss';
import api from '../../services/api';

const Admin = (props) => {
  const { dispatch } = props;
  const queryClient = appUtils.getQueryClient();
  const loggedUser = appUtils.getLoggedUser();

  const [allCompanies, setAllCompanies] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [showImpersonateCompanies, setShowImpersonateCompanies] = useState(null);
  const [searchUserText, setSearchUserText] = useState('');
  const [searchCompanyText, setSearchCompanyText] = useState('');
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [companySelectTitle, setCompanySelectTitle] = useState('All');

  const [isFetching, setIsFetching] = useState(true);
  const [isChangingUser, setIsChagingUser] = useState(false);

  const [isCompanyActive, setIsCompanyActive] = useState(false);
  const [isCompanyEnabled, setIsCompanyEnabled] = useState(false);
  const [isCompanyReviewsEnabled, setIsCompanyReviewsEnabled] = useState(false);
  const [isCompanyPulseEnabled, setIsCompanyPulseEnabled] = useState(false);
  const [isCompanyGoalsEnabled, setIsCompanyGoalsEnabled] = useState(false);

  const fetchUsers = useCallback(() => {
    setIsFetching(true);
    api
      .get('/admin/users', {
        search: searchUserText,
        status: 'Active',
        companyid: selectedCompanyId
      })
      .then((resp) => {
        const options = resp.users.map((user) => {
          const isCurrentUser = user.email === loggedUser.email;
          return {
            id: user.email,
            label: `${user.name} - ${user.email} - ${user.access} ${isCurrentUser ? '(Current)' : ''}`,
            companyid: user.companyid,
            disabled: isCurrentUser
          };
        });
        setAllUsers(options);
        setIsFetching(false);
      });
  }, [loggedUser.email, searchUserText, selectedCompanyId]);

  const fetchCompanies = useCallback(() => {
    setIsFetching(true);
    api
      .get('/admin/companies', {
        search: searchCompanyText,
        active: isCompanyActive,
        enabled: isCompanyEnabled,
        reviews: isCompanyReviewsEnabled,
        pulse: isCompanyPulseEnabled,
        goals: isCompanyGoalsEnabled
      })
      .then((resp) => {
        const options = resp.companies.map((company) => {
          const isCurrentCompany = company.id === loggedUser.companyid;
          const hasReviewsEnabled = company.events.reviews === 'enabled';
          const hasGoalsEnabled = company.events.goals === 'enabled';
          const hasPulseEnabled = company.events.pulse === 'enabled';
          const hasAnyEventsEnabled = hasReviewsEnabled || hasPulseEnabled || hasGoalsEnabled;

          const eventsString = hasAnyEventsEnabled
            ? `Events: ${hasReviewsEnabled ? 'Reviews' : ''}${hasGoalsEnabled ? ', Goals' : ''}${hasPulseEnabled ? ', Pulse' : ''}`
            : `Events: None`;
          return {
            id: company.id,
            label: `${company.name} - ${company.id} - Active: ${company.active ? 'Yes' : 'No'} - Enabled: ${company.enabled ? 'Yes' : 'No'}  - ${eventsString} ${isCurrentCompany ? '(Current)' : ''}`
          };
        });
        options.unshift({
          id: null,
          label: `All`
        });
        setAllCompanies(options);
        setIsFetching(false);
      });
  }, [
    isCompanyActive,
    isCompanyEnabled,
    isCompanyGoalsEnabled,
    isCompanyPulseEnabled,
    isCompanyReviewsEnabled,
    loggedUser.companyid,
    searchCompanyText
  ]);

  useEffect(() => {
    fetchCompanies();
    fetchUsers();
  }, []);

  useEffect(() => fetchCompanies(), [fetchCompanies, searchCompanyText]);
  useEffect(
    () => fetchUsers(),
    [fetchUsers, searchUserText, selectedCompanyId]
  );

  const switchToUser = (userEmail, companyid) => {
    const userRow = allUsers.find((row) => row.id === userEmail);

    setIsChagingUser(true);
    adminThunks
      .adminLogin(
        userEmail,
        userRow ? userRow.companyid : companyid
      )(dispatch)
      .then((resp) => {
        appThunks.logUserIn(resp.userData)(dispatch);
        window.location.href = '/dashboard/organization/chart';
        queryClient.removeQueries();
        setIsChagingUser(false);
        window.location.reload(true);
      });
  };

  const copyTextToClipboard = (text) => {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    toast.show(`Id '${text}' copied to clipboard`);
  };

  const onQuickSelect = (userEmail) => {
    const isCurrentUser = userEmail === loggedUser.email;
    if (isCurrentUser) return toast.error(`Already logged in as ${userEmail}`);

    setIsChagingUser(true);
    api
      .get('/admin/users', {
        search: userEmail,
        status: 'Active'
      })
      .then((resp) => {
        if (!resp.users.length) return toast.error('User not found!');
        const selectedUser = resp.users[0];

        switchToUser(userEmail, selectedUser.companyid);
      })
      .catch(() => setIsChagingUser(false));
  };

  const onSelectCompany = (companyId) => {
    setSelectedCompanyId(companyId);
    const title = companyId
      ? allCompanies.find((a) => a.id === companyId)?.label
      : 'All';

    if (title) {
      setCompanySelectTitle(title);
    }
  };

  tableHeaders.ADMIN_REVIEWS_HEADERS[0].onClick = copyTextToClipboard;

  const searchCompany = (value) => setSearchCompanyText(value);
  const searchUser = (value) => setSearchUserText(value);

  if (isChangingUser) {
    return (
      <div className='flex m-auto'>
        <LoadingOverlay top={false} variant='xs' loading />
      </div>
    );
  }

  return (
    <div className={STYLE.BASE}>
      <Base className={STYLE.CONTAINER_WHITE}>
        <div className='flex justify-center gap-2 my-4'>
          <button
            className='bg-red hover:bg-red-400 p-2 w-40 text-black rounded-md'
            onClick={() => {
              console.error('Test rollbar error log', {
                custom: 'data',
                name: 'Jack'
              });
            }}
          >
            Trigger rollbar error
          </button>
          <button
            className='bg-red hover:bg-red-400 p-2 w-40 text-black rounded-md'
            onClick={() => {
              console.error('Test rollbar error log');
              api.get('/admin/test/error').then((resp) => {
                console.log('resp', resp);
              });
            }}
          >
            Trigger Cloud Error
          </button>
        </div>
        <h3 data-cy='impersonate-user-title'>Impersonate User</h3>
        <p className='mb-0'>
          Warning: if you impersonate button user that doesn't belong to an
          organization (there are some left over, will cleanup), your app will
          break. Logout and re-log.
        </p>

        <SuperUserInfo />
        <button
          className='block'
          onClick={() => setShowImpersonateCompanies(!showImpersonateCompanies)}
        >
          Show impersonate companies
        </button>
        {showImpersonateCompanies ? <Impersonate /> : null}

        <div className='flex flex-wrap gap-3 mt-6 justify-center'>
          <Checkbox
            label='Is Active'
            value={isCompanyActive}
            name='isActive'
            onChange={(v) => setIsCompanyActive(v)}
          />
          <Checkbox
            label='Is Enabled'
            value={isCompanyEnabled}
            name='isEnabled'
            onChange={(v) => setIsCompanyEnabled(v)}
          />
          <Checkbox
            label='Has Reviews Enabled'
            value={isCompanyReviewsEnabled}
            name='hasReviewsEnabled'
            onChange={(v) => setIsCompanyReviewsEnabled(v)}
          />
          <Checkbox
            label='Has Pulse Enabled'
            value={isCompanyPulseEnabled}
            name='hasPulseEnabled'
            onChange={(v) => setIsCompanyPulseEnabled(v)}
          />
          <Checkbox
            label='Has Goals Enabled'
            value={isCompanyGoalsEnabled}
            name='hasGoalsEnabled'
            onChange={(v) => setIsCompanyGoalsEnabled(v)}
          />
        </div>

        <Select
          id='admin-company-filter'
          classes='w-full text-black mt-4'
          title={companySelectTitle}
          options={allCompanies}
          placeholder='Select Company'
          onChange={(opt) => onSelectCompany(opt.id)}
          showSearch
          onSearch={debounce(searchCompany, 200)}
          onDropdownClose={() => setSearchCompanyText('')}
          loading={isFetching}
          disabled={isChangingUser}
        />

        <Select
          id='admin-users-filter'
          classes='w-full text-black mt-8'
          title='Select User'
          options={allUsers}
          placeholder='Select User'
          onChange={(opt) => switchToUser(opt.id)}
          showSearch
          onSearch={debounce(searchUser, 200)}
          onDropdownClose={() => setSearchUserText('')}
          loading={isFetching}
          disabled={isChangingUser}
        />

        {allUsers && allUsers.length ? (
          <>
            <div className='mt-10 mb-2 text-base text-black'>
              <p className='mb-2 text-sm text-gray-600'>Quick select:</p>
              <button
                data-cy='testonboard2@test.com'
                className='block mb-2 '
                onClick={() => onQuickSelect('testonboard2@test.com')}
              >
                testonboard2@test.com
              </button>
              <button
                data-cy='matt@getspeedback.com'
                className='block mb-2'
                onClick={() => onQuickSelect('matt@getspeedback.com')}
              >
                matt@getspeedback.com
              </button>
              <button
                data-cy='meadowsmz@gmail.com'
                className='block mb-2'
                onClick={() => onQuickSelect('meadowsmz@gmail.com')}
              >
                meadowsmz@gmail.com
              </button>
              <button
                data-cy='perm-test-admin@getspeedback.com'
                className='block mb-2'
                onClick={() => onQuickSelect('perm-test-admin@getspeedback.com')}
              >
                Automation Test Account - perm-test-admin@getspeedback.com
              </button>
              <br />
              <button
                data-cy='sam@2ton.com'
                className='block mb-2'
                onClick={() => onQuickSelect('sam@2ton.com')}
              >
                Sam - sam@2ton.com
              </button>
              <button
                data-cy='joe@palmbeachtech.org'
                className='block mb-2'
                onClick={() => onQuickSelect('joe@palmbeachtech.org')}
              >
                Joe - joe@palmbeachtech.org
              </button>
              <button
                data-cy='lamia@rocketmatter.com'
                className='block mb-2'
                onClick={() => onQuickSelect('lamia@rocketmatter.com')}
              >
                Lamia - lamia@rocketmatter.com
              </button>
              <button
                data-cy='sean@thedigitalintellect.com'
                className='block mb-2'
                onClick={() => onQuickSelect('sean@thedigitalintellect.com')}
              >
                Sean - sean@thedigitalintellect.com
              </button>
              <button
                data-cy='todd@bocacode.com'
                className='block mb-2'
                onClick={() => onQuickSelect('todd@bocacode.com')}
              >
                Todd - todd@bocacode.com
              </button>
              <button
                data-cy='dh@tsl.io'
                className='block mb-2'
                onClick={() => onQuickSelect('dh@tsl.io')}
              >
                David Hartmann - dh@tsl.io
              </button>
              <button
                data-cy='sduverseau@smartpandalabs.com'
                className='block mb-2'
                onClick={() => onQuickSelect('sduverseau@smartpandalabs.com')}
              >
                Shamir - sduverseau@smartpandalabs.com
              </button>
              <button
                data-cy='rich@purplerockscissors.com'
                className='block mb-2'
                onClick={() => onQuickSelect('rich@purplerockscissors.com')}
              >
                Richard Wahl - rich@purplerockscissors.com
              </button>
              <button
                data-cy='georgia@fusionideas.com'
                className='block mb-2'
                onClick={() => onQuickSelect('georgia@fusionideas.com')}
              >
                Georgia Dardick - georgia@fusionideas.com
              </button>
              <button
                data-cy='warren@mendfamily.com'
                className='block mb-2'
                onClick={() => onQuickSelect('warren@mendfamily.com')}
              >
                Warren Bates - warren@mendfamily.com
              </button>
              <button
                data-cy='pmyers@peakactivity.com'
                className='block mb-2'
                onClick={() => onQuickSelect('pmyers@peakactivity.com')}
              >
                Peggy Myers - pmyers@peakactivity.com
              </button>
              {/* <button data-cy="ngraham@gcdtech.com" className="block mb-2" onClick={() => { onQuickSelect('ngraham@gcdtech.com') }}>Nikki Graham - ngraham@gcdtech.com</button>
              <button data-cy="megan.baker@schlesingercos.com" className="block mb-2" onClick={() => { onQuickSelect('megan.baker@schlesingercos.com') }}>Megan Baker - megan.baker@schlesingercos.com</button> */}
              <button
                data-cy='staceypage@annuitas.com'
                className='block mb-2'
                onClick={() => onQuickSelect('staceypage@annuitas.com')}
              >
                Stacey Page - staceypage@annuitas.com
              </button>
              <button
                data-cy='fpatel@reibus.com'
                className='block mb-2'
                onClick={() => onQuickSelect('fpatel@reibus.com')}
              >
                Fal Patel - fpatel@reibus.com
              </button>
              <button
                data-cy='cweber@affirmus.com'
                className='block mb-2'
                onClick={() => onQuickSelect('cweber@affirmus.com')}
              >
                Chad Weber - cweber@affirmus.com
              </button>
              <button
                data-cy='brian@cloudcath.com'
                className='block mb-2'
                onClick={() => onQuickSelect('brian@cloudcath.com')}
              >
                Brian Fisher - brian@cloudcath.com
              </button>
              <button
                data-cy='cpierson@swensonhe.com'
                className='block mb-2'
                onClick={() => onQuickSelect('cpierson@swensonhe.com')}
              >
                Claudia - cpierson@swensonhe.com
              </button>
              <button
                data-cy='orlando@yourdigitalresource.com'
                className='block mb-2'
                onClick={() => onQuickSelect('orlando@yourdigitalresource.com', queryClient)}
              >
                Orlando - orlando@yourdigitalresource.com
              </button>
              <button
                data-cy='bob@docspera.com'
                className='block mb-2'
                onClick={() => onQuickSelect('bob@docspera.com')}
              >
                Bob - bob@docspera.com
              </button>
              <button
                data-cy='andy.morrise@loanpro.io'
                className='block mb-2'
                onClick={() => onQuickSelect('andy.morrise@loanpro.io')}
              >
                Andy Morrise - andy.morrise@loanpro.io
              </button>
              <button
                data-cy='j.lannon@chargebacks911.com'
                className='block mb-2'
                onClick={() => onQuickSelect('j.lannon@chargebacks911.com')}
              >
                Jaclyn Lannon - j.lannon@chargebacks911.com
              </button>
            </div>
          </>
        ) : null}
      </Base>
    </div>
  );
};

export const mapStateToProps = (state) => ({
  app: state.appReducer,
  dashboard: state.dashboardReducer,
  admin: state.adminReducer
});

export const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
