import React, { useState } from 'react';
import { isFinite } from 'lodash';
import { Base } from 'src/components/';
import { useCompanyDashboard } from 'src/pagesDashboard/CompanyDash/queries/queries';
import GroupModal from 'src/pagesDashboard/CompanyDash/components/GroupModal';
import STYLE from 'src/constants/style';
import { Table } from 'src/componentsTailwind/index';
import CircledIcon from 'src/components/CircledIcon/CircledIcon';
import GrowingBarChartSVG from 'src/assets/svg/growing-bar-chart.svg';
import { TYPES } from 'src/componentsTailwind/Table/Table';
import { BADGE_COLOR_CLASSES } from 'src/componentsTailwind/tailwindConstants';
import { getAvgSentimentLabel } from 'common/commonNlpUtils';

const GroupPerformance = () => {
  const { groups, isFetching } = useCompanyDashboard();
  const [group, setGroup] = useState(null);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);

  const columns = [
    {
      label: 'Name',
      type: TYPES.ACTION
    },
    {
      label: 'Performance Score',
      type: TYPES.PROGRESS_BAR,
      tooltip: false
    },
    {
      label: 'vs Team Avg',
      type: TYPES.BADGES,
      span: 0.75,
      tooltip: false
    },
    {
      label: 'Reviews Received',
      tooltip: false
    },
    {
      label: 'Feedback Received',
      tooltip: false
    },
    {
      label: 'Sentiment',
      tooltip: false
    }
  ];

  const rows = groups.map((group) => {
    const {
      groupId,
      groupName,
      score,
      reviewed,
      rich,
      sentiment,
      comparison,
      scoreLabel
    } = group;

    let badgeColorClasses = BADGE_COLOR_CLASSES.GRAY;
    let comparisonLabel = '-';

    if (isFinite(comparison)) {
      if (comparison > 0) {
        badgeColorClasses = BADGE_COLOR_CLASSES.GREEN;
        comparisonLabel = `+${comparison}`;
      } else if (comparison < 0) {
        badgeColorClasses = BADGE_COLOR_CLASSES.RED;
        comparisonLabel = comparison;
      }
    } else {
      comparisonLabel = 'N/A';
    }

    return [
      {
        meta: {
          groupId
        },
        label: groupName,
        type: TYPES.ACTION,
        onClick: () => {
          setGroup(group);
          setIsGroupModalOpen(true);
        },
        discrete: true
      },
      ...(isFinite(score)
        ? [
          {
            progress: score,
            label: score,
            type: TYPES.PROGRESS_BAR
          }
        ]
        : [
          {
            label: scoreLabel
          }
        ]),
      {
        badges: [
          {
            label: comparisonLabel,
            colorClasses: badgeColorClasses,
            labelClasses: '!font-bold',
            badgeClasses: '!w-[100%] !rounded-full',
            containerClasses: 'w-[40px]'
          }
        ],
        type: TYPES.BADGES
      },
      {
        label: isFinite(reviewed) ? reviewed : '-',
        labelClasses: 'font-bold'
      },
      {
        label: isFinite(rich) ? rich : '-',
        labelClasses: 'font-bold'
      },
      {
        label: isFinite(sentiment) ? getAvgSentimentLabel(sentiment) : '-',
        labelClasses: 'font-bold'
      }
    ];
  });

  return (
    <>
      <GroupModal
        isOpen={isGroupModalOpen}
        group={group}
        close={() => setIsGroupModalOpen(false)}
      />
      <Base
        classes={`${STYLE.CONTAINER_WHITE} !p-4`}
        relative
        loading={isFetching}
      >
        <CircledIcon svg={<GrowingBarChartSVG className='text-purple' />} />
        <p className='text-xl text-black font-bold mb-0 mr-8'>
          Group Performance Metrics
        </p>
        <p className='text-md font-normal my-2 leading-5 text-bluish-gray mb-6'>
          Performance data for your pre-built groups based on real-time review
          data.
        </p>
        <Table
          rowClasses='!px-0 [&>*:first-child]:!pl-4 [&>*:last-child]:!pr-0'
          headClasses='[&_*]:!text-sm [&_*]:!truncate [&>*:first-child]:!pl-4 [&>*:last-child]:!pr-0'
          columns={columns}
          rows={rows}
        />
      </Base>
    </>
  );
};

export default GroupPerformance;
