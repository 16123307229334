import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { get } from 'lodash';
import { useGoal, useGoalUpdate } from 'src/queries/goal';
import { useAccount } from 'src/queries/account';
import { useTree } from 'src/queries/tree';
import {
  Modal, toast, Button, Select
} from 'src/components/';
import commonPermissions from 'common/commonPermissions';
import commonTreeUtils from 'common/commonTreeUtils';
import commonDateUtils from 'common/commonDateUtils';
import commonGoalUtils from 'common/commonGoalUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { GOALS: GOAL_CONSTANTS } = COMMON_CONSTANTS;

const EditGoalUpdate = ({ goalId, updateId, close }) => {
  const {
    data: myAccount,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: goal,
    isFetching: isFetchingGoal,
    isError: isErrorGoal
  } = useGoal(goalId);

  const { mutateAsync: updateGoal, isLoading: isUpdatingGoal } = useGoalUpdate(
    get(goal, '_id')
  );

  const isBinary = get(goal, 'type') === GOAL_CONSTANTS.TYPES.BINARY;
  const update = get(goal, 'updates', []).find((u) => u._id === updateId);

  const isFetching = isFetchingGoal || isFetchingAccount || isFetchingTree;
  const isError = isErrorGoal || isErrorAccount || isErrorTree;
  const isReady = goal && update && !isFetching && !isError;

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isValid, errors, isDirty }
  } = useForm({
    defaultValues: {
      text: '',
      progress: {}
    }
  });

  const formValues = watch();

  const [canEditUpdate, setCanEditUpdate] = useState(false);

  useEffect(() => {
    if (!isReady) return;

    if (!isDirty) {
      const defaultValues = {
        text: update.text,
        progress: commonGoalUtils.getGoalProgressOption({
          ...goal,
          lastUpdate: update
        })
      };
      reset(defaultValues);
    }

    if (isReady) {
      const isUpdateAuthor = myAccount._id === update.latestAuthor;
      const isAdmin = commonPermissions.isAdmin(myAccount);
      const isViewerDirectlyAboveUser = commonTreeUtils.isNodeDirectlyAbove(
        tree,
        update.latestAuthor,
        myAccount._id
      );
      setCanEditUpdate(isUpdateAuthor || isAdmin || isViewerDirectlyAboveUser);
    }
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  const saveUpdate = async (values) => {
    try {
      toast.show('Saving...');
      const { progress, text } = values;
      const payload = [
        {
          _id: updateId,
          text,
          author: myAccount._id,
          timestamp: commonDateUtils.getUnixDateNow(),
          progress: progress.value || progress
        }
      ];

      const response = await updateGoal({ updates: payload });

      if (!response || !response.success) {
        return toast.error(response.error ?? 'Try again!');
      }
      if (response.warning) {
        const message = get(
          response,
          'message',
          'Failed to save update. Try again later!'
        );
        toast.closeAll();
        return toast.show(message);
      }
      toast.show('Update changed!');
      close();
      return true;
    } catch (error) {
      return toast.error(error ?? 'Try again!');
    }
  };

  const deleteUpdate = async () => {
    try {
      toast.show('Deleting update...');
      const payload = [updateId];
      const response = await updateGoal({ deleteUpdates: payload });

      if (!response || !response.success) {
        return toast.error(response.error ?? 'Try again!');
      }
      if (response.warning) {
        const message = get(
          response,
          'message',
          'Failed to delete goal. Try again later!'
        );
        toast.closeAll();
        return toast.show(message);
      }

      toast.show('Update deleted!');
      close();
      return true;
    } catch (error) {
      return toast.error(error ?? 'Try again!');
    }
  };

  const hasDataChanged = () => {
    if (formValues.text && formValues.text !== update.text) return true;
    if (isBinary) return Number(formValues.progress.value) !== Number(update.progress);
    if (Number(formValues.progress) !== Number(update.progress)) return true;
    return false;
  };

  return (
    <Modal variant='custom-no-scroll' innerClasses='w-2/5' close={close}>
      <form className='flex flex-col' onSubmit={handleSubmit(saveUpdate)}>
        <p className='font-bold mb-3 text-xl w-full'>
          {canEditUpdate ? `Edit update` : `View update`}
        </p>
        <p className='mb-1 text-left'>Comments:</p>
        <Controller
          control={control}
          name='text'
          rules={{
            required: { value: true, message: 'Write a comment' },
            minLength: {
              value: 5,
              message: 'Comments must be at least 5 characters long'
            }
          }}
          disabled={!canEditUpdate || isUpdatingGoal}
          render={({ field }) => (
            <textarea
              className='block w-full resize-none h-32'
              placeholder={update.text}
              {...field}
            />
          )}
        />
        <div className='text-xs text-red italic text-left h-1'>
          {errors.text ?? ''}
        </div>
        <p className='mb-1 mt-3'>Progress:</p>
        <Controller
          control={control}
          name='progress'
          rules={{
            required: true,
            ...(goal.type === GOAL_CONSTANTS.TYPES.PERCENT && {
              min: 0,
              max: 100
            })
          }}
          render={({ field }) => {
            if (goal.type === GOAL_CONSTANTS.TYPES.BINARY) {
              const title = field.value.label;
              const options = [
                { value: '0', label: 'Not done' },
                { value: '1', label: 'Done' }
              ];
              return (
                <Select
                  id='edit-goal-update-progress-filter'
                  placeholder='Any progress?'
                  classes='w-1/2 mx-auto'
                  options={options}
                  title={title}
                  {...field}
                />
              );
            }
            if (goal.type === GOAL_CONSTANTS.TYPES.DOLLAR) {
              return (
                <div className='flex items-center w-1/2 mx-auto'>
                  <span className='align-middle'>
                    {commonGoalUtils.getGoalType(goal.type)}
                  </span>
                  <input
                    className='w-full ml-1.5'
                    placeholder='Any progress?'
                    type='number'
                    value={field.value ?? ''}
                    {...field}
                  />
                </div>
              );
            }
            if (goal.type === GOAL_CONSTANTS.TYPES.NUMBER) {
              return (
                <div className='flex items-center w-1/2 mx-auto'>
                  <span className='align-middle'>
                    {commonGoalUtils.getGoalType(goal.type)}
                  </span>
                  <input
                    className='w-full ml-1.5'
                    placeholder='Any progress?'
                    type='number'
                    value={field.value ?? ''}
                    {...field}
                  />
                </div>
              );
            }
            if (goal.type === GOAL_CONSTANTS.TYPES.PERCENT) {
              return (
                <div className='flex items-center w-1/2 mx-auto'>
                  <span className='align-middle'>
                    {commonGoalUtils.getGoalType(goal.type)}
                  </span>
                  <input
                    className='w-full ml-1.5'
                    placeholder='Any progress?'
                    type='number'
                    min='0'
                    max='100'
                    value={field.value ?? ''}
                    {...field}
                  />
                </div>
              );
            }
          }}
        />
        <div className='py-5 w-full flex justify-between'>
          <div className='w-1/2 text-left'>
            <Button
              type='button'
              classes='text-xl'
              variant='transparent'
              onClick={close}
            >
              Close
            </Button>
          </div>
          {canEditUpdate ? (
            <div className='w-1/2 text-right'>
              <Button
                type='button'
                classes='text-xl'
                variant='transparent'
                disabled={isUpdatingGoal}
                onClick={deleteUpdate}
              >
                Delete
              </Button>

              <Button
                disabled={!isValid || isUpdatingGoal || !hasDataChanged()}
                variant='yellow'
                type='submit'
              >
                Save update
              </Button>
            </div>
          ) : null}
        </div>
      </form>
    </Modal>
  );
};

export default EditGoalUpdate;
