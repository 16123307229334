import { toast } from 'src/components';
import appUtils from '../components/appUtils';
import treeUtils from '../containers/Tree/utils';
import api from '../services/api';
import {
  treeActions,
  companyActions,
  dashActions,
  userActions
} from '../reducers/actions';
import sharedTreeUtils from '../common/sharedTreeUtils';
import sharedDateUtils from '../common/sharedDateUtils';
import { CONSTANTS, ACCESS } from '../constants';
import SHARED_CONSTANTS from '../common/sharedConstants';
import { companyUtils } from '../utils';
import questionsUtils from '../common/questionsUtils';

const thunks = {};

thunks.verifyIfUserIsLogged = () => (dispatch) => {
  const isLoggedIn = appUtils.isLoggedIn();
  if (!isLoggedIn) {
    thunks.logOut()(dispatch);
    return false;
  }
  return true;
};

thunks.logOut = () => (dispatch) => {
  dispatch({ type: 'RESET_APP' });
  if (window.auth2) {
    window.auth2.signOut();
  }
  appUtils.logUserOut();
  // api.post('/user/logout').then((response) => {});
  window.location.href = '/';
};

thunks.logUserIn = (user) => (dispatch) => {
  dispatch({
    type: 'LOG_USER_IN',
    user
  });
  appUtils.saveLoggedUser(user);
};

thunks.login = (data) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/user/login', data)
    .then((resp) => {
      if (!resp || resp.error) return reject(resp.error);
      if (resp.warning) return resolve(resp);
      const { userData, myTreeRow } = resp;
      if (myTreeRow) {
        dispatch(dashActions.setMyTreeRow(myTreeRow));
      }
      thunks.logUserIn(userData)(dispatch);
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

thunks.sendSignUpEmail = (data) => new Promise((resolve, reject) => {
  api
    .post('/user/pushSignUpToDesktop', data)
    .then((resp) => {
      if (!resp || resp.error) reject(resp.error);
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

thunks.getDashboard = (data) => (dispatch) => {
  if (
    process.env.DASHBOARD_CALL_CACHE !== 'false'
    && data
    && data.lastUpdated
  ) {
    const { lastUpdated } = data;
    const cachedUntil = lastUpdated + parseInt(process.env.DASHBOARD_CALL_CACHE, 10);
    if (sharedDateUtils.getUnixDateNow() < cachedUntil) {
      console.log(
        `Dashboard call is cached for ${
          cachedUntil - sharedDateUtils.getUnixDateNow()
        } more seconds.`
      );
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    }
  }
  return new Promise((resolve, reject) => {
    api
      .get('/emailing/dashboard')
      .then((resp) => {
        if (!resp || resp.error) {
          console.error('/emailing/dashboard response error', resp);
          reject();
          return;
        }

        const {
          emailing, tree, myTreeRow, userData, userSlackAuth
        } = resp;
        const { company, companyRow } = resp;

        const myTree = JSON.parse(tree);
        dispatch(treeActions.setTree(myTree));

        company.questions = questionsUtils.questionsDbToApp(
          JSON.parse(company.questions)
        );
        dispatch(
          companyActions.setCompany({
            ...companyRow,
            ...company
          })
        );
        dispatch(dashActions.setDashboard(resp));
        // const { myScore, teamScore, myTreeRow, usersToReview, highScore, lowScore } = resp;
        const preferredCommunication = userData && userData.preferredCommunication
          ? userData.preferredCommunication
          : 'email';
        dispatch(
          userActions.setUser({
            ...userData,
            userSlackAuth,
            preferredCommunication,
            newPreferredCommunication: preferredCommunication
          })
        );

        resolve(resp);
      })
      .catch((error) => {
        console.error('/emailing/dashboard error is..', error);
        reject(error);
      });
  });
};

thunks.sendPasswordRecoveryEmail = (email) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/user/password/recover', { email })
    .then((resp) => {
      if (resp.error) {
        toast.error(resp.message);
        reject(resp);
      }
      toast.show(resp.message);
      resolve(resp);
    })
    .catch((error) => {
      reject(error);
    });
});

thunks.saveNewPassword = (password, token) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/user/password/set', { password, token })
    .then((resp) => {
      if (!resp || !resp.success) {
        return reject(resp);
      }
      resolve(true);
    })
    .catch((error) => {
      reject(error);
    });
});

thunks.getUserWithInviteToken = (inviteToken) => {
  const data = { inviteToken };
  return (dispatch) => new Promise((resolve, reject) => {
    api.get(`/user/getUserWithInviteToken/${inviteToken}`).then((resp) => {
      if (resp.error) {
        return reject(resp);
      }
      resolve(resp);
    });
  });
};

thunks.confirmInviteAccount = (inviteToken, newPassword, userEmail, tz) => {
  const data = {
    inviteToken,
    newPassword,
    userEmail,
    tz
  };
  return (dispatch) => new Promise((resolve, reject) => {
    api.post('/user/confirmInviteAccount', data).then((resp) => {
      const { user } = resp;
      if (resp.error) {
        return reject(resp);
      }
      resolve(user);
    });
  });
};

thunks.sendMessageToAdmins = (data) => () => new Promise((resolve, reject) => {
  api.post('/subscriber/contact', data).then((resp) => {
    if (resp.error) {
      return reject(resp);
    }
    resolve(resp);
  });
});

thunks.subscribeEmail = (email) => () => new Promise((resolve, reject) => {
  api.get(`/subscriber/add/${email}`).then((resp) => {
    if (resp.error) {
      return reject(resp);
    }
    resolve(resp);
  });
});

thunks.loggedUserStatusChecked = (status) => (dispatch) => {
  dispatch({
    type: 'LOGGED_USER_STATUS_CHECKED',
    isCheckingLoggedUserStatus: status
  });
};

export default thunks;
