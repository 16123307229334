import React, { useState, useEffect } from 'react';
import { Select } from 'src/components';
import { useTree } from 'src/queries/tree';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { ACCESS } = COMMON_CONSTANTS;

const FilterByTeams = ({ id, manager, updateFn }) => {
  const {
    data: { myTreeRow, tree, managerList },
    isFetching: isFetchingTree
  } = useTree();

  const [teamOptions, setTeamOptions] = useState([]);
  const [teamNode, setTeamNode] = useState(null);

  useEffect(() => {
    let managerId = manager;
    if (
      !managerId
      && managerList.length
      && myTreeRow.access
      && !isFetchingTree
    ) {
      if (myTreeRow.children.length) {
        managerId = myTreeRow.id;
      } else if (myTreeRow.access === ACCESS.ADMIN) {
        managerId = tree.id;
      }
    }
    const teamTempNode = managerId
      ? commonTreeUtils.findNodeById(tree, managerId)
      : null;

    setTeamNode(teamTempNode);

    const mine = myTreeRow.access === ACCESS.ADMIN
      ? managerList
      : commonTreeUtils.getManagerNodeUnder(tree, myTreeRow.id);
    const options = mine.map((node) => ({
      id: node.id,
      label: node.name
    }));
    setTeamOptions(options);
  }, [
    managerList,
    myTreeRow.access,
    myTreeRow.children.length,
    myTreeRow.id,
    tree,
    tree.id,
    manager
  ]);

  const onChangeTeam = (option) => {
    updateFn({ teamId: undefined, managerId: option.id });
  };

  return (
    <Select
      id={id}
      variant='shadow'
      title={teamNode ? `Team ${teamNode.name}` : 'Select team'}
      options={teamOptions}
      onChange={onChangeTeam}
      classes='w-full'
    />
  );
};

export default FilterByTeams;
