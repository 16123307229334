import React, { useEffect } from 'react';
import { useTree } from 'src/queries/tree';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';
import { Select, XButton } from 'src/components';
import filterBarUtils from 'src/containers/UserProfile/FilterBar/utils';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import LoadingSelect from 'src/containers/UserProfile/FilterView/LoadingSelect';

const { REVIEW_FILTERS } = COMMON_CONSTANTS;

const getFilterTitle = (reviewerIds, reviewerGroup, tree) => {
  let filterTitle = 'Filter by Reviewers';
  if (reviewerIds || reviewerGroup) {
    if (reviewerIds && reviewerIds.length) {
      const filterNode = commonTreeUtils.findNodeById(tree, reviewerIds[0]);
      filterTitle = filterNode.name;
    }
    if (reviewerGroup) {
      filterTitle = reviewerGroup;
    }
  }
  return filterTitle;
};

const FilterByReviewers = ({
  id,
  isMyProfile,
  userId,
  reviewerIds,
  reviewerGroup,
  updateFn,
  customSelectClasses,
  setIsLoading = () => {}
}) => {
  const {
    data: treeData,
    isFetching: isFetchingTree,
    isError: isErrorTree,
    refetch: refetchTree
  } = useTree();

  const MyProfile = useMyProfile();
  const UserProfile = useUserProfile(userId);
  const {
    reviewers,
    isFetching: isFetchingReviewers,
    isError: isErrorReviewers
  } = isMyProfile ? MyProfile : UserProfile;

  useEffect(() => {
    if (!treeData && !isFetchingTree && refetchTree) refetchTree();
  }, [treeData, isFetchingTree, refetchTree]);

  const isFetching = isFetchingTree || isFetchingReviewers;
  const isError = isErrorReviewers || isErrorTree;
  const isReady = treeData && !isFetching && !isError;

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  if (!isReady) {
    return (
      <LoadingSelect
        variant='shadow'
        title='Filter by reviewers'
        customSelectClasses={customSelectClasses}
      />
    );
  }

  const { tree } = treeData;

  const userTree = commonTreeUtils.findNodeById(tree, userId);
  const filterOptions = filterBarUtils.getQuickFilterOptions(
    reviewers,
    tree,
    userTree,
    'By'
  );
  const filterTitle = getFilterTitle(reviewerIds, reviewerGroup, tree);

  const clearReviewersFilter = () => updateFn({ reviewerIds: null, reviewerGroup: null });

  const filterBy = (filterId) => {
    const REVIEW_FILTERS_VALUES = Object.keys(REVIEW_FILTERS).map(
      (key) => REVIEW_FILTERS[key]
    );
    if (REVIEW_FILTERS_VALUES.includes(filterId)) {
      return updateFn({ reviewerIds: null, reviewerGroup: filterId });
    }

    return updateFn({ reviewerIds: [filterId], reviewerGroup: null });
  };

  return (
    <Select
      id={id}
      variant='shadow'
      title={filterTitle}
      onChange={(option) => {
        filterBy(option.id);
      }}
      options={filterOptions}
      classes={customSelectClasses ?? 'w-60'}
      showXButton={(reviewerIds && reviewerIds.length) || reviewerGroup}
      onClickXButton={clearReviewersFilter}
    />
  );
};

export default FilterByReviewers;
