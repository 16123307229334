import React, { useEffect, useState } from 'react';
import { Flyout } from 'src/componentsTailwind/index';
import { Base, Button } from 'src/components';
import CircledIcon from 'src/components/CircledIcon/CircledIcon';
import ReviewOutlinedSVG from 'src/assets/svg/review-outlined.svg';
import FilterByDate from 'src/containers/Dash/FilterView/FilterByDate';
import FilterByTeams from 'src/containers/UserProfile/FilterView/FilterByTeams';
import FilterByReviewers from 'src/containers/UserProfile/FilterView/FilterByReviewers';
import FilterByReviewees from 'src/containers/UserProfile/FilterView/FilterByReviewees';
import FilterByRoles from 'src/containers/UserProfile/FilterView/FilterByRoles';
import FilterByCategories from 'src/containers/UserProfile/FilterView/FilterByCategories';
import FilterBySentiment from 'src/containers/UserProfile/FilterView/FilterBySentiment';

import commonDateUtils from 'common/commonDateUtils';

import COMMON_CONSTANTS from 'common/commonConstants';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

const FiltersFlyout = ({
  isOpen,
  setIsOpen,
  filters,
  updateDate,
  updateFn,
  showFields,
  isMyProfile,
  userId
}) => {
  const [localFilters, setLocalFilters] = useState({ ...filters });

  useEffect(() => {
    const unionFilters = { ...localFilters, ...filters };
    setLocalFilters(unionFilters);
  }, [filters]);

  const applyFilters = (updatedFilters) => {
    updateFn({
      reviewerIds: updatedFilters.reviewerIds,
      reviewerGroup: updatedFilters.reviewerGroup,
      teamId: updatedFilters.teamId,
      managerId: updatedFilters.managerId,
      revieweeIds: updatedFilters.revieweeIds,
      revieweeGroup: updatedFilters.revieweeGroup,
      sentiment: updatedFilters.sentiment,
      category: updatedFilters.category,
      roles: updatedFilters.roles
    });
    const { value, start, end } = updatedFilters.range;
    updateDate(value, start, end);
    setIsOpen(false);
  };

  const clearAll = () => {
    updateDate(
      DATE_RANGE_FILTERS.THREE_MONTHS.key,
      commonDateUtils.getFirstUnixOfDate(
        commonDateUtils.getDateFromDaysAgo(DATE_RANGE_FILTERS.THREE_MONTHS.days)
          .date
      ),
      undefined
    );

    const newFilters = {
      ...localFilters,
      reviewerIds: null,
      reviewerGroup: null,
      teamId: undefined,
      managerId: null,
      revieweeIds: null,
      revieweeGroup: null,
      sentiment: null,
      category: null,
      roles: null
    };

    setLocalFilters(newFilters);

    applyFilters(newFilters);
  };

  const updateLocalField = (value) => {
    setLocalFilters({ ...localFilters, ...value });
  };

  const closeFiltersOnClickAway = (e) => {
    const targetClassLists = e?.target?.classList;
    const targetParentClassLists = e?.target?.parentNode?.classList;

    const isClickingOnSelect = targetClassLists?.contains('select-filter')
      || targetParentClassLists?.contains('select-filter')
      || targetClassLists?.contains('select-option')
      || targetParentClassLists?.contains('select-option');

    if (!isClickingOnSelect) {
      const allSelects = document.querySelectorAll('.open-select-filter');
      allSelects.forEach((el) => {
        if (e?.target?.id !== el.id) {
          el.click();
        }
      });
    }
  };

  return (
    <Flyout isOpen={isOpen} showXButton={false} setIsOpen={setIsOpen}>
      <Base
        classes='h-full w-full items-center flex flex-col items-stretch justify-between'
        relative
        onMouseDown={closeFiltersOnClickAway}
      >
        <div className='mb-10'>
          <div className='flex items-center'>
            <CircledIcon svg={<ReviewOutlinedSVG className='text-purple' />} />
            <h2 className='text-xl font-bold my-auto mt-2 p-0'>Filters</h2>
          </div>
          <div className='border-b border-mid-gray my-3 transform -translate-x-[16px] w-[calc(100%+32px)]' />

          {showFields.includes('date') && (
            <div className='mt-5'>
              <p className='p-0 m-0 mb-2 text-md text-black font-bold'>Date</p>
              <FilterByDate
                id='filters-flyout-range'
                dateRangeSelected={localFilters.range}
                onSelectDateRange={(value, start, end) => updateLocalField({ range: { value, start, end } })}
                classes='w-full'
                customSelectClasses='w-full h-10'
              />
            </div>
          )}
          {showFields.includes('reviewers') && (
            <div className='mt-5'>
              <p className='p-0 m-0 mb-2 text-md text-black font-bold'>
                Reviewers
              </p>

              <FilterByReviewers
                id='filters-flyout-reviewers'
                isMyProfile={isMyProfile}
                userId={userId}
                updateFn={updateLocalField}
                reviewerIds={localFilters.reviewerIds}
                reviewerGroup={localFilters.reviewerGroup}
                customSelectClasses='w-full h-10'
              />
            </div>
          )}
          {showFields.includes('reviewees') && (
            <div className='mt-5'>
              <p className='p-0 m-0 mb-2 text-md text-black font-bold'>
                Reviewees
              </p>
              <FilterByReviewees
                id='filters-flyout-reviewees'
                isMyProfile={isMyProfile}
                userId={userId}
                updateFn={updateLocalField}
                revieweeIds={localFilters.revieweeIds}
                revieweeGroup={localFilters.revieweeGroup}
                customSelectClasses='w-full h-10'
              />
            </div>
          )}
          {showFields.includes('teams') && (
            <div className='mt-5'>
              <p className='p-0 m-0 mb-2 text-md text-black font-bold'>Teams</p>
              <FilterByTeams
                id='filters-flyout-teams'
                team={localFilters.teamId}
                manager={localFilters.managerId}
                updateFn={updateLocalField}
                customSelectClasses='w-full h-10'
              />
            </div>
          )}
          {showFields.includes('roles') && (
            <div className='mt-5'>
              <p className='p-0 m-0 mb-2 text-md text-black font-bold'>Roles</p>
              <FilterByRoles
                id='filters-flyout-roles'
                isMyProfile={isMyProfile}
                userId={userId}
                roles={localFilters.roles}
                updateFn={updateLocalField}
                customSelectClasses='w-full h-10'
              />
            </div>
          )}
          {(showFields.includes('categories')
            || showFields.includes('multi-categories')) && (
            <div className='mt-5'>
              <p className='p-0 m-0 mb-2 text-md text-black font-bold'>
                Categories
              </p>
              <FilterByCategories
                id='filters-flyout-categories'
                isMyProfile={isMyProfile}
                userId={userId}
                category={localFilters.category}
                multiSelect={showFields.includes('multi-categories')}
                updateFn={updateLocalField}
                customSelectClasses='w-full h-10'
              />
            </div>
          )}
          {showFields.includes('sentiment') && (
            <div className='mt-5'>
              <p className='p-0 m-0 mb-2 text-md text-black font-bold'>
                Sentiment
              </p>
              <FilterBySentiment
                id='filters-flyout-sentiment'
                sentiment={localFilters.sentiment}
                updateFn={updateLocalField}
                customSelectClasses='w-full h-10'
              />
            </div>
          )}
        </div>

        <div className='flex flex-row flex-wrap w-full max-w-280 gap-4 h-9 2xl:h-[39.5px] xl:ml-auto xl:mr-0 justify-center xl:justify-end'>
          <Button
            type='button'
            variant='empty-with-border'
            classes='!rounded-md !text-sm !font-normal py-1 xl:ml-auto xl:mr-0 h-full'
            onClick={clearAll}
          >
            Clear All
          </Button>
          <Button
            classes='bg-[#0F172A] text-white text-sm     py-1 rounded-md transition-colors duration-300 h-full px-6'
            variant='custom'
            onClick={() => applyFilters(localFilters)}
          >
            Apply
          </Button>
        </div>
      </Base>
    </Flyout>
  );
};

export default FiltersFlyout;
