import React, { useState, useEffect } from 'react';
import './Circle.scss';
import appUtils from 'src/components/appUtils';

const CircleV2 = ({
  size,
  rounded,
  imageUrl,
  customSize = null,
  customClasses = ''
}) => {
  let circleSize = 'w-32 h-32';
  if (customSize) circleSize = customSize;
  else {
    if (size === '2xl') circleSize = 'w-24 h-24';
    if (size === 'xl') circleSize = 'w-20 h-20';
    if (size === 'lg') circleSize = 'w-16 h-16';
    if (size === 'md') circleSize = 'w-14 h-14';
    if (size === 'sm') circleSize = 'w-12 h-12 min-w-12';
    if (size === 'xs') circleSize = 'w-8 h-8';
  }

  let roundedSize = 'rounded-full';
  if (rounded === 'xl') roundedSize = 'rounded-xl';
  if (rounded === 'lg') roundedSize = 'rounded-lg';
  if (rounded === 'md') roundedSize = 'rounded-md';
  if (rounded === 'sm') roundedSize = 'rounded-sm';

  const [currentImage, setCurrentImage] = useState(appUtils.getImageUrl());
  const [loading, setLoading] = useState(true);

  const fetchImage = (src) => {
    const loadingImage = new Image();
    loadingImage.src = appUtils.getImageUrl(src);
    loadingImage.onload = () => {
      setCurrentImage(loadingImage.src);
      setLoading(false);
    };
  };

  useEffect(() => {
    fetchImage(imageUrl);
  }, []);

  return (
    <img
      src={currentImage}
      className={`${circleSize} ${roundedSize}  ${customClasses || 'shadow-inner-md shadow-md object-cover m-auto'} ${loading ? '' : ''}`}
      alt='Profile circle'
    />
  );
};

export default CircleV2;
