import React, { useState } from 'react';
import { uniqBy, get } from 'lodash';
import { appUtils } from 'src/components';
import GeneralFilter from 'src/containers/UserProfile/FilterView/GeneralFilter';
import FilterByDate from 'src/containers/UserProfile/FilterView/FilterByDate';
import { useCompanyAsDataset } from 'src/queries/company';
import { useBuilderDataset } from 'src/queries/builder';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonDateUtils from 'common/commonDateUtils';

const { DEFAULT_ROLE } = COMMON_CONSTANTS;

const BuilderDashboardFilters = ({
  filters,
  setFilters,
  individualClasses = 'w-60'
}) => {
  const {
    cycleIds, roleIds, reviewerIds, revieweeIds
  } = filters;

  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = appUtils.getLoggedUserId();
  const { companyid } = loggedUser;

  const {
    data: builderDataset,
    isFetching: isFetchingBuilderDataset,
    isError: isErrorBuilderDataset
  } = useBuilderDataset();

  const {
    data: companyData,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompanyAsDataset(companyid, loggedUserId);

  const [roleSearchText, setRoleSearchText] = useState('');
  const [reviewerSearchText, setReviewerSearchtext] = useState('');
  const [revieweeSearchText, setRevieweeSearchtext] = useState('');

  const isFetching = isFetchingCompany || isFetchingBuilderDataset;
  const isError = isErrorCompany || isErrorBuilderDataset;
  const isReady = !isFetching && !isError;

  if (!isReady) return null;

  const roleIdsToShow = get(companyData, 'allowedFilters.roleIds', []);
  const revieweeIdsToShow = get(companyData, 'allowedFilters.revieweeIds', []);
  const companyAsIndustry = get(companyData, 'dataset[0]', {});

  const cycleOptions = [
    { id: 'null', label: 'No Cycle', checked: cycleIds.includes('null') },
    ...companyAsIndustry.cycles.map(({ id: cycleId, label }) => ({
      id: cycleId,
      label,
      checked: cycleIds.includes(cycleId)
    }))
  ];

  const industriesData = [companyAsIndustry, ...builderDataset];
  const roleOptions = uniqBy(
    industriesData
      .map((industry) => industry.roles.map(({ roleId, name }) => ({
        id: roleId,
        label: name,
        checked: roleIds.includes(roleId),
        disabled: roleId === DEFAULT_ROLE.id
      })))
      .flat()
      .filter(
        (role) => roleIdsToShow.includes(role.id)
          && role.label.toLowerCase().includes(roleSearchText.toLowerCase())
      )
      .sort((a, b) => (a.label > b.label ? 1 : -1))
      .concat({
        id: 'null',
        label: 'No Role',
        classes: '',
        checked: roleIds.includes('null'),
        disabled: false
      }),
    'id'
  );

  const accounts = get(companyAsIndustry, 'accounts', []);
  const reviewerOptions = [];
  const revieweeOptions = [];

  accounts.forEach((account) => {
    const { _id, name, isManaged } = account;

    if (
      (isManaged || revieweeIdsToShow.includes(_id))
      && name.toLowerCase().includes(revieweeSearchText.toLowerCase())
    ) {
      revieweeOptions.push({
        id: _id,
        label: name,
        checked: revieweeIds.includes(_id)
      });
    }

    if (
      (isManaged || loggedUserId === _id)
      && name.toLowerCase().includes(reviewerSearchText.toLowerCase())
    ) {
      reviewerOptions.push({
        id: _id,
        label: name,
        checked: reviewerIds.includes(_id)
      });
    }
  });

  const getTitle = (field) => {
    if (field === 'cycles') {
      const count = cycleIds.length;
      if (!count) return 'Filter by Cycles';
      if (count === 1) return '1 cycle selected';
      if (count > 1) return `${count} cycles selected`;
    }

    if (field === 'roles') {
      const count = roleIds.length;
      if (!count) return 'Filter by Roles';
      if (count === 1) return '1 role selected';
      if (count > 1) return `${count} roles selected`;
    }

    if (field === 'reviewers') {
      const count = reviewerIds.length;
      if (!count) return 'Filter by Reviewers';
      if (count === 1) return '1 reviewer selected';
      if (count > 1) return `${count} reviewers selected`;
    }

    if (field === 'reviewees') {
      const count = revieweeIds.length;
      if (!count) return 'Filter by Reviewees';
      if (count === 1) return '1 reviewee selected';
      if (count > 1) return `${count} reviewees selected`;
    }
  };

  return (
    <div className='flex justify-between gap-2 mt-1 mb-2'>
      <div className='flex flex-wrap full:flex-nowrap justify-start gap-2 w-full'>
        <GeneralFilter
          multiselect
          wrapperClasses={individualClasses}
          title={getTitle('roles')}
          options={roleOptions}
          onChange={(option) => {
            setFilters({
              ...filters,
              roleIds: option.checked
                ? roleIds.filter((id) => id !== option.id)
                : [...roleIds, option.id]
            });
          }}
          onClear={() => {
            setFilters({
              ...filters,
              roleIds: []
            });
          }}
          onSearch={(value) => setRoleSearchText(value)}
          onDropdownClose={() => setRoleSearchText('')}
        />
        <GeneralFilter
          multiselect
          wrapperClasses={individualClasses}
          title={getTitle('cycles')}
          options={cycleOptions}
          onChange={(option) => {
            const isNoCycle = option.id === 'null';
            const isNoCycleChecked = cycleIds.includes('null');

            let newCycleIds = [];

            if (isNoCycle) {
              if (isNoCycleChecked) {
                newCycleIds = [];
              } else {
                newCycleIds = [option.id];
              }
            } else if (isNoCycleChecked) {
              newCycleIds = [option.id];
            } else {
              newCycleIds = option.checked
                ? cycleIds.filter((id) => id !== option.id)
                : [...cycleIds, option.id];
            }

            setFilters({
              ...filters,
              cycleIds: newCycleIds
            });
          }}
          onClear={() => {
            setFilters({
              ...filters,
              cycleIds: []
            });
          }}
        />
        {/* <GeneralFilter
          multiselect
          wrapperClasses={individualClasses}
          title={getTitle('reviewers')}
          options={reviewerOptions}
          onChange={(option) => {
            setFilters({
              ...filters,
              reviewerIds: option.checked
                ? reviewerIds.filter((id) => id !== option.id)
                : [...reviewerIds, option.id]
            });
          }}
          onClear={() => {
            setFilters({
              ...filters,
              reviewerIds: []
            });
          }}
          onSearch={(value) => setReviewerSearchtext(value)}
          onDropdownClose={() => setReviewerSearchtext('')}
        /> */}
        <GeneralFilter
          multiselect
          wrapperClasses={individualClasses}
          title={getTitle('reviewees')}
          options={revieweeOptions}
          onChange={(option) => {
            setFilters({
              ...filters,
              revieweeIds: option.checked
                ? revieweeIds.filter((id) => id !== option.id)
                : [...revieweeIds, option.id]
            });
          }}
          onClear={() => {
            setFilters({
              ...filters,
              revieweeIds: []
            });
          }}
          onSearch={(value) => setRevieweeSearchtext(value)}
          onDropdownClose={() => setRevieweeSearchtext('')}
        />
        <FilterByDate
          classes={individualClasses}
          dateRangeSelected={filters.lastUpdated}
          onSelectDateRange={(value, start, end) => {
            const startValue = commonDateUtils.getFirstUnixOfDateFromUnix(
              start || commonDateUtils.dateToUnix('2020-01-01')
            );
            const endValue = commonDateUtils.getFirstUnixOfDateFromUnix(
              end || commonDateUtils.dateToUnix(new Date())
            );

            const isCustom = value === 'custom';
            setFilters({
              ...filters,
              lastUpdated: {
                value,
                start: startValue,
                ...(isCustom && { end: endValue })
              }
            });
          }}
        />
      </div>
    </div>
  );
};

export default BuilderDashboardFilters;
