import React, { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import qs from 'qs';
import {
  Button,
  Select,
  XButton,
  toast,
  Pagination,
  EditReviewModal
} from 'src/components';
import { useTree } from 'src/queries/tree';
import {
  useCompanyFeedback,
  likeFeedbackQuery,
  QUERY_KEYS
} from 'src/queries/feedback';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonDateUtils from 'common/commonDateUtils';
import FeedbackCard from 'src/pagesDashboard/Kudos/components/FeedbackCard';
import MostFeedback from 'src/pagesDashboard/Kudos/components/MostFeedback';
import commonTreeUtils from 'common/commonTreeUtils';
import FilterByDate from 'src/containers/UserProfile/FilterView/FilterByDate';
import appUtils from 'src/components/appUtils';
import { useNavigate } from 'react-router-dom';
import EditFeedbackModal from './components/EditFeedbackModal';

const PAGE_SIZE = 6; // use numbers that are divisible by 2

const { DATE_RANGE_FILTERS, ACCESS } = COMMON_CONSTANTS;

const Kudos = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const loggedUserId = appUtils.getLoggedUserId();

  const { mutateAsync: likeFeedback } = likeFeedbackQuery();

  const [currentStatePage, setCurrentStatePage] = useState(1);
  const [showEditReviewModal, setShowEditReviewModal] = useState(false);
  const [showEditFeedbackModal, setShowEditFeedbackModal] = useState(false);

  const [dateRangeSelected, setDateRangeSelected] = useState({
    value: DATE_RANGE_FILTERS.THREE_MONTHS.key,
    start: commonDateUtils.getDateFromDaysAgo(
      DATE_RANGE_FILTERS.THREE_MONTHS.days
    ).unix,
    end: undefined
  });

  const [selectedAuthor, setSelectedAuthor] = useState();
  const [selectedRecipient, setSelectedRecipient] = useState();

  const changePage = (page) => {
    setCurrentStatePage(page);
    window.scrollTo(0, 0);
  };

  const {
    data: { tree, myTreeRow, treeList },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isTreeReady = tree && tree.id && !isFetchingTree && !isErrorTree;

  const isAdmin = myTreeRow.access === ACCESS.ADMIN;

  const queryOptions = {
    size: PAGE_SIZE,
    sortBy: 'createdAt',
    page: currentStatePage
  };
  const query = {
    start: dateRangeSelected.start,
    end: dateRangeSelected.end,
    author: selectedAuthor,
    recipient: selectedRecipient,
    includeUnassignedUsers: true,
    options: queryOptions
  };

  const {
    data: companyFeedbackData = {},
    isFetching: isFetchingFeedbacks,
    isError: isErrorFeedbacks,
    status
  } = useCompanyFeedback(query, {
    enabled: isTreeReady
  });

  useEffect(() => {
    setCurrentStatePage(1);
  }, [
    dateRangeSelected.start,
    dateRangeSelected.end,
    selectedAuthor,
    selectedRecipient
  ]);

  const { feedbacks = [], pagination = { pages: 1, current: 1 } } = companyFeedbackData;

  const isFetching = isFetchingFeedbacks;
  const isError = isErrorFeedbacks;
  const isReady = feedbacks && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const setFeedbackDateRange = (value, start, end) => {
    setDateRangeSelected({ value, start, end });
  };

  const users = treeList
    ? treeList.map((row) => ({ id: row.id, label: row.name }))
    : [];

  const authorSelectTitle = selectedAuthor
    ? users.find((i) => i.id === selectedAuthor).label
    : 'Author';
  const recipientSelectTitle = selectedRecipient
    ? users.find((i) => i.id === selectedRecipient).label
    : 'Recipient';

  const openEditReviewModal = (data) => setShowEditReviewModal(data);

  const openEditFeedbackModal = (data) => setShowEditFeedbackModal(data);

  const onLikePost = async (id, type, reaction) => {
    const userId = loggedUserId;
    feedbacks.forEach((fb) => {
      if (fb.id === id) {
        if (!fb.likes.find((l) => l.userId === userId)) {
          fb.likes.push({ userId, reaction });
        } else {
          fb.likes = fb.likes.filter((i) => i.userId !== userId);
        }
      }
    });

    const res = await likeFeedback({ id, type, reaction });
    if (!res.success) {
      return toast.error("Couldn't like the feedback. Try again later!");
    }

    queryClient.invalidateQueries([
      QUERY_KEYS.FEEDBACK_FEED,
      ...qs.stringify(query, { skipNulls: true }).split('&')
    ]);
  };

  const mockArray = Array.from({ length: PAGE_SIZE });

  return (
    <>
      {showEditReviewModal ? (
        <EditReviewModal
          close={() => setShowEditReviewModal(false)}
          review={showEditReviewModal}
          userName={
            commonTreeUtils.findNodeById(tree, showEditReviewModal.reviewee)
              ?.name ?? ''
          }
        />
      ) : null}
      {showEditFeedbackModal ? (
        <EditFeedbackModal
          data={showEditFeedbackModal}
          close={() => setShowEditFeedbackModal(false)}
        />
      ) : null}

      <div className='right-standard mb-12 p-2 px-1 rounded-md bg-cloud-blue w-full'>
        <div className='flex items-center flex-wrap w-full mb-4 px-2 justify-between'>
          <div className='mb-2 2xl:mb-0'>
            <Button
              variant='purple'
              classes='w-48 py-2 rounded'
              onClick={() => navigate(`/dashboard/submit-feedback/feedback`)}
            >
              Give Feedback
            </Button>
            <button
              className='ml-2 w-36 py-2.5 px-2 text-md rounded bg-transparent border border-red text-red'
              onClick={() => window.open(
                'https://home.workstory.team/faq/what-is-the-shoutouts-feed',
                '_blank',
                'noopener'
              )}
            >
              What's this page?
            </button>
          </div>

          <div className='mt-4 xl:my-0 flex flex-wrap gap-1'>
            <div className='inline-block w-52 mr-2'>
              <div className='flex'>
                <Select
                  id='kudos-author-filter'
                  classes='w-52'
                  variant='shadow'
                  customVariantClasses=''
                  title={authorSelectTitle}
                  onChange={(option) => setSelectedAuthor(option.id)}
                  options={users}
                />
                {selectedAuthor ? (
                  <div className='inline-block align-top focus:outline-none'>
                    <XButton onClick={() => setSelectedAuthor(null)} />
                  </div>
                ) : null}
              </div>
            </div>

            <div className='inline-block w-52 mr-2'>
              <div className='flex'>
                <Select
                  id='kudos-recipient-filter'
                  classes='w-52'
                  variant='shadow'
                  customVariantClasses=''
                  title={recipientSelectTitle}
                  onChange={(option) => setSelectedRecipient(option.id)}
                  options={users}
                />
                {selectedRecipient ? (
                  <div className='inline-block align-top focus:outline-none'>
                    <XButton onClick={() => setSelectedRecipient(null)} />
                  </div>
                ) : null}
              </div>
            </div>

            <div className='inline-block w-52'>
              <FilterByDate
                id='kudos-range-filter'
                classes='w-48'
                customSelectClasses=''
                dateRangeSelected={dateRangeSelected}
                onSelectDateRange={setFeedbackDateRange}
              />
            </div>
          </div>
        </div>
        <div className='flex flex-row'>
          <div className='px-2 pr-5 w-full'>
            <div
              className={`min-h-68rem ${
                status === 'loading' && 'loading-blur'
              }`}
            >
              {isFetching && !feedbacks.length
                ? mockArray.map((_, index) => (
                  <div
                    key={index}
                    className='w-full bg-white px-4 pt-3 mb-3 pb-4 shadow rounded min-h-36'
                  />
                ))
                : null}
              {feedbacks && feedbacks.length
                ? feedbacks.map((fb) => (
                  <FeedbackCard
                    key={`card-${fb.type}-${fb.id}`}
                    id={fb.id}
                    date={fb.date}
                    text={fb.text}
                    likes={fb.likes ? fb.likes.length : '0'}
                    liked={
                        fb.likes
                          ? !!fb.likes.find((i) => i.userId === myTreeRow.id)
                          : false
                      }
                    onLike={onLikePost}
                    type={fb.type}
                    reviewer={fb.reviewer}
                    reviewee={fb.reviewee}
                    isAdmin={isAdmin}
                    editFn={() => {
                      if (fb.type === 'Feedback') {
                        return openEditFeedbackModal(fb);
                      }
                      return openEditReviewModal(fb);
                    }}
                  />
                ))
                : !isFetching && (
                <p className='text-center'>No feedback found!</p>
                )}
            </div>
            <div className={`${pagination.pages === 1 && 'hidden'}`}>
              <Pagination
                name='Feedback Feed'
                totalPages={pagination.pages}
                currentPage={pagination.current}
                selectPage={changePage}
                isLoading={isFetching}
              />
            </div>
          </div>

          <div className='flex flex-col min-w-20rem gap-5 ml-auto mr-2 mb-3'>
            <MostFeedback
              title='Most Feedback Given'
              dateRangeSelected={dateRangeSelected}
              groupBy='reviewer'
            />

            <MostFeedback
              title='Most Feedback Received'
              dateRangeSelected={dateRangeSelected}
              groupBy='reviewee'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Kudos;
