const getCategoryOptions = (myCategories, selectedCategories) => {
  const categoryOptions = [];
  if (!myCategories || !myCategories.length) {
    return [];
  }

  const filterCategories = Array.isArray(selectedCategories)
    ? selectedCategories
    : [selectedCategories];

  myCategories.forEach((cat) => {
    if (
      !categoryOptions.find(
        (c) => c && c.id && cat && cat.id && c.id.toString() === cat.id.toString()
      )
    ) {
      categoryOptions.push({
        id: cat.id,
        label: cat.label,
        checked: filterCategories && filterCategories.includes(cat.id)
      });
    }
  });

  const sorted = categoryOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

  return sorted;
};

export { getCategoryOptions };
