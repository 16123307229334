import React, { useState, useContext, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { Button, toast } from 'src/components';
import { OrganizationContext } from 'src/pagesDashboard/Organization/context/OrganizationProvider';
import { updateData } from 'src/pagesDashboard/Organization/context/actions';
import { uploadOrgQuery, useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import { exportCompanyQuery } from 'src/pagesDashboard/CompanyDash/queries/queries';
import { COMPANY_EXPORT_VIEWS } from 'common/commonCompanyUtils';
import UploadOrganizationErrors from 'src/containers/Organization/UploadOrganization/UploadOrganizationErrors/UploadOrganizationErrors';
import SpinnerSVG from 'src/assets/svg/spinner.svg';

const UploadOrganization = () => {
  const fileInputRef = useRef(null);
  const queryClient = useQueryClient();
  const { dispatch, resetContext } = useContext(OrganizationContext);
  const { mutateAsync: uploadFile, isLoading: updateFileLoading } = uploadOrgQuery();
  const { mutateAsync: exportData, isLoading: isExportDataLoading } = exportCompanyQuery(COMPANY_EXPORT_VIEWS.USERS);
  const [local, setLocal] = useState({ organizationFile: null });
  const [uploadErrors, setUploadErrors] = useState([]);
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch: refetchCompany
  } = useCompany();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree,
    refetch: refetchTree
  } = useTree();

  const isFetching = isFetchingCompany || isFetchingTree;
  const isError = isErrorCompany || isErrorTree;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const isMutating = updateFileLoading || isExportDataLoading;

  const onClickDownload = async () => {
    await exportData('Users');
    toast.show('Download successful');
  };

  const upload = async () => {
    const formData = new FormData();
    formData.append('excel', local.organizationFile);
    toast.show('Uploading organization');
    setUploadErrors([]);
    const result = await uploadFile(formData);
    if (result.failed.length) {
      setUploadErrors(result.failed);
    } else {
      toast.show('Done!');
      queryClient.invalidateQueries();
      resetContext();
    }
    setLocal({ organizationFile: null });
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const selectFile = (event) => {
    setLocal({ organizationFile: event.target.files[0] });
  };
  const close = () => {
    dispatch(updateData({ showOrgUpload: false }));
  };

  return (
    <div>
      <h5>Upload Organization</h5>
      <p>
        Use list of users which will attach to the current existing org
        structure. Accepts csv and xlsx files.
      </p>
      <input
        ref={fileInputRef}
        onChange={selectFile}
        type='file'
        disabled={isMutating}
      />
      <div>
        <div className='flex gap-3 mt-2'>
          <Button
            variant='yellow'
            onClick={upload}
            disabled={!local.organizationFile || isMutating}
          >
            Upload
          </Button>
          <Button
            variant='yellow'
            onClick={onClickDownload}
            disabled={isMutating}
          >
            Download
          </Button>
          <Button variant='yellow' onClick={close}>
            Close
          </Button>
        </div>
      </div>
      {
        isMutating ? (
          <div className='flex items-center justify-center h-full w-full mt-14'>
            <SpinnerSVG className='w-8 h-8' />
          </div>
        ) : null
      }
      {
        uploadErrors.length ? (
          <UploadOrganizationErrors
            errors={uploadErrors}
          />
        ) : null
      }
    </div>
  );
};

export default UploadOrganization;
