import React, { useEffect, useRef } from 'react';
import { Base } from 'src/components/';
import { usePulseQuestions } from 'src/queries/pulse';
import PULSE_CONSTANTS from 'common/pulseConstants';
import commonDateUtils from 'common/commonDateUtils';
import STYLE from 'src/constants/style';

const { PULSE_QUESTION_VISIBILITY } = PULSE_CONSTANTS;

const PendingPulse = ({ scroll, status }) => {
  // const { data: list, isFetching: isFetchingPulseReviews } = usePulseReviews();
  const sectionRef = useRef(false);
  const scrollToPendingReviews = () => {
    if (sectionRef.current) {
      const { x, y } = sectionRef.current.getBoundingClientRect();
      scrollTo(x, window.scrollY + y - 100);
    }
  };
  const {
    data: pulseQuestions,
    isFetching: isFetchingPulseQuestions,
    isError: isErrorPulseQuestions
  } = usePulseQuestions({
    filters: {
      visibility: PULSE_QUESTION_VISIBILITY.DEFAULT,
      status
    }
  });

  const isFetching = isFetchingPulseQuestions;
  const isError = isErrorPulseQuestions;
  const isReady = pulseQuestions && !isFetching && !isError;

  useEffect(() => {
    if (scroll) {
      scrollToPendingReviews();
    }
  }, [scroll]);

  const activeQuestions = isReady
    ? pulseQuestions.filter(
      (pulseQuestion) => pulseQuestion.status === PULSE_CONSTANTS.STATUS.ACTIVE
    )
    : [];

  return (
    <Base
      classes={STYLE.CONTAINER_WHITE}
      loading={isFetchingPulseQuestions}
      ref={sectionRef}
    >
      <div className='block'>
        <h5 className='inline-block text-xl font-bold w-1/2'>
          Pending Pulse Questions
        </h5>
      </div>
      <div className='mt-6'>
        <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm cursor-default'>
          <p className='mb-0 pl-1 w-2/12 inline-block font-bold'>
            Scheduled Date
          </p>
          <p className='mb-0 pl-1 w-2/12 inline-block font-bold'>Repeats</p>
          <p className='mb-0 w-6/12 inline-block font-bold'>Question</p>
          <p className='mb-0 w-2/12 inline-block font-bold'>Category</p>
        </div>

        {activeQuestions && activeQuestions.length === 0 ? (
          <div>
            <p className='text-center font-bold mt-6'>
              You have no pending reviews
            </p>
          </div>
        ) : null}

        {activeQuestions
          && activeQuestions
            .sort((a, b) => (a.created > b.created ? -1 : 1))
            .map((pulseQuestion, index) => {
              // const categories = pulseQuestion.category.join('');
              const category = PULSE_CONSTANTS.CATEGORIES.find(
                (cat) => cat.id === parseInt(pulseQuestion.category, 10)
              );
              let repeats = 'Never';
              if (pulseQuestion.repeats !== PULSE_CONSTANTS.FREQUENCY.NEVER) {
                repeats = commonDateUtils.unixToMonthDayYearFormat(
                  pulseQuestion.timestamp
                );
              }
              return (
                <div
                  key={index}
                  className='mb-2 py-1 px-1 text-black rounded-sm cursor-default'
                >
                  <p className='mb-0 pl-1 w-2/12 inline-block'>
                    {commonDateUtils.unixToMonthDayYearFormat(
                      pulseQuestion.timestamp
                    )}
                  </p>
                  <p className='mb-0 pl-1 w-2/12 inline-block'>
                    {pulseQuestion.repeats}
                  </p>
                  <p className='mb-0 w-6/12 inline-block pr-5'>
                    {pulseQuestion.text}
                  </p>
                  <p className='mb-0 w-2/12 inline-block'>
                    {' '}
                    {category ? category.label : '-'}
                    {' '}
                  </p>
                </div>
              );
            })}
      </div>
    </Base>
  );
};

export default PendingPulse;
