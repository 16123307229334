import React, { useState } from 'react';
import { useIsFetching } from 'react-query';
import { get } from 'lodash';
import STYLE from 'src/constants/style';
import { Base, Button } from 'src/components/';
import { useCompany } from 'src/queries/company';
import PulseOverview from 'src/pagesDashboard/Pulse/containers/PulseOverview/PulseOverview';
import PendingPulse from 'src/pagesDashboard/Pulse/containers/PendingPulse/PendingPulse';
import PendingPulseReviews from 'src/pagesDashboard/Pulse/containers/PendingPulseReviews/PendingPulseReviews';
import FilterByStatus from 'src/containers/UserProfile/FilterView/FilterByStatus';
import { QUERY_KEYS as PULSE_QUERY_KEYS } from 'src/queries/pulse';
import appUtils from 'src/components/appUtils';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import SuperuserPulse from 'src/pagesDashboard/Pulse/containers/SuperuserPulse/SuperuserPulse';
import PULSE_CONSTANTS from 'common/pulseConstants';
import { useNavigate, useSearchParams } from 'react-router-dom';

const { STATUS } = PULSE_CONSTANTS;

const Pulse = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const scrollToPendingPulse = searchParams.get('scrollToPendingPulse') === 'true';
  const loggedUserId = appUtils.getLoggedUserId();

  const isFetchingPulseQuestions = useIsFetching({
    queryKey: [PULSE_QUERY_KEYS.PULSE_QUESTIONS]
  });

  const AVAILABLE_STATUS = Object.keys(STATUS).filter(
    (key) => STATUS[key] !== 'Archived'
  );
  const [statusFilter, setStatusFilter] = useState(
    AVAILABLE_STATUS.map((key) => STATUS[key])
  );

  const { data: company, isFetching: isFetchingCompany } = useCompany();

  const isFetching = isFetchingPulseQuestions || isFetchingCompany;

  const arePulseEventsEnabled = get(company, 'events.pulse', false) === 'enabled';

  const statusOptions = AVAILABLE_STATUS.map((key) => ({
    id: STATUS[key],
    label: STATUS[key],
    checked: statusFilter && statusFilter.includes(STATUS[key])
  }));

  const updateStatusFilter = ({ status: selectedStatus }) => {
    setStatusFilter(selectedStatus);
  };

  return (
    <>
      <HeaderV2 overtitle='Tracking' title='Pulse' />
      <Base className={STYLE.BASE}>
        <FilterByStatus
          id='pulse-status-filter'
          isMyProfile={false}
          companyid={company.id}
          userId={loggedUserId}
          status={statusFilter}
          options={statusOptions}
          updateFn={updateStatusFilter}
          customSelectClasses='mb-2'
        />
        <div className='mt-0'>
          <Base classes={STYLE.CONTAINER_WHITE} loading={isFetching}>
            <div className='flex justify-between'>
              <h5 className='text-xl font-bold'>Pending Questions</h5>
              <div className='tooltip'>
                {arePulseEventsEnabled ? null : (
                  <span className='tooltip-text bg-black text-white -ml-16 -mt-8 max-w-19rem'>
                    Pulse is not enabled for your organization
                  </span>
                )}

                <Button
                  variant='yellow'
                  classes='right-0'
                  onClick={() => navigate('/dashboard/pulse/new')}
                  disabled={!arePulseEventsEnabled}
                >
                  Add Question
                </Button>
              </div>
            </div>

            <PulseOverview status={statusFilter} />
          </Base>

          <PendingPulse scroll={scrollToPendingPulse} status={statusFilter} />

          {appUtils.isSuperUser() ? (
            <div>
              <SuperuserPulse status={statusFilter} />
              <PendingPulseReviews />
            </div>
          ) : null}
        </div>
      </Base>
    </>
  );
};

export default Pulse;
