import React, { cloneElement } from 'react';
import DOMPurify from 'dompurify';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild
} from '@headlessui/react';
import InfoCircleAlterSVG from 'src/assets/svg/infoCircle-alter.svg';
import { Button } from 'src/components';
import CrossSVG from 'src/assets/cross.svg';

const IconSection = ({ type, customIcon = null }) => {
  if (customIcon) {
    return (
      <div className='mx-auto mb-3 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 *:m-auto *:aria-hidden:'>
        <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
          {cloneElement(customIcon)}
        </div>
      </div>
    );
  }

  switch (type) {
    case 'error':
      return (
        <div className='mx-auto mb-3 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 *:m-auto *:aria-hidden:'>
          <div className='rounded-full bg-red-100'>
            <InfoCircleAlterSVG className='h-10 w-10 text-red-400 m-auto' />
          </div>
        </div>
      );
    case 'success':
      return (
        <div className='mx-auto mb-3 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 *:m-auto *:aria-hidden:'>
          <div className='rounded-full bg-green-100'>
            <InfoCircleAlterSVG className='h-10 w-10 text-green-400 m-auto' />
          </div>
        </div>
      );
    default:
      return (
        <div className='mx-auto mb-3 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 *:m-auto *:aria-hidden:'>
          <div className='rounded-full bg-green-100'>
            <InfoCircleAlterSVG className='h-10 w-10 text-green-400 m-auto' />
          </div>
        </div>
      );
  }
};

const actionButtonColorClasses = (type) => {
  switch (type) {
    case 'error':
      return 'bg-red hover:bg-hover-red';
    case 'success':
      return 'bg-purple hover:bg-hover-purple';
    default:
      return 'bg-purple hover:bg-hover-purple';
  }
};

const TailwindInfoModal = ({
  title,
  content,
  isOpen,
  close,
  type = 'success',
  customIcon = null
}) => (
  <Transition show={isOpen}>
    <Dialog className='fixed top-0 left-0 w-full h-full z-10' onClose={close}>
      <TransitionChild
        enter='ease-out duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='ease-in duration-200'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-9' />
      </TransitionChild>

      <div className='inset-0 z-10 w-full overflow-y-auto flex items-center justify-center'>
        <div className='inset-0 max-w-[24.5rem] z-10 overflow-y-auto flex min-h-screen'>
          <div className='flex min-h-full items-end justify-center text-center sm:items-center sm:p-0'>
            <TransitionChild
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <DialogPanel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all p-6'>
                <div className='sm:flex justify-between pb-4'>
                  <IconSection type={type} customIcon={customIcon} />
                  <button className='w-6 h-6' onClick={close}>
                    <CrossSVG className='w-6 h-6 cursor-pointer' />
                  </button>
                </div>
                <div className='sm:pb-3'>
                  <div className='flex justify-between mb-4'>
                    <div className='mt-3 sm:mt-0'>
                      <div className='text-center sm:text-left flex justify-between'>
                        <DialogTitle
                          as='h3'
                          className='text-base font-semibold leading-6 text-gray-900 mb-0 pb-0'
                        >
                          {title}
                        </DialogTitle>
                      </div>
                      <div className='mt-2'>
                        <p
                          className='text-sm text-gray-500 mb-0 pb-0 leading-5 info-content'
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(content)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bg-gray-50'>
                  <Button
                    classes={`font-bold text-white focus:outline-none w-full text-center py-2.5 rounded-lg ${actionButtonColorClasses(type)}`}
                    variant='custom'
                    onClick={close}
                  >
                    Close
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </Transition>
);

export default TailwindInfoModal;
