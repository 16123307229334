import React, { useState } from 'react';
import { get } from 'lodash';
import { Controller } from 'react-hook-form';
import { Select, appUtils } from 'src/components';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import commonTreeUtils from 'common/commonTreeUtils';
import commonQuestions from 'common/commonQuestions';
import { useAccount, useAccounts } from 'src/queries/account';

const { ACCESS, USER_STATE, GET_ACCOUNT_SPEC_OPS } = COMMON_CONSTANTS;

const Assignee = ({
  control, mode, setValue, watch
}) => {
  const {
    data: loggedAccount,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingCompany || isFetchingTree || isFetchingAccount;
  const isError = isErrorCompany || isErrorTree || isErrorAccount;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  const loggedUserId = appUtils.getLoggedUserId();
  const isAdmin = loggedAccount.access === ACCESS.ADMIN;
  const { manageAccounts } = loggedAccount;
  const allowedIds = [loggedUserId, ...manageAccounts];

  const [searchText, setSearchText] = useState('');

  const { data: users } = useAccounts(
    {
      ...(!isAdmin && {
        ids: allowedIds,
        specs: [
          {
            op: GET_ACCOUNT_SPEC_OPS.BELOW_ACCOUNTID,
            accountId: loggedUserId
          }
        ]
      }),
      status: [USER_STATE.ACTIVE, USER_STATE.PASSIVE]
    },
    {
      page: {
        size: 25
      },
      search: {
        enabled: true,
        field: 'name',
        value: searchText
      }
    }
  );

  if (!isReady) {
    return null;
  }

  const assigneeOptions = [];
  const categoryOptions = [{ value: null, label: 'No category' }];
  // trico only
  if (company.id === '18fb1b5d-ec89-4ab5-a6bb-b6f519ca8a3c') {
    categoryOptions.push({
      value: 't_1',
      label: 'Building Meaningful Projects'
    });
    categoryOptions.push({
      value: 't_2',
      label: 'Investing where we live, work, and play'
    });
  }

  if (mode !== 'new') {
    assigneeOptions.push({ value: null, label: 'Unassigned' });
  }
  assigneeOptions.push(
    ...users.map((user) => ({
      value: user.id || user._id,
      label: user.name
    }))
  );

  const assignee = watch('assignee');
  if (assignee.value) {
    const { roles: assigneeRoleIds } = commonTreeUtils.findNodeById(
      tree,
      assignee.value
    );
    const ROLES_LIST = commonQuestions.convertCompanyRolesToList(
      company.questions.ROLES
    );
    const assigneeRoles = ROLES_LIST.filter((role) => assigneeRoleIds.includes(role.id));
    const assigneeCategoryIds = [
      ...new Set(assigneeRoles.map((role) => role.categories).flat())
    ];
    Object.keys(company.questions.CATEGORIES).map((key) => {
      const { id, label } = company.questions.CATEGORIES[key];
      if (assigneeCategoryIds.includes(id)) {
        categoryOptions.push({
          value: id,
          label
        });
      }
    });
  }

  return (
    <>
      <div className='flex justify-between'>
        <p className='mb-0 text-gray-500 text-base font-bold w-2/6'>ASSIGNEE</p>
        <div className='pl-8 pr-6 h-16 w-4/6'>
          <Controller
            name='assignee'
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              const title = get(field.value, 'label', 'Set assignee');
              return (
                <Select
                  id='assignee-assigne-filter'
                  placeholder='Set assignee'
                  classes='w-full'
                  options={assigneeOptions}
                  title={title}
                  showSearch
                  onSearch={(value) => {
                    setSearchText(value);
                  }}
                  onDropdownClose={() => {
                    setSearchText('');
                  }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('category', {
                      value: null,
                      label: 'No category'
                    });
                  }}
                  drop='up'
                />
              );
            }}
          />
        </div>
      </div>
      {assignee.value ? (
        <div className='flex justify-between'>
          <p className='mb-0 text-gray-500 text-base font-bold w-2/6'>
            PERFORMANCE CATEGORY
          </p>
          <div className='pl-8 pr-6 h-16 w-4/6'>
            <Controller
              name='category'
              control={control}
              render={({ field }) => {
                const title = get(field.value, 'label', 'Set category');
                return (
                  <Select
                    id='assignee-category-filter'
                    placeholder='Set category'
                    classes='w-full'
                    options={categoryOptions}
                    title={title}
                    drop='up'
                    {...field}
                  />
                );
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Assignee;
