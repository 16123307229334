import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { toast } from 'src/components';
import StarsSvg from 'src/assets/svg/stars.svg';
import SpinnerSVG from 'src/assets/spinner.svg';
import RightArrowSVG from 'src/assets/right-arrow.svg';
import { useReviewSuggestions } from 'src/queries/Wizard/wizard';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonReviewUtils from 'common/commonReviewUtils';
import './ReviewAiSuggestions.scss';

const { PROMPT_TYPES } = COMMON_CONSTANTS;

const ReviewAiSuggestions = ({
  comments,
  userId,
  revieweeName,
  question,
  companyid,
  reviewerId,
  resetSuggestions,
  setResetSuggestions = () => {}
}) => {
  const plainTextComment = commonReviewUtils.getPlainTextFromHTML(comments);
  const [suggestionHTML, setSuggestionHTML] = useState(null);

  const {
    generateSuggestions,
    data: generateSuggestionsData,
    isLoading: isCreateSuggestionsLoading
  } = useReviewSuggestions();

  useEffect(() => {
    if (generateSuggestionsData.length) {
      const [suggestionData] = generateSuggestionsData;
      setSuggestionHTML({
        __html: DOMPurify.sanitize(suggestionData)
      });
    }
  }, [generateSuggestionsData]);

  useEffect(() => {
    if (resetSuggestions) {
      setSuggestionHTML(null);
      setResetSuggestions(false);
    }
  }, [resetSuggestions]);

  const getSuggestions = async () => {
    const result = await generateSuggestions({
      filters: {
        text: comments,
        type: PROMPT_TYPES.FEEDBACK_SUGGESTION,
        question,
        userId,
        reviewerId,
        companyid
      }
    });
    if (!result.success) {
      toast.error(result.message);
    }
  };

  const getSuggestionsTriggered = suggestionHTML || isCreateSuggestionsLoading;
  const showCommentSizeWarning = !getSuggestionsTriggered && plainTextComment.length < 15;
  const shownGetSuggestionsButton = !getSuggestionsTriggered && plainTextComment.length >= 15;

  return (
    <div className='ai-review-suggestions'>
      <div className='mb-8 flex flex-col md:flex-row'>
        <div className='text-gray-500 mb-2 md:w-2/5 font-bold text-xl'>
          AI ASSIST
        </div>
        <div className='md:w-3/5'>
          {!getSuggestionsTriggered ? (
            <div className='bg-sky-blue p-4 rounded-md mb-2'>
              <div className='flex mb-2 text-gray-450'>
                <StarsSvg className='mr-2' />
                WorkStory AI Assistant
              </div>
              <div>
                👋 Hey! We can help you improve your feedback. Provide a comment
                and we’ll let you know how you can make it even better!
              </div>
            </div>
          ) : null}
          {showCommentSizeWarning ? (
            <div className='bg-sky-blue p-2 px-3 rounded-3xl w-fit'>
              Please add a little more feedback before we can make a suggestion.
            </div>
          ) : null}
          {shownGetSuggestionsButton ? (
            <button
              type='button'
              className='bg-sky-blue p-2 rounded-3xl w-48 flex'
              onClick={getSuggestions}
            >
              <RightArrowSVG className='mr-1 text-[#323232]' />
              Suggest Improvements
            </button>
          ) : null}
          {isCreateSuggestionsLoading ? (
            <button
              type='button'
              className='bg-sky-blue py-2 px-3 rounded-3xl flex'
              disabled
            >
              <SpinnerSVG className='w-6 h-6' />
              Processing
            </button>
          ) : null}
          {suggestionHTML ? (
            <div className='bg-sky-blue p-4 rounded-md'>
              <div className='flex mb-2 text-gray-450'>
                <StarsSvg className='mr-2' />
                WorkStory AI Assistant
              </div>
              <p className='mb-1'>
                {`Suggestions for improving your feedback for ${revieweeName}:`}
              </p>
              <div dangerouslySetInnerHTML={suggestionHTML} className='pl-8' />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ReviewAiSuggestions;
