import React, { useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { isFinite, get } from 'lodash';
import { appUtils, Base } from 'src/components/index';
import STYLE from 'src/constants/style';
import { useCompany } from 'src/queries/company';
import HashtagSVG from 'src/assets/svg/hashtag.svg';
import CircledIcon from 'src/components/CircledIcon/CircledIcon';
import {
  COMPARE,
  useAccount,
  useAccountScoreComparison
} from 'src/queries/account';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import commonDateUtils from 'common/commonDateUtils';
import { BADGE_COLOR_CLASSES } from 'src/componentsTailwind/tailwindConstants';
import commonChartUtils from 'common/chart/chart';
import commonPermissions from 'common/commonPermissions';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useTree } from 'src/queries/tree';

const { VIEW_SCORES_PERMISSIONS } = COMMON_CONSTANTS;

const OverallScores = ({
  userId = appUtils.getLoggedUserId(),
  viewerId = appUtils.getLoggedUserId()
}) => {
  const loggedUserId = appUtils.getLoggedUserId();
  const isMyProfile = userId === loggedUserId;
  const { context } = useContext(
    isMyProfile ? DashContext : UserProfileContext
  );
  const { range } = context;

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: viewerAccount,
    isFetching: isFetchingViewerAccount,
    isError: isErrorViewerAccount
  } = useAccount(viewerId);

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: comparisonScore,
    isFetching: isFetchingComparisonScore,
    isRefetching: isRefetchingComparisonScore,
    isError: isErrorComparisonScore
  } = useAccountScoreComparison(
    {
      id: userId,
      compare: COMPARE.COMPANY,
      ...(range.start && {
        start: range.start,
        end: range.end
      })
    },
    {
      sampleBy: commonChartUtils.getChartGroupBy(range.start, range.end),
      bundleCategories: true,
      role: 'reviewee',
      viewerId
    },
    {
      enabled: Boolean(userId),
      keepPreviousData: true
    }
  );

  const isInitialFetchingComparisonScore = isFetchingComparisonScore && !isRefetchingComparisonScore;
  const isFetching = isFetchingCompany
    || isInitialFetchingComparisonScore
    || isFetchingViewerAccount
    || isFetchingTree;
  const isError = isErrorCompany
    || isErrorComparisonScore
    || isErrorViewerAccount
    || isErrorTree;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const {
    avgScoreReceived,
    avgScoreReceivedDiff,
    avgScoreLabel,
    avgSentimentLabel,
    reviewedReceivedCount,
    commentedReceivedCount,
    feedbackReceivedCount,
    samples
  } = comparisonScore;

  const graphOptions = {
    plugins: {
      legend: false,
      tooltip: {
        yAlign: 'bottom',
        boxPadding: 4
      }
    },
    scales: {
      y: {
        display: false,
        max: 110,
        min: -10
      },
      x: {
        display: false
      }
    }
  };

  const samplesToPlot = samples.map((sample, index) => {
    if (!isFinite(sample.avgScoreReceived)) {
      const prevSamples = samples.slice(0, index).reverse();
      const latestValidSample = prevSamples.find((prevSample) => isFinite(prevSample.avgScoreReceived));
      return {
        ...sample,
        avgScoreReceived: get(latestValidSample, 'avgScoreReceived', 0)
      };
    }
    return sample;
  });

  const labels = [
    ...samplesToPlot.map(({ start }) => commonDateUtils.unixToTimeAgo(start)),
    'Today'
  ];
  const data = [
    ...samplesToPlot.map((sample) => sample.avgScoreReceived),
    avgScoreReceived
  ];

  const graphData = {
    labels,
    datasets: [
      {
        data,
        borderColor: '#5B7FFF',
        tension: 0.75,
        cubicInterpolationMode: 'monotone',
        pointRadius: 0
      }
    ]
  };

  const hasNumericData = isFinite(avgScoreReceived);
  const hasGraphData = hasNumericData && data.some(isFinite);

  const canManageUser = commonPermissions.canManageUsers(viewerAccount, tree, [
    userId
  ]);
  const companyViewScorePermissions = get(
    company,
    'settings.viewScores',
    VIEW_SCORES_PERMISSIONS.MANAGERS
  );
  const isViewingAsUser = viewerId === userId;
  const canViewScores = isViewingAsUser
    ? companyViewScorePermissions === VIEW_SCORES_PERMISSIONS.ALL
    : canManageUser;
  const canViewComparisonBadge = canViewScores && canManageUser;

  let avgScoreDiffLabel = 'No comparison data';
  let avgScoreDiffBadgeClasses = BADGE_COLOR_CLASSES.GRAY;

  if (!hasNumericData) {
    avgScoreDiffLabel = 'No data';
  } else if (hasGraphData) {
    if (avgScoreReceivedDiff > 0) {
      avgScoreDiffLabel = `+${avgScoreReceivedDiff}% vs others`;
      avgScoreDiffBadgeClasses = BADGE_COLOR_CLASSES.GREEN;
    } else if (avgScoreReceivedDiff < 0) {
      avgScoreDiffLabel = `${avgScoreReceivedDiff}% vs others`;
      avgScoreDiffBadgeClasses = BADGE_COLOR_CLASSES.RED;
    }
  }

  const avgScoreReceivedlabel = canViewScores && hasNumericData
    ? `${avgScoreReceived}, ${avgScoreLabel}`
    : avgScoreLabel;

  let commentedAndFeedbackReceivedCountCount;
  if (isFinite(commentedReceivedCount) && isFinite(feedbackReceivedCount)) {
    commentedAndFeedbackReceivedCountCount = commentedReceivedCount + feedbackReceivedCount;
  } else if (isFinite(commentedReceivedCount)) {
    commentedAndFeedbackReceivedCountCount = commentedReceivedCount;
  } else if (isFinite(feedbackReceivedCount)) {
    commentedAndFeedbackReceivedCountCount = feedbackReceivedCount;
  }

  const isLoading = isFetchingCompany || isFetchingComparisonScore;
  return (
    <Base
      classes={`${STYLE.CONTAINER_WHITE} !p-4 !mt-0`}
      relative
      loading={isLoading}
    >
      <CircledIcon svg={<HashtagSVG className='text-purple' />} />
      <p className='text-xl text-black font-bold mb-0 mr-8'>
        Performance Metrics
      </p>
      <p className='text-md font-normal my-2 leading-5 text-bluish-gray'>
        Summary statistics based on reviews and feedback from the team.
      </p>
      <div className='border-b border-mid-gray my-3 transform -translate-x-[16px] w-[calc(100%+32px)]' />
      <p className='text-[#101828] font-[700] text-[24px] leading-[28px] m-0 py-[5px]'>
        {avgScoreReceivedlabel}
      </p>
      <div className='w-full flex justify-between items-center'>
        <p className='text-[#727272] font-[400] text-[14px] leading-[17px] m-0'>
          Overall Performance
        </p>
        {canViewComparisonBadge ? (
          <span
            className={`rounded-md px-2 py-[2px] my-[4px] ${avgScoreDiffBadgeClasses} max-w-fit`}
          >
            <div className='m-0 leading-4 text-xs font-medium'>
              {avgScoreDiffLabel}
            </div>
          </span>
        ) : null}
      </div>
      {canViewScores ? <Line data={graphData} options={graphOptions} /> : null}
      <div className='border-b border-dashed border-mid-gray my-3 transform -translate-x-[16px] w-[calc(100%+32px)]' />
      <div className='w-full'>
        <p className='text-[#101828] font-[700] text-[24px] leading-[28px] m-0 py-[5px]'>
          {isFinite(reviewedReceivedCount)
            ? reviewedReceivedCount.toLocaleString()
            : '-'}
        </p>
        <p className='text-[#727272] font-[400] text-[14px] leading-[17px] m-0'>
          Reviews Received
        </p>
      </div>
      <div className='border-b border-dashed border-mid-gray my-3 transform -translate-x-[16px] w-[calc(100%+32px)]' />
      <div className='w-full'>
        <p className='text-[#101828] font-[700] text-[24px] leading-[28px] m-0 py-[5px]'>
          {isFinite(commentedAndFeedbackReceivedCountCount)
            ? commentedAndFeedbackReceivedCountCount.toLocaleString()
            : '-'}
        </p>
        <p className='text-[#727272] font-[400] text-[14px] leading-[17px] m-0'>
          Total Feedback Comments
        </p>
      </div>
      <div className='border-b border-dashed border-mid-gray my-3 transform -translate-x-[16px] w-[calc(100%+32px)]' />
      <div className='w-full'>
        <p className='text-[#101828] font-[700] text-[24px] leading-[28px] m-0 py-[5px]'>
          {avgSentimentLabel}
        </p>
        <p className='text-[#727272] font-[400] text-[14px] leading-[17px] m-0'>
          Feedback Sentiment
        </p>
      </div>
    </Base>
  );
};

export default OverallScores;
