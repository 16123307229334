import React from 'react';
import commonQuestionsUtils from 'common/commonQuestionsUtils';
import { Select } from 'src/components/';
import { useCompany } from 'src/queries/company';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';
import { getCategoryOptions } from 'src/utils/historicFilterUtils';

const FilterByCategories = ({
  id,
  isMyProfile,
  userId,
  category,
  updateFn,
  customSelectClasses
}) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const MyProfile = useMyProfile();
  const UserProfile = useUserProfile(userId);
  const {
    revieweeCategories,
    isFetching: isFetchingCategories,
    isError: isErrorCategories
  } = isMyProfile ? MyProfile : UserProfile;
  const categoryOptions = getCategoryOptions(revieweeCategories);

  const isFetching = isFetchingCompany || isFetchingCategories;
  const isError = isErrorCompany || isErrorCategories;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const categorySelected = category;
  const cat = categorySelected
    ? commonQuestionsUtils.findCategory(company.questions, categorySelected)
    : null;

  const title = categoryOptions.length
    ? `Filter by ${cat ? cat.label : 'category'}`
    : 'No categories available';

  const clearFilter = () => updateFn({ category: null });

  const onChange = (option) => updateFn({ category: option.id });

  return (
    <Select
      id={id}
      variant='shadow'
      optionsWidth='wide'
      title={title}
      options={categoryOptions}
      onChange={onChange}
      classes={customSelectClasses ?? 'w-60'}
      showXButton={categorySelected}
      onClickXButton={clearFilter}
      disabled={!categoryOptions.length}
    />
  );
};

export default FilterByCategories;
