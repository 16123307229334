import React from 'react';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';
import ContentBox from 'src/components/ContentBox/ContentBox';
import ContentBoxes from 'src/components/ContentBox/ContentBoxes';
import DateHeatmap from 'src/containers/DashGraphs/DateHeatmap';
import RadialGraph from 'src/containers/DashGraphs/RadialGraph';
import PieChart from 'src/containers/DashGraphs/PieChart';
import RequestReviewBox from 'src/components/RequestReviewBox/RequestReviewBox';
import useOverviewTab from 'src/containers/UserProfile/Overview/queries';
import TopScoresV2 from 'src/containers/UserProfile/TopScores/TopScoresV2';
import { COLORS } from 'src/constants/style';
import HighestSentimentReview from 'src/containers/UserProfile/Overview/components/HighestSentimentReview';
import TopReviewers from 'src/containers/UserProfile/Overview/components/TopReviewers';
import FireIcon from 'src/assets/svg/fire-icon.svg';
import TopReviewersIcon from 'src/assets/svg/top-reviewers-icon.svg';
import TeamIcon from 'src/assets/svg/team-icon.svg';
import InformationCircleSVG from 'src/assets/svg/informationCircle.svg';
import appUtils from 'src/components/appUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useTree } from 'src/queries/tree';
import UserProfileWizard from 'src/containers/UserProfile/Wizard/UserProfileWizard';
import Team from 'src/containers/UserProfile/Overview/components/Team';
import { Select } from 'src/components/';
import { useNavigate } from 'react-router-dom';

const { ACCESS } = COMMON_CONSTANTS;

const FilterByDatePlaceholder = () => (
  <Select
    id='filter-by-date-placeholder-filter'
    disabled
    variant='shadow'
    title='Filter by 3 Months'
    classes='mb-1 w-64'
  />
);

const Overview = ({ userId }) => {
  const navigate = useNavigate();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    isFetching: isFetchingOverview,
    isError: isErrorAccountOverview,
    account,
    revieweeHeatmap: {
      data: revieweeHeatmapData,
      maxBatches: maxRevieweeBatches
    },
    reviewerHeatmap: {
      data: reviewerHeatmapData,
      maxBatches: maxReviewerBatches
    },
    categoryRadialGraph: { data: categoryRadialGraphData },
    sentimentPieChart: { data: sentimentPieChartData },
    highestSentimentReviewReceived: {
      data: highestSentimentReviewReceivedData
    },
    topReviewers: { data: topReviewersData },
    team: { data: teamData }
  } = useOverviewTab(userId);

  const isFetching = isFetchingOverview || isFetchingTree;
  const isError = isErrorAccountOverview || isErrorTree;
  const isReady = tree && tree.id && account && account._id && !isFetching && !isError;

  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = appUtils.getLoggedUserId();
  const showRequestReviewBox = userId !== loggedUserId
    && (loggedUser.access === ACCESS.ADMIN
      || commonTreeUtils.isNodeDirectlyAbove(tree, userId, loggedUserId));

  if (!appUtils.getIsOverviewTabEnabled()) return navigate(appUtils.getDashRoute(userId));

  return (
    <>
      <UserProfileWizard account={account._id} version='V2' />
      {/* <FilterView userId={userId} show={['date']} alwaysDisplayFields={['date']} /> */}
      <FilterByDatePlaceholder />
      <TopScoresV2 userId={userId} isLoading={!isReady} />
      <div className='grid grid-cols-3 grid-rows-2 gap-4 w-full h-full'>
        <ContentBoxes
          titles={['Reviews received', 'Reviews given']}
          isLoading={!isReady}
        >
          <DateHeatmap
            data={revieweeHeatmapData}
            maxBatches={maxRevieweeBatches}
            color={COLORS.INDIGO}
            tooltipText='reviews received'
          />
          <DateHeatmap
            data={reviewerHeatmapData}
            maxBatches={maxReviewerBatches}
            color={COLORS.PINK}
            tooltipText='reviews given'
          />
        </ContentBoxes>
        <ContentBox title='Sentiment Feedback' isLoading={!isReady}>
          <PieChart data={sentimentPieChartData} />
        </ContentBox>
        <ContentBox title='Competencies' isLoading={!isReady}>
          <RadialGraph maxValue={100} data={categoryRadialGraphData} />
        </ContentBox>
        <ContentBox
          title='Highest Sentiment Review'
          icon={<FireIcon />}
          isLoading={!isReady}
        >
          <HighestSentimentReview data={highestSentimentReviewReceivedData} />
        </ContentBox>
        <ContentBox
          title='Top Reviewers'
          icon={<TopReviewersIcon />}
          isLoading={!isReady}
          tooltip={{
            text: `Top reviewers for ${account.name}`,
            icon: <InformationCircleSVG fill='black' />
          }}
        >
          <TopReviewers data={topReviewersData} />
        </ContentBox>
        <ContentBox title='Team' icon={<TeamIcon />} isLoading={!isReady}>
          <Team data={teamData} />
        </ContentBox>
        {showRequestReviewBox ? <RequestReviewBox /> : null}
      </div>
    </>
  );
};

export default Overview;
