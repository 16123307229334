import React, { useContext } from 'react';
import {
  Select, Button, toast, appUtils
} from 'src/components/';
import { exportGoalsQuery } from 'src/containers/Goals/queries';
import { GoalsContext } from 'src/context/Goals/GoalsProvider';
import { useNavigate } from 'react-router-dom';

const GoalsActions = () => {
  const loggedUserId = appUtils.getLoggedUserId();
  const { context } = useContext(GoalsContext);
  const { mutateAsync: exportData } = exportGoalsQuery();
  const actionOptions = [{ id: 'excel', label: 'Export Data' }];

  const exportFn = async () => {
    const res = await exportData({
      filename: 'Goals',
      filters: { ...context }
    });
    toast.show('Export successful');
  };

  const navigate = useNavigate();
  return (
    <div className='flex flex-col-reverse lg:flex-row gap-2 text-black self-end mb-2'>
      <Select
        id='goals-actions-actions-filter'
        title='Actions'
        classes='w-36'
        variant='shadow'
        onChange={() => {
          exportFn();
        }}
        options={actionOptions}
      />
      <div className='p-0.5 bg-yellow rounded'>
        <Button
          variant='custom'
          classes='w-32 p-1 text-base font-bold answer transition-colors duration-300'
          onClick={() => navigate(
            `/dashboard/goal/new/${loggedUserId}?redir=/dashboard/goals`
          )}
        >
          Create Goal
        </Button>
      </div>
    </div>
  );
};

export default GoalsActions;
