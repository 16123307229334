import React, { useEffect, useState } from 'react';
import { appUtils } from 'src/components';
import FilterByDate from 'src/containers/UserProfile/FilterView/FilterByDate';
import GeneralFilter from 'src/containers/UserProfile/FilterView/GeneralFilter';
import { useCompany } from 'src/queries/company';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonDateUtils from 'common/commonDateUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import { useTree } from 'src/queries/tree';
import commonQuestions from 'common/commonQuestions';
import { uniq, get } from 'lodash';
import commonQuestionsUtils from 'common/commonQuestionsUtils';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';

const { ACCESS, DEFAULT_ROLE, DEFAULT_CATEGORY } = COMMON_CONSTANTS;

const getAvailableCategories = (rolesList, selectedRoleIds) => uniq(
  rolesList
    .map((role) => {
      if (!selectedRoleIds.includes(role.id)) return [];
      const { categories } = role;
      return categories;
    })
    .flat()
);

const CompetencyComparisonFilters = ({
  filters,
  setFilters,
  maxCategories = 10
}) => {
  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = appUtils.getLoggedUserId();
  const isAdmin = loggedUser.access === ACCESS.ADMIN;

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: { tree, managerList },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingCompany || isFetchingTree;
  const isError = isErrorCompany || isErrorTree;
  const isReady = company && company.id && !isFetching && !isError;

  const {
    range, managerId, selectedRoleIds, selectedCategoryIds
  } = filters;

  if (!isReady) return null;

  const validTeamNodes = isAdmin
    ? managerList
    : commonTreeUtils.getManagerNodeUnder(tree, loggedUserId);

  const teamOptions = validTeamNodes.map((node) => ({
    id: node.id,
    label: node.name
  }));

  const managerNode = commonTreeUtils.findNodeById(tree, managerId);
  const teamRoleIds = uniq(
    [
      ...get(managerNode, 'roles', []),
      ...get(managerNode, 'children', []).map((node) => node.roles)
    ].flat()
  );

  const companyQuestions = company.questions;
  const TEAM_ROLES_LIST = commonQuestions
    .convertCompanyRolesToList(companyQuestions.ROLES)
    .filter(
      (role) => role.id !== DEFAULT_ROLE.id
        && role.status === COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
        && teamRoleIds.includes(role.id)
    );

  const rolesCategoryIds = getAvailableCategories(
    TEAM_ROLES_LIST,
    selectedRoleIds
  );

  const TEAM_CATEGORIES_LIST = commonQuestions
    .convertCompanyCategoriesToList(companyQuestions.CATEGORIES)
    .filter(
      (category) => category.id !== DEFAULT_CATEGORY.id
        && category.status === COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
        && rolesCategoryIds.includes(category.id)
    );

  const roleOptions = TEAM_ROLES_LIST.map((r) => ({
    id: r.id,
    label: r.label,
    checked: selectedRoleIds.includes(r.id)
  })).sort((a, b) => (a.label > b.label ? 1 : -1));
  const isAnyRoleSelected = roleOptions.some((option) => option.checked);

  const categoryOptions = TEAM_CATEGORIES_LIST.map((c) => ({
    id: c.id,
    label: c.label,
    checked: selectedCategoryIds.includes(c.id),
    disabled:
      selectedCategoryIds.length >= maxCategories
      && !selectedCategoryIds.includes(c.id)
  })).sort((a, b) => (a.label > b.label ? 1 : -1));

  const getTitle = (field) => {
    if (field === 'categories') {
      const count = selectedCategoryIds.length;
      return count ? `${count} categories selected` : 'Filter by Categories';
    }

    if (field === 'roles') {
      const count = selectedRoleIds.length;
      return count ? `${count} roles selected` : 'Filter by Roles';
    }

    if (field === 'team') {
      return managerNode ? `Team ${managerNode.name}` : 'Filter by Team';
    }
  };

  return (
    <div className='mt-1 mb-2 flex justify-evenly gap-2 w-full'>
      <GeneralFilter
        multiselect
        wrapperClasses='!w-1/4'
        title={getTitle('roles')}
        options={roleOptions}
        onChange={(option) => {
          if (option.checked) {
            const newSelectedRolesIds = selectedRoleIds.filter(
              (id) => id !== option.id
            );

            const newAvailableCategories = getAvailableCategories(
              TEAM_ROLES_LIST,
              newSelectedRolesIds
            );

            const newSelectedCategoryIds = selectedCategoryIds.filter((id) => newAvailableCategories.includes(id));
            setFilters({
              ...filters,
              selectedRoleIds: newSelectedRolesIds,
              selectedCategoryIds: newSelectedCategoryIds
            });
          } else {
            setFilters({
              ...filters,
              selectedRoleIds: [...selectedRoleIds, option.id]
            });
          }
        }}
        onClear={() => {
          setFilters({
            ...filters,
            selectedRoleIds: [],
            selectedCategoryIds: []
          });
        }}
      />
      <GeneralFilter
        multiselect
        wrapperClasses='!w-1/4'
        title={getTitle('categories')}
        options={categoryOptions}
        disabled={!isAnyRoleSelected}
        onChange={(option) => {
          setFilters({
            ...filters,
            selectedCategoryIds: option.checked
              ? selectedCategoryIds.filter((id) => id !== option.id)
              : [...selectedCategoryIds, option.id]
          });
        }}
        onClear={() => {
          setFilters({
            ...filters,
            selectedCategoryIds: []
          });
        }}
      />
      <GeneralFilter
        wrapperClasses='!w-1/4'
        title={getTitle('team')}
        options={teamOptions}
        onChange={(option) => {
          const newManagerNode = commonTreeUtils.findNodeById(tree, option.id);
          const defaultRoleIds = uniq(
            [
              ...get(newManagerNode, 'roles', []),
              ...get(newManagerNode, 'children', null).map((node) => node.roles)
            ].flat()
          );
          const defaultRoles = defaultRoleIds.map((roleId) => commonQuestionsUtils.findRole(companyQuestions, roleId));
          const defaultCategoryIds = uniq(
            defaultRoles.map((role) => role.categories).flat()
          ).splice(0, maxCategories);

          setFilters({
            ...filters,
            managerId: option.id,
            selectedRoleIds: defaultRoleIds,
            selectedCategoryIds: defaultCategoryIds
          });
        }}
      />
      <FilterByDate
        hideKeys={['custom']}
        classes='!w-1/4'
        dateRangeSelected={range}
        onSelectDateRange={(value, selectedStart, selectedEnd) => {
          const startValue = commonDateUtils.getFirstUnixOfDateFromUnix(
            selectedStart || commonDateUtils.dateToUnix('2020-01-01')
          );
          const endValue = commonDateUtils.getFirstUnixOfDateFromUnix(
            selectedEnd || commonDateUtils.dateToUnix(new Date())
          );

          const isCustom = value === 'custom';
          setFilters({
            ...filters,
            range: {
              value,
              start: startValue,
              end: isCustom ? endValue : undefined
            }
          });
        }}
      />
    </div>
  );
};

export default CompetencyComparisonFilters;
