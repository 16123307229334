import React, { useContext } from 'react';
import { Base } from 'src/components/index';
import STYLE from 'src/constants/style';
import { useCompany } from 'src/queries/company';
import HashtagSVG from 'src/assets/svg/hashtag.svg';
import CircledIcon from 'src/components/CircledIcon/CircledIcon';
import { useAccountsScore } from 'src/queries/account';
import { isFinite } from 'lodash';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';

const TeamOverallScores = () => {
  const { context } = useContext(CompanyDashContext);
  const { range, filters } = context;
  const { managerId } = filters;

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: teamScores,
    isFetching: isFetchingTeamScores,
    isRefetching: isRefetchingTeamScores,
    isError: isErrorTeamScores
  } = useAccountsScore(
    {
      ids: [managerId],
      managerId,
      ...(range.start && {
        start: range.start,
        end: range.end
      })
    },
    {
      role: 'reviewee',
      bundleCategories: true,
      merge: true
    },
    {
      enabled: true,
      keepPreviousData: true
    }
  );

  const isInitialFetchingTeamSampledScores = isFetchingTeamScores && !isRefetchingTeamScores;
  const isFetching = isFetchingCompany || isInitialFetchingTeamSampledScores;
  const isError = isErrorCompany || isErrorTeamScores;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const [teamScore] = teamScores;
  const {
    avgScoreReceived,
    avgScoreLabel,
    avgSentimentLabel,
    reviewedReceivedCount,
    commentedReceivedCount,
    feedbackReceivedCount
  } = teamScore;

  const hasNumericData = isFinite(avgScoreReceived);
  const avgScoreReceivedlabel = hasNumericData
    ? `${avgScoreReceived}, ${avgScoreLabel}`
    : avgScoreLabel;

  let commentedAndFeedbackReceivedCountCount;
  if (isFinite(commentedReceivedCount) && isFinite(feedbackReceivedCount)) {
    commentedAndFeedbackReceivedCountCount = commentedReceivedCount + feedbackReceivedCount;
  } else if (isFinite(commentedReceivedCount)) {
    commentedAndFeedbackReceivedCountCount = commentedReceivedCount;
  } else if (isFinite(feedbackReceivedCount)) {
    commentedAndFeedbackReceivedCountCount = feedbackReceivedCount;
  }

  const isLoading = isFetchingTeamScores;
  return (
    <Base
      classes={`${STYLE.CONTAINER_WHITE} !p-4 !mt-0`}
      relative
      loading={isLoading}
    >
      <CircledIcon svg={<HashtagSVG className='text-purple' />} />
      <p className='text-xl text-black font-bold mb-0 mr-8'>
        Overall Performance Metrics
      </p>
      <p className='text-md font-normal my-2 leading-5 text-bluish-gray'>
        Summary statistics based on reviews and feedback from the team.
      </p>
      <div className='border-b border-mid-gray my-3 transform -translate-x-[16px] w-[calc(100%+32px)]' />
      <p className='text-[#101828] font-[700] text-[24px] leading-[28px] m-0 py-[5px]'>
        {avgScoreReceivedlabel}
      </p>
      <div className='w-full flex justify-between items-center'>
        <p className='text-[#727272] font-[400] text-[14px] leading-[17px] m-0'>
          Overall Performance
        </p>
      </div>
      <div className='border-b border-dashed border-mid-gray my-3 transform -translate-x-[16px] w-[calc(100%+32px)]' />
      <div className='w-full'>
        <p className='text-[#101828] font-[700] text-[24px] leading-[28px] m-0 py-[5px]'>
          {isFinite(reviewedReceivedCount)
            ? reviewedReceivedCount.toLocaleString()
            : '-'}
        </p>
        <p className='text-[#727272] font-[400] text-[14px] leading-[17px] m-0'>
          Reviews Received
        </p>
      </div>
      <div className='border-b border-dashed border-mid-gray my-3 transform -translate-x-[16px] w-[calc(100%+32px)]' />
      <div className='w-full'>
        <p className='text-[#101828] font-[700] text-[24px] leading-[28px] m-0 py-[5px]'>
          {isFinite(commentedAndFeedbackReceivedCountCount)
            ? commentedAndFeedbackReceivedCountCount.toLocaleString()
            : '-'}
        </p>
        <p className='text-[#727272] font-[400] text-[14px] leading-[17px] m-0'>
          Total Feedback Comments
        </p>
      </div>
      <div className='border-b border-dashed border-mid-gray my-3 transform -translate-x-[16px] w-[calc(100%+32px)]' />
      <div className='w-full'>
        <p className='text-[#101828] font-[700] text-[24px] leading-[28px] m-0 py-[5px]'>
          {avgSentimentLabel}
        </p>
        <p className='text-[#727272] font-[400] text-[14px] leading-[17px] m-0'>
          Feedback Sentiment
        </p>
      </div>
    </Base>
  );
};

export default TeamOverallScores;
