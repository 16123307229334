import React from 'react';
import COMMON_CONSTANTS from 'common/commonConstants';

const { UPLOAD_USERS_ERROR_TYPES } = COMMON_CONSTANTS;

const ErrorTraceList = ({ error }) => {
  const shouldRender = error.traceList && error.traceList.length;
  return shouldRender ? (
    <ul className='ml-5 list-outside list-disc'>
      {error.traceList.map((trace, listIndex) => (
        <li
          className='mb-0'
          key={`${listIndex}_trace_${error.id}`}
        >
          {`${trace}`}
        </li>
      ))}
    </ul>
  ) : null;
};

const UploadOrganizationErrors = ({ errors }) => {
  const systemErrors = errors.filter(
    (error) => error.type === UPLOAD_USERS_ERROR_TYPES.SYSTEM
  );
  const structureErrors = errors.filter(
    (error) => error.type === UPLOAD_USERS_ERROR_TYPES.STRUCTURE
  );
  const treeErrors = errors.filter(
    (error) => error.type === UPLOAD_USERS_ERROR_TYPES.TREE
  );
  const userErrors = errors.filter(
    (error) => error.type === UPLOAD_USERS_ERROR_TYPES.USER
  );

  return (
    <div className='w-full mt-12 rounded shadow bg-white p-4'>
      <h5 className='mb-5 font-semibold'>Upload Errors</h5>
      {systemErrors.length ? (
        <div>
          <p className='mb-3 text-black text-lg'>
            System Errors (check Error Reporting on Google Cloud)
          </p>
          <div className='w-full bg-red p-3 mb-5 rounded'>
            <ol className='mb-2 ml-5 list-outside list-decimal'>
              {systemErrors.map((error, index) => (
                <li
                  key={`${index}_system_${error.warning}`}
                  className='mb-0 text-white'
                >
                  {`${error.warning}`}
                  <ErrorTraceList error={error} />
                </li>
              ))}
            </ol>
          </div>
        </div>
      ) : null}
      {
        structureErrors.length ? (
          <div>
            <p className='mb-3 text-black text-lg'>New Structure errors</p>
            <div className='w-full bg-red p-3 mb-5 rounded'>
              <ol className='mb-2 ml-5 list-outside list-decimal'>
                {
                  errors.map((error, index) => (
                    <li
                      className='mb-0 text-white ml-3'
                      key={`${index}_structure_${error.id}`}
                    >
                      <p className='mb-0'>{`${error.warning}`}</p>
                      {
                        error.reason ? (
                          <ul className='ml-5 list-outside list-disc'>
                            <li
                              className='mb-0'
                              key={`${index}_expected_${error.id}`}
                            >
                              {`Expected: ${error.reason.expected}`}
                            </li>
                            <li
                              className='mb-0'
                              key={`${index}_actual_${error.id}`}
                            >
                              {`Actual: ${error.reason.actual}`}
                            </li>
                          </ul>
                        ) : (
                          <ErrorTraceList error={error} />
                        )
                      }
                    </li>
                  ))
                }
              </ol>
            </div>
          </div>
        ) : null
      }
      {
        treeErrors.length ? (
          <div>
            <p className='mb-3 text-black text-lg'>New Tree errors</p>
            <div className='w-full bg-red p-3 mb-5 rounded'>
              <ol className='mb-2 ml-5 list-outside list-decimal'>
                {treeErrors.map((error) => (
                  <li
                    className='mb-0 text-white'
                    key={`${error.id}_tree_${error.warning}`}
                  >
                    {`${error.warning}`}
                    <ErrorTraceList error={error} />
                  </li>
                ))}
              </ol>
            </div>
          </div>
        ) : null
      }
      {
        userErrors.length ? (
          <div>
            <p className='mb-3 text-black text-lg'>User errors</p>
            {userErrors.map((errorObj) => (
              <div className='w-full bg-red p-3 rounded mb-1.5'>
                <p className='mb-3 text-white bold'>{`User: ${errorObj.user.name} (Line: ${errorObj.user.lineIndex})`}</p>
                <ol className='mb-2 ml-5 list-outside list-decimal'>
                  {errorObj.errors.map((error, index) => (
                    <li
                      className='mb-0 text-white'
                      key={`${index}_user_${errorObj.id}`}
                    >
                      {`${error.warning}`}
                      <ErrorTraceList error={{ ...error, id: errorObj.id }} />
                    </li>
                  ))}
                </ol>
              </div>
            ))}
          </div>
        ) : null
      }
    </div>
  );
};

export default UploadOrganizationErrors;
