import React, { useEffect, useState, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { xor } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNewUserReportContext } from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';
import { useTree } from 'src/queries/tree';
import {
  useReport,
  shareReportQuery,
  updateReportQuery,
  QUERY_KEYS as REPORTS_QUERY_KEYS
} from 'src/queries/reports';
import {
  useAccount,
  QUERY_KEYS as ACCOUNT_QUERY_KEYS
} from 'src/queries/account';
import useUpdateReport from 'src/hooks/UserReports/useUpdateReport';
import { ConfirmationModal, Button, toast } from 'src/components';
import { ALL_STEPS } from 'src/pagesDashboard/NewUserReport/utils';
import buildParams from 'src/queries/utils/buildParams';
import BinSVG from 'src/assets/svg/bin-v2.svg';
import ShareV2 from 'src/assets/svg/share-v2.svg';
import appUtils from 'src/components/appUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonPermissions from 'common/commonPermissions';
import DownloadFile from 'src/assets/svg/download-file.svg';
import { useNavigate, useParams } from 'react-router-dom';
import GeneralFilter from 'src/containers/UserProfile/FilterView/GeneralFilter';

const { USER_STATE, REPORT_STATUS, INCLUDE_SCORES_OPTIONS } = COMMON_CONSTANTS;

const getUserOptions = ({
  treeList,
  loggedUserId,
  accountSearchText,
  shareIds,
  oldShareReportIds
}) => {
  if (!treeList || !treeList.length || !loggedUserId) return [];

  const users = treeList
    .filter((n) => (
      (n.active === USER_STATE.ACTIVE
        || (n.active === USER_STATE.PASSIVE && oldShareReportIds.includes(n.id))
        || (n.active === USER_STATE.INACTIVE && oldShareReportIds.includes(n.id))
      )
      && n.name.toLowerCase().includes(accountSearchText.toLowerCase())
      && n.id !== loggedUserId
    ))
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    .map((t) => ({
      id: t.id,
      label: `Share with ${t.name}`,
      checked: shareIds.includes(t.id),
      disabled: t.id === loggedUserId || (t.active !== USER_STATE.ACTIVE && !shareIds.includes(t.id)),
      status: t.active
    }));
  return users;
};

const NewUserReportShare = () => {
  const { userId, reportId } = useParams();
  const navigate = useNavigate();
  const { updateContext, context } = useNewUserReportContext();
  const { showStatsOnReportBuilder } = useFlags();
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  const formRef = useRef();

  const { reopenReport, refreshReports } = context;

  const loggedUserId = appUtils.getLoggedUserId();
  const queryClient = useQueryClient();

  useEffect(() => {
    updateContext({ formRef, activeStep: ALL_STEPS.FINALIZE });
  }, []);

  const invalidateReportQueries = () => {
    queryClient.invalidateQueries([REPORTS_QUERY_KEYS.REPORT, reportId]);
    queryClient.invalidateQueries(REPORTS_QUERY_KEYS.SHARED_REPORTS);
    queryClient.invalidateQueries(REPORTS_QUERY_KEYS.MY_REPORTS);
    queryClient.invalidateQueries(ACCOUNT_QUERY_KEYS.TASKS);
  };

  const { data: report, isFetching: isFetchingReport } = useReport(reportId);
  const { mutateAsync: shareReport, isLoading: isShareReportLoading } = shareReportQuery();
  const { mutateAsync: updateReport, isLoading: isUpdateReportLoading } = updateReportQuery();
  const { remove: deleteReport, pdf: exportAsPdf } = useUpdateReport(reportId);

  const generateReportPFD = async () => {
    setIsLoadingPdf(true);
    await exportAsPdf({
      reportId,
      isScoresVisible: report.includeScores === INCLUDE_SCORES_OPTIONS.YES,
      hideStats: !showStatsOnReportBuilder,
      compareToReport: report.previousReport
    });
    setIsLoadingPdf(false);
  };

  const {
    data: { treeList, myTreeRow, tree },
    isFetching: isFetchingTree
  } = useTree();

  const { data: accountData, isFetching: isFetchingAccount } = useAccount('me');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleteReportLoading, setIsDeleteReportLoading] = useState(false);
  const [accountSearchText, setAccountSearchText] = useState('');
  const [shareIds, setShareIds] = useState([]);
  const [newStatus, setNewStatus] = useState(report.status);

  const { handleSubmit } = useForm({
    defaultValues: {}
  });

  const isFetching = isFetchingReport || isFetchingTree || isFetchingAccount;

  useEffect(() => {
    updateContext({
      isLoading: isFetching
    });
  }, [isFetching]);

  useEffect(() => {
    if (reopenReport) {
      setNewStatus(REPORT_STATUS.IN_PROGRESS);
      updateContext({ reopenReport: false, refreshReports: !refreshReports });
    }
  }, [context]);

  useEffect(() => {
    if (report.share.length) {
      const currentShareIds = report.share.map((shareObj) => shareObj.id);
      setShareIds(currentShareIds);
    }
  }, []);

  const isReportAuthor = loggedUserId === report.createdBy;
  const isViewerDirectlyAboveUser = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    report.user,
    myTreeRow.id
  );
  const canManageAccount = commonPermissions.canManageAccounts(accountData, [
    report.user
  ]);

  const canUpdateReport = commonPermissions.isAdmin(accountData)
    || isViewerDirectlyAboveUser
    || isReportAuthor
    || canManageAccount;

  // const statusOptions = Object.values(REPORT_STATUS).map((status) => ({
  //   id: status,
  //   label: status
  // }));

  const updateChangeShareWith = async () => {
    try {
      const removed = report.share
        .filter((u) => !shareIds.includes(u.id))
        .map((u) => u.id);

      await shareReport({
        reportId,
        users: {
          new: shareIds,
          removed
        }
      });
      queryClient.invalidateQueries([REPORTS_QUERY_KEYS.REPORT, reportId]);
      queryClient.invalidateQueries(REPORTS_QUERY_KEYS.SHARED_REPORTS);
      toast.show('Report shared');
    } catch (error) {
      console.error('Failed to share report', error);
      return toast.error(error ?? 'Failed to share report');
    }
  };

  const updateStatus = async (updateToStatus) => {
    try {
      await updateReport({
        reportId,
        status: updateToStatus
      });
      invalidateReportQueries();
      updateContext({ refreshReports: !refreshReports });
      toast.show('Report status updated');
    } catch (error) {
      console.error('Failed to update report status', error);
      return toast.error(error ?? 'Failed to update report status');
    }
  };

  const onDeleteReport = async () => {
    setIsDeleteReportLoading(true);

    const routeToGo = loggedUserId === userId
      ? `/dashboard/me/reports`
      : `/dashboard/profile/${userId}/reports`;

    await deleteReport();
    invalidateReportQueries();
    navigate(routeToGo);
  };

  const finalizeReport = () => {
    queryClient.invalidateQueries([
      REPORTS_QUERY_KEYS.REPORTS,
      buildParams({ userId })
    ]);
    invalidateReportQueries();
    const routeToGo = loggedUserId === userId
      ? `/dashboard/me/reports`
      : `/dashboard/profile/${userId}/reports`;
    return navigate(routeToGo);
  };

  // const isDirtyStatus = newStatus !== report.status;
  const isDirtyShare = !!xor(
    report.share.map((u) => u.id),
    shareIds
  ).length;

  if (isFetching) return <div className='h-full' />;

  const oldShareReportIds = report.share.length ? report.share.map((shareObj) => shareObj.id) : [];

  const shareOptions = getUserOptions({
    treeList,
    loggedUserId,
    accountSearchText,
    shareIds,
    oldShareReportIds
  });

  const getTitle = () => {
    const count = shareIds.length;
    if (!count) return 'Share with...';
    if (count === 1) return '1 user selected';
    if (count > 1) return `${count} users selected`;
  };

  const shouldAddFixedMarginTop = window.innerHeight <= 1128;

  // const isStatusSelectDisabled = report.status === REPORT_STATUS.FINISHED || !canUpdateReport;
  // const isStatusSelectDisabled = false;
  // const isUpdateDisabled = !canUpdateReport || !isDirtyStatus || isUpdateReportLoading;
  return (
    <form
      id='new-user-report-share-form'
      ref={formRef}
      className='flex flex-col h-full w-full justify-between'
      onSubmit={handleSubmit(finalizeReport)}
    >
      {isLoadingPdf ? (
        <div className='fixed inset-0 z-20 backdrop-blur-[2px]' />
      ) : null}
      {showDeleteModal ? (
        <ConfirmationModal
          id={reportId}
          onAction={onDeleteReport}
          onClose={() => setShowDeleteModal(false)}
          title='Delete Report'
          subtitle='Are you sure you would like to delete this report?'
          actionText='Delete'
          isLoading={isDeleteReportLoading}
        />
      ) : null}
      <div className={`flex flex-col md:mb-30 my-auto  ${shouldAddFixedMarginTop ? 'md:mt-16' : ''}`}>
        <div className='flex flex-col w-3/5 mb-5 mx-auto'>
          <div className='flex flex-col justify-between w-full border border-gray-200 rounded p-4 shadow-md'>
            <p className='mb-2 font-bold'>Status</p>
            <p className='mb-4 text-md text-gray-500'>
              The performance review status is used to determine when a review
              is complete or not. Outstanding tasks related to review will stay
              open until the review is finalized.
            </p>
            <div className='w-full mb-1'>
              <div className='w-full justify-between text-right'>
                {report.status === REPORT_STATUS.FINISHED ? (
                  <Button
                    classes='w-1/3 h-9 mr-2 rounded-md px-4 py-1'
                    variant='purple-with-border-sm'
                    onClick={() => updateStatus(REPORT_STATUS.IN_PROGRESS)}
                    disabled={!canUpdateReport}
                  >
                    Reopen
                  </Button>
                ) : (
                  <Button
                    classes='w-1/3 h-9 mr-2 rounded-md px-4 py-1'
                    variant='purple-with-border-sm'
                    onClick={() => updateStatus(REPORT_STATUS.FINISHED)}
                    disabled={!canUpdateReport}
                  >
                    Finalize
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col w-3/5 mt-10 mb-5 mx-auto'>
          <div className='flex flex-col justify-between w-full border border-gray-200 rounded p-4 shadow-md'>
            <p className='mb-2 font-bold'>Share</p>
            <p className='mb-4 text-md text-gray-500'>
              When you share a performance review, it creates a task for that
              user to collaborate on the review. They will have that task until
              the performance review is finalized.
            </p>
            <div className='w-full mb-1 relative'>
              <div className='flex flex-row w-full justify-between relative'>
                <GeneralFilter
                  multiselect
                  size='30'
                  wrapperClasses='w-[60%]'
                  disabled={!canUpdateReport || isShareReportLoading}
                  disabledOptionClasses='text-gray-400 cursor-default'
                  title={getTitle()}
                  options={shareOptions}
                  searchNoDataPlaceholder='No users found'
                  onSearch={(value) => setAccountSearchText(value)}
                  onDropdownClose={() => setAccountSearchText('')}
                  onChange={(option) => setShareIds((
                    option.checked
                      ? shareIds.filter((id) => id !== option.id)
                      : [...shareIds, option.id]
                  ))}
                  onClear={() => {
                    setShareIds([]);
                  }}
                />
                <Button
                  classes='w-1/3 h-9 text-sm mr-2 rounded-md bg-purple text-white px-4 py-1 font-bold answer transition-colors duration-300 flex items-center justify-center'
                  variant='custom'
                  onClick={updateChangeShareWith}
                  disabled={
                    !canUpdateReport || !isDirtyShare || isShareReportLoading
                  }
                >
                  <ShareV2 className='w-4 h-4 mr-1.5 mb-0.5' />
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col w-3/5 mt-10 mb-5 mx-auto'>
          <div className='flex flex-col justify-between w-full border border-gray-200 rounded p-4 shadow-md'>
            <p className='mb-2 font-bold'>Options</p>
            <p className='mb-4 text-md text-gray-500 leading-4'>
              This review has been drafted. You can download the latest version
              or delete it as necessary.
            </p>
            <div className='w-full mb-1'>
              <div className='flex flex-row w-full justify-between'>
                <Button
                  classes='w-1/2 mr-3 h-9 px-4 py-0 flex items-center justify-center gap-2'
                  variant='purple-with-border-sm'
                  onClick={() => generateReportPFD()}
                  disabled={isFetching}
                >
                  <DownloadFile className='text-white' />
                  Download Report
                </Button>
                {canUpdateReport ? (
                  <button
                    onClick={() => setShowDeleteModal(true)}
                    disabled={isFetching}
                    type='button'
                    className='flex h-9 items-center border border-hover-red text-hover-red bg-white hover:bg-red-warning px-3 py-2 w-1/2 rounded-md transition-colors duration-300 focus:outline-none justify-center gap-2'
                  >
                    <BinSVG className='text-hover-red' />
                    Delete Report
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-row flex-wrap gap-4 items-center justify-center md:justify-center text-md w-full md:w-28rem m-auto mt-10'>
          {/* <button className='flex items-center justify-around gap-2 text-black px-3 py-2 font-bold border border-black hover:text-dark-grey hover:border-dark-grey rounded transition-colors duration-300'>
            <ClipboardListSVG className='w-6 h-6' />
            Copy Link
          </button> */}
        </div>
      </div>
    </form>
  );
};

export default NewUserReportShare;
