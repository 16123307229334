import React from 'react';
import TopFilters from 'src/containers/CompanyDash/TopFilters/TopFilters';
import ReviewPartipationRanking from 'src/pagesDashboard/CompanyDash/components/ReviewParticipationRanking';
import ConstructiveReviewRanking from 'src/pagesDashboard/CompanyDash/components/ConstructiveReviewRanking';
import HistoricReviewsFeed from 'src/pagesDashboard/CompanyDash/components/HistoricReviewsFeed';
import COMMON_CONSTANTS from 'common/commonConstants';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

const OrganizationParticipation = () => (
  <div>
    <TopFilters
      show={['date', 'team']}
      hideKeys={[DATE_RANGE_FILTERS.CUSTOM.key]}
    />
    <ReviewPartipationRanking />
    <ConstructiveReviewRanking />
    <HistoricReviewsFeed />
  </div>
);

export default OrganizationParticipation;
