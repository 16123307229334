import React from 'react';
import { Select } from 'src/components';
import COMMON_CONSTANTS from 'common/commonConstants';
import { capitalize } from 'lodash';

const { SENTIMENT_RANGE } = COMMON_CONSTANTS;

const getSentimentTitle = (sentiments) => {
  let filterTitle = 'Filter by Sentiment';
  if (!sentiments || !sentiments.length) {
    return filterTitle;
  }
  if (sentiments) {
    if (sentiments.length > 1) {
      filterTitle = `${sentiments.length} sentiment selected`;
    }
    if (sentiments.length === 1) {
      filterTitle = `1 sentiment selected`;
    }
  }
  return filterTitle;
};

const FilterBySentiment = ({ id, sentiment, updateFn }) => {
  const onChangeSentiment = (selectedOption) => {
    const exists = sentiment && sentiment.includes(selectedOption.id);
    const currentSentiments = sentiment ?? [];
    const newSentiments = exists
      ? currentSentiments.filter(
        (curSentiment) => curSentiment !== selectedOption.id
      )
      : [...currentSentiments, selectedOption.id];

    return updateFn({ sentiment: newSentiments });
  };

  const sentimentTitle = getSentimentTitle(sentiment);

  const options = SENTIMENT_RANGE.map((label) => ({
    id: label,
    label: capitalize(label),
    checked: sentiment && sentiment.includes(label)
  }));

  const clearFilter = () => updateFn({ sentiment: [] });

  return (
    <Select
      id={id}
      variant='shadow'
      classes='w-full'
      title={sentimentTitle}
      multiselect
      options={options}
      onChange={onChangeSentiment}
      showXButton={sentiment && sentiment.length}
      onClickXButton={clearFilter}
    />
  );
};

export default FilterBySentiment;
