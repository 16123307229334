import React, { useEffect, useState } from 'react';
import {
  isEmpty, uniq, get, uniqBy
} from 'lodash';
import commonPermissions from 'common/commonPermissions';
import appUtils from 'src/components/appUtils';
import { Base, toast } from 'src/components/';
import STYLE from 'src/constants/style';
import commonDateUtils from 'common/commonDateUtils';
import { TYPES } from 'src/componentsTailwind/Table/Table';
import { BADGE_COLOR_CLASSES } from 'src/componentsTailwind/tailwindConstants';
import { Table } from 'src/componentsTailwind';
import { useCompanyAsDataset } from 'src/queries/company';
import {
  useBuilderDataset,
  useBuilderReports,
  useDeleteBuilderReport,
  QUERY_KEYS as BUILDER_QUERY_KEYS,
  useCreatePdf
} from 'src/queries/builder';
import commonUtils from 'common/commonUtils';
import BuilderDashboardFilters from 'src/pages/PerformanceBuilder/BuilderDashboardFilters';
import COMMON_CONSTANTS from 'common/commonConstants';
import ChevronDownSVG from 'src/assets/svg/chevronDown.svg';
import { useNavigate } from 'react-router-dom';
import { AlertModal } from 'src/componentsTailwind/';
import { ACCESS } from 'src/constants/index';

const { DATE_RANGE_FILTERS, BUILDER_REPORT_STATUS } = COMMON_CONSTANTS;

const renderEmptyPlaceholder = (areThereReports) => (
  <div className='flex justify-center items-center h-52 w-full'>
    <p className='text-black text-md m-0'>
      {areThereReports
        ? 'No performance reviews matching these filters'
        : 'No performance reviews created yet'}
    </p>
  </div>
);

const BuilderDashboard = () => {
  const navigate = useNavigate();
  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = appUtils.getLoggedUserId();
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const { companyid } = loggedUser;
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    cycleIds: [],
    roleIds: [],
    lastUpdated: {
      value: DATE_RANGE_FILTERS.THREE_MONTHS.key,
      start: commonDateUtils.getFirstUnixOfDate(
        commonDateUtils.getDateFromDaysAgo(DATE_RANGE_FILTERS.THREE_MONTHS.days)
          .date
      ),
      end: undefined
    },
    reviewerIds: [],
    revieweeIds: []
  });

  const [reportIdToDelete, setReportIdToDelete] = useState(null);
  const [expandedGroups, setExpandedGroups] = useState([]);

  const {
    data: builderDataset,
    isFetching: isFetchingBuilderDataset,
    isError: isErrorBuilderDataset
  } = useBuilderDataset();

  const {
    data: companyData,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompanyAsDataset(companyid, loggedUserId);

  const {
    cycleIds, roleIds, lastUpdated, reviewerIds, revieweeIds
  } = filters;

  const {
    data: singleReportData,
    isFetching: isFetchingSingleReport,
    isError: isErrorSingleReport
  } = useBuilderReports(
    {
      companyid
    },
    {
      viewerId: loggedUserId,
      page: { size: 1 }
    }
  );

  const {
    data: reportGroups,
    meta: { page },
    isFetching: isFetchingReports,
    isRefetching: isRefetchingReports,
    isError: isErrorReports
  } = useBuilderReports(
    {
      companyid,
      lastUpdated,
      // ...(!isEmpty(reviewerIds) && {
      //   reviewerIdsOr: [...reviewerIds, ...(isAdmin ? [] : [loggedUserId])]
      // }),
      ...(!isAdmin && { reviewerIdsOr: [loggedUserId] }),
      ...(!isEmpty(cycleIds) && { cycleIds }),
      ...(!isEmpty(roleIds) && { roleIds }),
      ...(!isEmpty(revieweeIds) && { revieweeIds })
    },
    {
      viewerId: loggedUserId,
      page: {
        number: currentPage,
        size: 25
      },
      bundleCycleAndReviewee: true
    },
    {
      keepPreviousData: true
    }
  );

  const { delete: deleteReport, isMutating: isLoadingDeleteBuilderReport } = useDeleteBuilderReport();
  const { mutateAsync: createPdf, isLoading: isLoadingCreatePdf } = useCreatePdf();
  const queryClient = appUtils.getQueryClient();

  const isInitialFetchingReports = isFetchingReports && !isRefetchingReports;
  const isFetching = isInitialFetchingReports
    || isFetchingSingleReport
    || isFetchingCompany
    || isFetchingBuilderDataset;
  const isError = isErrorReports
    || isErrorSingleReport
    || isErrorCompany
    || isErrorBuilderDataset;
  const isReady = !isEmpty(companyData) && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  if (isEmpty(companyData)) {
    toast.show(
      'Data unavailable. Redirecting to the performance review builder...'
    );
    navigate('/builder');
  }

  const [singleReport] = singleReportData;
  const areThereReports = Boolean(singleReport);

  const pagination = {
    currentPage,
    setCurrentPage: (params) => {
      setExpandedGroups([]);
      setCurrentPage(params);
    },
    totalPages: page.totalPages
  };

  const columns = [
    {
      label: 'Expand Row',
      hidden: true,
      span: 0.1,
      tooltip: false
    },
    {
      label: 'Review Cycle',
      span: 1.5
    },
    {
      label: 'Reviewee',
      span: 1.5
    },
    {
      label: 'Reviewer',
      span: 1.5
    },
    {
      label: 'Roles',
      span: 1.5
    },
    {
      label: 'Last Updated',
      span: 1.5
    },
    {
      label: 'Status',
      span: 1
    },
    {
      label: 'Review Count',
      span: 1.5
    },
    {
      type: TYPES.ACTION,
      label: 'Actions',
      span: 0.75,
      paddingless: true,
      hidden: true,
      tooltip: false
    }
  ];

  const { dataset } = companyData;
  const companyAsIndustry = dataset.find(
    (industryData) => industryData.industryId === companyid
  );
  const { cycles } = companyAsIndustry;

  const allRolesData = uniqBy(
    [
      get(companyAsIndustry, 'roles', []),
      ...builderDataset.map((industryData) => industryData.roles)
    ].flat(),
    'roleId'
  );

  const rows = reportGroups.map((group) => {
    const {
      cycleId, revieweeId, lastUpdated: groupLastUpdated, data
    } = group;

    const [sample] = data;
    let cycleLabel = 'No Cycle';
    if (cycleId) {
      const cycle = cycles.find((c) => c.id === cycleId);
      cycleLabel = get(cycle, 'label', 'Cycle Removed');
    }

    const { reviewee } = sample;

    let roleBadges = [];

    const roleIdsInGroup = uniq(
      data.map((report) => report.roleId).filter(Boolean)
    );
    if (!isEmpty(roleIdsInGroup)) {
      const roleIdsToShow = roleIdsInGroup.slice(0, 3);
      const rolesData = allRolesData.filter((r) => roleIdsToShow.includes(r.roleId));
      roleBadges = rolesData.map((r) => ({
        label: r.name,
        colorClasses: BADGE_COLOR_CLASSES.GRAY
      }));
      if (roleIdsInGroup > 3) {
        roleBadges.push({
          label: `and more`,
          color: BADGE_COLOR_CLASSES.GRAY
        });
      }
    }

    const isExpanded = expandedGroups.some(
      (eg) => eg.cycleId === cycleId && eg.revieweeId === revieweeId
    );

    const reportIds = data.map((r) => r._id);

    const groupStatus = data.some(
      (r) => r.status === BUILDER_REPORT_STATUS.DRAFT
    )
      ? BUILDER_REPORT_STATUS.DRAFT
      : BUILDER_REPORT_STATUS.COMPLETE;

    const isAllDraft = data.every(
      (r) => r.status === BUILDER_REPORT_STATUS.DRAFT
    );

    const canManageReviewee = commonPermissions.canManageAccounts(loggedUser, [
      revieweeId
    ]);
    const showGroupedViewAction = (!isAllDraft && isAdmin)
      || (canManageReviewee && !isAllDraft && revieweeId);
    const showGroupedDownloadAction = showGroupedViewAction;

    return [
      {
        meta: {
          cycleId,
          revieweeId,
          classes: `border-x-8 ${isExpanded ? 'bg-very-light-blue border-t-8 border-t-white border-b-4 border-b-very-light-blue border-x-very-light-blue' : 'border-y-8 border-white'}`
        },
        type: TYPES.ACTION,
        icon: (
          <ChevronDownSVG
            width='24px'
            height='24px'
            className={`text-black -ml-5 transform ${isExpanded ? 'rotate-180' : 'rotate-90'}`}
          />
        ),
        onClick: () => {
          if (isExpanded) {
            setExpandedGroups((prev) => prev.filter(
              (eg) => eg.cycleId !== cycleId || eg.revieweeId !== revieweeId
            ));
          } else {
            setExpandedGroups((prev) => [...prev, { cycleId, revieweeId }]);
          }
        }
      },
      {
        label: cycleLabel,
        classes: 'text-black',
        onClick: () => {
          if (isExpanded) {
            setExpandedGroups((prev) => prev.filter(
              (eg) => eg.cycleId !== cycleId || eg.revieweeId !== revieweeId
            ));
          } else {
            setExpandedGroups((prev) => [...prev, { cycleId, revieweeId }]);
          }
        }
      },
      {
        label: revieweeId ? commonUtils.getFullName(reviewee) : 'Various'
      },
      {},
      {},
      {
        label: commonDateUtils.dateToMonthDayYearFormat(
          new Date(groupLastUpdated)
        )
      },
      {
        label: groupStatus
      },
      {
        label: data.length
      },
      {
        type: TYPES.DROP_MENU,
        label: 'Actions',
        direction: 'left',
        ifEmpty: 'hide',
        items: [
          ...(showGroupedDownloadAction
            ? [
              {
                type: TYPES.ACTION,
                label: 'Download',
                onClick: async () => {
                  toast.show('Creating PDF');
                  let response;
                  try {
                    response = await createPdf({
                      reportIds,
                      companyid
                    });
                  } catch (e) {
                    return toast.error('Failed to create PDF');
                  }
                  toast.show('Report exported as pdf');

                  const { documentUrl, name } = response;
                  const a = document.createElement('a');
                  a.setAttribute('href', documentUrl);
                  a.setAttribute('download', name);
                  const el = document.getElementById('app');
                  el.appendChild(a);
                  a.click();
                }
              }
            ]
            : []),
          ...(showGroupedViewAction
            ? [
              {
                type: TYPES.ACTION,
                label: 'View',
                onClick: () => window.open(
                  `/builder/${revieweeId}/group-view${cycleId ? `/${cycleId}` : ''}`,
                  '_blank'
                )
              }
            ]
            : [])
        ]
      }
    ];
  });

  expandedGroups.forEach((eg) => {
    const parentRowIndex = rows.findIndex(([rowHeader]) => {
      const meta = get(rowHeader, 'meta', {});
      return meta.cycleId === eg.cycleId && meta.revieweeId === eg.revieweeId;
    });

    const reportGroup = reportGroups.find((group) => {
      const { cycleId, revieweeId } = group;
      return cycleId === eg.cycleId && revieweeId === eg.revieweeId;
    });

    const { data: reports } = reportGroup;

    const subRows = reports.map((report, index) => {
      const {
        _id,
        editToken,
        reviewee,
        reviewer,
        roleId,
        lastUpdated: reportLastUpdated
      } = report;
      const roleData = allRolesData.find((r) => r.roleId === roleId);
      const roleBadges = [
        {
          label: get(roleData, 'name', 'No Role'),
          colorClasses: BADGE_COLOR_CLASSES.GRAY
        }
      ];

      const isLast = index === reports.length - 1;

      return [
        {
          meta: {
            classes: `border-x-8 bg-very-light-blue ${isLast ? 'border-b-8 border-b-white border-t-4 border-t-very-light-blue border-x-very-light-blue' : 'border-y-4 border-very-light-blue'}`
          }
        },
        {},
        {
          label: commonUtils.getFullName(reviewee)
        },
        {
          label: commonUtils.getFullName(reviewer)
        },
        {
          type: TYPES.BADGES,
          badges: roleBadges
        },
        {
          label: commonDateUtils.dateToMonthDayYearFormat(
            new Date(reportLastUpdated)
          )
        },
        {
          label: report.status
        },
        {},
        {
          type: TYPES.DROP_MENU,
          label: 'Actions',
          direction: 'left',
          ifEmpty: 'hide',
          items: [
            {
              label: 'Delete',
              onClick: () => setReportIdToDelete(_id),
              hidden: !isAdmin
            },
            {
              label: 'Edit',
              onClick: () => navigate(`/builder/${editToken}/edit`)
            },
            {
              label: 'Download',
              onClick: async () => {
                toast.show('Creating PDF');
                let response;
                try {
                  response = await createPdf({
                    reportIds: [_id],
                    companyid
                  });
                } catch (e) {
                  return toast.error('Failed to create PDF');
                }
                toast.show('Report exported as pdf');

                const { documentUrl, name } = response;
                const a = document.createElement('a');
                a.setAttribute('href', documentUrl);
                a.setAttribute('download', name);
                const el = document.getElementById('app');
                el.appendChild(a);
                a.click();
              }
            },
            {
              label: 'View',
              onClick: () => window.open(`/builder/${_id}/view`, '_blank')
            }
          ]
        },
        {}
      ];
    });

    rows.splice(parentRowIndex + 1, 0, ...subRows);
  });

  const isLoading = isFetching
    || isRefetchingReports
    || isLoadingDeleteBuilderReport
    || isLoadingCreatePdf;
  return (
    <div className='w-full h-full flex flex-col mx-auto p-7'>
      <AlertModal
        isOpen={Boolean(reportIdToDelete)}
        close={() => setReportIdToDelete(null)}
        onAction={async () => {
          await deleteReport(reportIdToDelete);
          setReportIdToDelete(null);
          setExpandedGroups([]);
          queryClient.invalidateQueries([BUILDER_QUERY_KEYS.BUILDER]);
          queryClient.invalidateQueries([BUILDER_QUERY_KEYS.BUILDER_DASHBOARD]);
        }}
        title='Delete Review'
        content='Are you sure you want to delete this review?'
        actionText='Delete'
        isLoading={isLoadingDeleteBuilderReport}
      />
      <Base classes={STYLE.BASE}>
        <BuilderDashboardFilters
          filters={filters}
          setFilters={(params) => {
            setCurrentPage(1);
            setExpandedGroups([]);
            setFilters(params);
          }}
        />
        <Base classes={STYLE.CONTAINER_WHITE} loading={isLoading}>
          <div className='flex justify-between'>
            <div className='w-3/5'>
              <h5 className='text-black text-left text-xl mb-2'>
                Review Dashboard
              </h5>
            </div>
          </div>
          <Table
            columns={columns}
            rows={rows}
            wrapperClasses='py-2'
            bodyClasses='p-2'
            pagination={pagination}
            renderEmptyPlaceholder={() => renderEmptyPlaceholder(areThereReports)}
          />
        </Base>
      </Base>
    </div>
  );
};

export default BuilderDashboard;
