import React from 'react';
import { XButton, Select } from 'src/components';
import { isFunction } from 'lodash';

const getMaxSize = (size) => {
  if (size === undefined || size === null) {
    return false;
  }

  if (typeof val === 'string') {
    const num = Number(size);
    if (isNaN(num)) {
      return false;
    }
    return num;
  }

  if (typeof val === 'boolean') {
    return false;
  }

  return size;
};

const GeneralFilter = ({
  title,
  options,
  onChange,
  onClear,
  onSearch,
  disabledOptionClasses = '',
  disabled = false,
  searchNoDataPlaceholder = 'No Results',
  onDropdownOpen,
  onDropdownClose,
  isLoading = false,
  multiselect = false,
  wrapperClasses = 'w-full',
  selectClasses = 'w-full',
  XGap = 'gap-2',
  XOffset = '-mx-1',
  optionsWidth = 'standard',
  size = null
}) => {
  let displayedOptions = options.sort(
    (opt1, opt2) => opt2.checked - opt1.checked
  );
  if (multiselect) {
    const maxSize = getMaxSize(size);
    if (maxSize) {
      displayedOptions = options
        .sort((opt1, opt2) => opt2.checked - opt1.checked)
        .filter((o, index) => index < maxSize);
    }
  }

  return (
    <div className={`flex ${XGap} ${wrapperClasses}`}>
      <Select
        id={`general-filter-${title}`}
        variant='shadow'
        title={title}
        options={displayedOptions}
        onChange={onChange}
        onSearch={onSearch}
        disabled={disabled}
        disabledOptionClasses={disabledOptionClasses}
        searchNoDataPlaceholder={searchNoDataPlaceholder}
        showSearch={isFunction(onSearch)}
        onDropdownOpen={onDropdownOpen}
        onDropdownClose={onDropdownClose}
        loading={isLoading}
        multiselect={multiselect}
        classes={selectClasses}
        optionsWidth={optionsWidth}
      />
      {multiselect && options.some((option) => option.checked) ? (
        <span className={`flex items-center justify-center ${XOffset}`}>
          <XButton centered onClick={onClear} />
        </span>
      ) : null}
    </div>
  );
};

export default GeneralFilter;
