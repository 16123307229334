import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  Modal,
  Button,
  Select,
  toast,
  Base,
  Multiselect,
  appUtils
} from 'src/components';
import { useAccount, useAccounts, useAccountUpdate } from 'src/queries/account';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useTree } from 'src/queries/tree';
import STYLE from 'src/constants/style';
import { useCompany } from 'src/queries/company';
import { get } from 'lodash';
import sharedUtils from 'src/common/sharedUtils';

const { USER_STATE } = COMMON_CONSTANTS;

const statusOptions = [
  { id: 1, label: USER_STATE.ACTIVE },
  { id: 2, label: USER_STATE.INACTIVE },
  { id: 3, label: USER_STATE.PASSIVE }
];

const UnarchiveAccountModal = ({ accountId, close }) => {
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount(accountId);
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const [searchText, setSearchText] = useState('');
  const { data: accounts, isFetching: isFetchingAccounts } = useAccounts(
    {
      status: [USER_STATE.ACTIVE, USER_STATE.INACTIVE, USER_STATE.PASSIVE]
    },
    {
      page: {
        size: 30
      },
      search: {
        enabled: true,
        field: 'name',
        value: searchText
      }
    }
  );

  const { update: updateAccount, isLoading: isAccountUpdateLoading } = useAccountUpdate(accountId);

  const isFetching = isFetchingAccount || isFetchingCompany;
  const isError = isErrorAccount || isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  const { refetch: refetchTree } = useTree();

  const [localData, setLocalData] = useState({
    status: statusOptions[0],
    manager: {
      value: null,
      label: 'Select Manager'
    },
    roles: []
  });

  if (!isReady) {
    return null;
  }

  const companyQuestions = get(company, 'questions', null);
  const queryClient = appUtils.getQueryClient();

  const managerOptions = [];
  if (!isFetchingAccounts) {
    managerOptions.push(
      ...accounts.map((acc) => ({
        value: acc._id,
        label: acc.name
      }))
    );
  }

  const roleOptions = sharedUtils.getRoleOptions(
    companyQuestions,
    localData.roles
  );

  const updateAction = async () => {
    try {
      toast.show('Restoring user...');

      const {
        status: { label: status },
        manager: { value: managerId }
      } = localData;

      const data = {
        status,
        settings: {
          isArchived: false
        }
      };

      const nodeData = {
        ...data,
        // node only attributes
        settings: undefined,
        status: undefined,
        active: status,
        managerId,
        roles: localData.roles
      };
      data.nodeData = nodeData;

      await updateAccount({
        data
      });

      toast.show('User restored!');
      queryClient.invalidateQueries('account');
      queryClient.invalidateQueries('accounts');
      queryClient.invalidateQueries('companyTree');
      queryClient.invalidateQueries('events');
      queryClient.invalidateQueries('reviews');
      queryClient.invalidateQueries('users');
      await refetchTree();
      close();
    } catch (error) {
      return toast.error(error);
    }
  };

  return (
    <Modal
      variant='custom-no-scroll'
      classes='shadow text-left z-20'
      innerClasses='text-left h-full w-90'
      close={close}
    >
      <Base classes={`${STYLE.CONTAINER} items-start w-full`}>
        <p className='font-bold mb-3 text-xl text-left w-'>Restore user</p>
        <p className='mb-1 text-left'>
          Select
          <span className='font-bold mb-0'>
            {' '}
            {account.name}
            {' '}
          </span>
          new status
        </p>
        <Select
          id='unarchive-account-status-filter'
          classes='w-80 self-center'
          options={statusOptions}
          title={localData.status.label}
          onChange={(option) => {
            setLocalData({ ...localData, status: option });
          }}
        />
        <p className='mb-1 mt-5 text-left'>
          Select
          <span className='font-bold mb-0'>
            {' '}
            {account.name}
            {' '}
          </span>
          new manager
        </p>
        <Select
          id='unarchive-account-manager-filter'
          classes='w-80 self-center'
          placeholder='Manager'
          options={managerOptions}
          title={localData.manager.label}
          onChange={(option) => {
            setLocalData({ ...localData, manager: option });
          }}
          loading={isFetchingAccounts}
          showSearch
          onSearch={(value) => {
            setSearchText(value);
          }}
          onDropdownClose={() => {
            setSearchText('');
          }}
        />
        <p className='mb-1 mt-5 text-left'>
          Select
          <span className='font-bold mb-0'>
            {' '}
            {account.name}
            {' '}
          </span>
          new roles
        </p>
        <Multiselect
          classes='w-80 self-center'
          multiSelectSelectedItemClasses='w-8.5rem'
          options={roleOptions}
          placeholder='Select roles'
          other={{
            variant: 'sm',
            size: 300
          }}
          multiselect
          onChange={(option, type) => {
            if (type === 'select') {
              const newRoles = option.checked
                ? localData.roles.filter((r) => r !== option.id)
                : [...localData.roles, option.id];
              setLocalData({ ...localData, roles: newRoles });
            }
          }}
        />
        <div className='py-5 w-full flex justify-between'>
          <div className='w-1/2 text-left'>
            <Button
              classes='text-xl focus:outline-none pl-0'
              variant='transparent'
              onClick={close}
            >
              Close
            </Button>
          </div>
          <div className='w-1/2 text-right'>
            <Button
              variant='yellow'
              disabled={
                !localData.manager.value
                || !localData.roles.length
                || isFetching
                || isAccountUpdateLoading
              }
              onClick={updateAction}
            >
              Update
            </Button>
          </div>
        </div>
      </Base>
    </Modal>
  );
};

export default UnarchiveAccountModal;
