import React, { useContext, cloneElement } from 'react';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import { useLocation, useNavigate } from 'react-router-dom';
import { isFunction } from 'lodash';
import Button from 'src/components/Button/Button';

export const TABS = {
  FEEDBACK: 'feedback',
  ANALYTICS: 'analytics',
  PARTICIPATION: 'participation',
  REPORTS: 'reports'
};

const renderActions = (tab, actions = []) => {
  const actionsToRender = actions.filter(
    (action) => action.tabs.includes('*') || action.tabs.includes(tab)
  );

  return (
    <div className='flex mb-2 items-end justify-end gap-2 h-[39.5px]'>
      {actionsToRender.map((action, index) => {
        if (action.hidden) return;
        if (action.component) return cloneElement(action.component, { key: index });
        return (
          <Button
            key={index}
            disabled={action.disabled}
            onClick={isFunction(action.onClick) ? action.onClick : () => {}}
            variant={action.buttonVariant || 'black'}
            classes={`px-2 py-1 whitespace-nowrap h-full ${action.buttonClasses || ''}`}
            paddingless
          >
            {action.buttonText || 'Action'}
          </Button>
        );
      })}
    </div>
  );
};

const OrganizationTabNavigator = ({ actions = [] }) => {
  const { resetContext } = useContext(CompanyDashContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const tabButtonStyle = (isSelected) => `flex p-2 items-center gap-1 focus:outline-none border-b-4 border-purple text-xl ${
    isSelected
      ? 'border-opacity-100 text-purple font-bold'
      : 'border-opacity-0 text-zinc-800 font-bold'
  } hover:text-purple hover:border-opacity-100 hover:font-bold transition-colors duration-200 whitespace-nowrap`;

  const baseUrl = '/dashboard/organization';

  const currentTab = pathname
    .split('/')
    .find((str) => Object.values(TABS).includes(str));

  return (
    <div className='w-full flex justify-between items-end place-self-end px-4 h-52px'>
      <div className='flex gap-4'>
        <button
          className={tabButtonStyle(currentTab === TABS.ANALYTICS)}
          onClick={() => {
            resetContext();
            navigate(`${baseUrl}/analytics`);
          }}
        >
          Analytics
        </button>
        <button
          className={tabButtonStyle(currentTab === TABS.FEEDBACK)}
          onClick={() => {
            resetContext();
            navigate(`${baseUrl}/feedback`);
          }}
        >
          Feedback
        </button>
        <button
          className={tabButtonStyle(currentTab === TABS.PARTICIPATION)}
          onClick={() => {
            resetContext();
            navigate(`${baseUrl}/participation`);
          }}
        >
          Participation
        </button>
        <button
          className={tabButtonStyle(currentTab === TABS.REPORTS)}
          onClick={() => {
            resetContext();
            navigate(`${baseUrl}/reports`);
          }}
        >
          Reviews
        </button>
      </div>
      {renderActions(currentTab, actions)}
    </div>
  );
};

export default OrganizationTabNavigator;
