import React, { useContext, useState } from 'react';
import { get } from 'lodash';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import { useCompanyFeedback } from 'src/queries/feedback';
import { appUtils, Base } from 'src/components/';
import FeedbackFlyout from 'src/containers/UserProfile/FeedbackDashboard/components/FeedbackFlyout';
import { Table, AlertModal } from 'src/componentsTailwind/index';
import { TYPES } from 'src/componentsTailwind/Table/Table';
import CircledIcon from 'src/components/CircledIcon/CircledIcon';
import commonDateUtils from 'common/commonDateUtils';
import GrowingBarChartSVG from 'src/assets/svg/growing-bar-chart.svg';
import STYLE from 'src/constants/style';
import CircleV2 from 'src/components/Circle/CircleV2';
import COMMON_CONSTANTS from 'common/commonConstants';

const { ANONYMOUS_REVIEW } = COMMON_CONSTANTS;

const HistoricReviewsFeed = () => {
  const { context, updateContext } = useContext(CompanyDashContext);
  const loggedUserId = appUtils.getLoggedUserId();
  const { range, filters, currentPages } = context;
  const { managerId } = filters;

  const [isFeedbackFlyoutOpen, setIsFeedbackFlyoutOpen] = useState(false);
  const [flyoutFeedback, setFlyoutFeedback] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserAccount, setSelectedUserAccount] = useState(null);

  const currentPage = currentPages.historicReviewsFeed;
  const updatePage = (newPage) => updateContext({
    currentPages: {
      ...currentPages,
      historicReviewsFeed: newPage
    }
  });

  const {
    data: { feedbacks: reviews, pagination: { pages: totalPages } } = {},
    isFetching: isFetchingReviews
  } = useCompanyFeedback({
    start: range.start,
    end: range.end,
    managerId,
    options: { size: 10, page: currentPage, feedbackTypes: ['Review'] }
  });

  const openFeedbackFlyout = (reviewId) => {
    setFlyoutFeedback(() => {
      const review = reviews.find(
        (f) => f._id === reviewId || f.id === reviewId
      );

      if (review) {
        setIsFeedbackFlyoutOpen(true);
        return review;
      }
    });
  };

  const columns = [
    {
      label: 'Date',
      tooltip: false,
      span: 0.5
    },
    {
      label: 'Reviewer',
      tooltip: true
    },
    {
      label: 'Reviewee',
      tooltip: true
    },
    {
      label: 'Answer',
      tooltip: false
    },
    {
      label: 'Comments',
      tooltip: false,
      span: 1
    },
    {
      label: 'View',
      type: TYPES.ACTION,
      hidden: true,
      span: 0.5
    }
  ];

  const rows = reviews.map((review) => {
    const {
      id,
      date,
      reviewer,
      reviewee,
      reviewerName,
      reviewerImage,
      revieweeName,
      revieweeImage,
      answer,
      text
    } = review;

    const formattedDate = commonDateUtils.dateToMonthDayFormat(
      commonDateUtils.unixToDate(date),
      ''
    );

    const viewAction = () => openFeedbackFlyout(id);

    const reviewerProfileUrl = loggedUserId === reviewer
      ? `/dashboard/me/participation`
      : `/dashboard/profile/${reviewer}/participation`;

    const revieweeProfileUrl = loggedUserId === reviewee
      ? `/dashboard/me/participation`
      : `/dashboard/profile/${reviewee}/participation`;

    return [
      { label: formattedDate, labelClasses: 'font-bold' },
      {
        label: reviewerName,
        image: reviewerImage,
        type: TYPES.IMAGE_WITH_TEXT,
        onClick: () => {
          if (reviewerName !== ANONYMOUS_REVIEW.NAME) {
            setSelectedUserAccount({
              id: reviewer,
              name: reviewerName,
              firstName: reviewerName.split(' ')[0],
              imageUrl: reviewerImage,
              profileUrl: reviewerProfileUrl
            });
            setIsModalOpen(true);
          }
        }
      },
      {
        label: revieweeName,
        image: revieweeImage,
        type: TYPES.IMAGE_WITH_TEXT,
        onClick: () => {
          if (revieweeName !== ANONYMOUS_REVIEW.NAME) {
            setSelectedUserAccount({
              id: reviewee,
              name: revieweeName,
              firstName: revieweeName.split(' ')[0],
              imageUrl: revieweeImage,
              profileUrl: revieweeProfileUrl
            });
            setIsModalOpen(true);
          }
        }
      },
      { label: answer ?? 'Free response' },
      {
        label: text,
        id: `${id}-review-comments`,
        type: TYPES.HTML
      },
      {
        label: 'View',
        type: TYPES.ACTION,
        onClick: viewAction
      }
    ];
  });

  const pagination = {
    currentPage,
    setCurrentPage: updatePage,
    totalPages
  };

  const isFetching = isFetchingReviews;

  return (
    <>
      <AlertModal
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
        onAction={() => {
          window.open(selectedUserAccount.profileUrl, '_blank');
          setIsModalOpen(false);
          setSelectedUserAccount(null);
        }}
        title={get(selectedUserAccount, 'name', 'View More Details')}
        content={`View ${get(selectedUserAccount, 'firstName', 'this user')}'s dashboard see more details.`}
        actionText='View Dashboard'
        isLoading={isFetching}
        type='success'
        customIcon={(
          <CircleV2
            imageUrl={get(selectedUserAccount, 'imageUrl', null)}
            size='sm'
          />
        )}
      />
      <FeedbackFlyout
        width={40}
        feedback={flyoutFeedback}
        isOpen={isFeedbackFlyoutOpen}
        setIsOpen={setIsFeedbackFlyoutOpen}
        showXButton={false}
      />
      <Base
        classes={`${STYLE.CONTAINER_WHITE} !p-4`}
        relative
        loading={isFetching}
      >
        <CircledIcon svg={<GrowingBarChartSVG className='text-purple' />} />
        <p className='text-xl text-black font-bold mb-0 mr-8'>
          Historic Reviews
        </p>
        <p className='text-md font-normal my-2 leading-5 text-bluish-gray mb-6'>
          Real-time feed of reviews as they are submitted by the team.
        </p>
        <Table
          rowClasses='!px-0 [&>*:first-child]:!pl-4 [&>*:last-child]:!pr-0'
          headClasses='[&_*]:!text-sm [&_*]:!truncate [&>*:first-child]:!pl-0 [&>*:last-child]:!pr-0'
          columns={columns}
          rows={rows}
          pagination={pagination}
        />
      </Base>
    </>
  );
};

export default HistoricReviewsFeed;
