import React, { useEffect, useContext } from 'react';
import { get } from 'lodash';
import TreeContainer from 'src/containers/Tree/TreeContainer';
import { useTree } from 'src/queries/tree';
import { useAccount } from 'src/queries/account';
import { useCompany } from 'src/queries/company';
import { Base, Button, toast } from 'src/components';
import { OrganizationContext } from 'src/pagesDashboard/Organization/context/OrganizationProvider';
import { updateData } from 'src/pagesDashboard/Organization/context/actions';
import filterTree from 'src/containers/Organization/Tree/filterTree';
import commonTreeUtils from 'common/commonTreeUtils';
import CompanyName from 'src/containers/Organization/CompanyName/CompanyName';
import COMMON_CONSTANTS from 'common/commonConstants';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';

const { ACCESS } = COMMON_CONSTANTS;

const OrganizationTree = () => {
  const { state, dispatch } = useContext(OrganizationContext);
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');
  const {
    data: { tree, treeList, myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany || isFetchingAccount || isFetchingTree;
  const isError = isErrorCompany || isErrorTree || isErrorAccount;

  const isReady = get(company, 'id')
    && get(tree, 'id')
    && get(account, '_id')
    && !isFetching
    && !isError;

  useEffect(() => {
    if (tree) {
      const displayTree = filterTree({
        search: state.search,
        managerId: state.displayManagerId,
        tree,
        treeList
      });
      dispatch(updateData({ displayTree }));
    }
  }, [tree]);

  if (!isReady) {
    return null;
  }

  const openBelow = (node) => {
    dispatch(
      updateData({ displayManagerId: node.id, search: '', displayTree: node })
    );
  };
  const openAbove = (node) => {
    const topNode = commonTreeUtils.findNodeById(tree, node.managerId);
    dispatch(
      updateData({
        search: '',
        displayManagerId: topNode.id,
        displayTree: topNode
      })
    );
  };

  const addNode = (nodeId) => {
    // all info needed from the tree to change an user's data should be set here!!
    const initialData = {
      actionType: 'new',
      accountId: null,
      children: [],
      roles: [],
      managerId: nodeId
    };

    dispatch(
      updateData({
        showUserDetails: initialData
      })
    );
  };

  if (!treeList.length || !state.displayTree) {
    return null;
  }

  const getDisabledTooltip = () => {
    if (!company.active) {
      return 'Company is not active';
    }
    if (company.demo) {
      return 'Disabled in demo mode';
    }
  };

  const isSuperUser = appUtils.isSuperUser();

  return (
    <>
      <div className='flex justify-between px-1 w-full'>
        <div className='w-1/2 mt-auto text-left'>
          <h5 className='text-2xl group'>
            <CompanyName />
          </h5>
        </div>
        <div className='w-1/2 text-right'>
          <div className='inline-block tooltip'>
            {!company.active || company.demo ? (
              <span className='tooltip-text bg-black text-white -mt-8 -ml-6'>
                {getDisabledTooltip()}
              </span>
            ) : null}
            <div className='flex gap-2'>
              {isSuperUser && myTreeRow.access === ACCESS.ADMIN ? (
                <div className='flex'>
                  <Button
                    variant='yellow'
                    onClick={() => {
                      dispatch(updateData({ showOrgUpload: true }));
                    }}
                  >
                    Upload (superuser)
                  </Button>
                </div>
              ) : null}
              {[ACCESS.ADMIN, ACCESS.MANAGER].includes(account.access) ? (
                <Button variant='yellow' onClick={() => addNode(account._id)}>
                  Add Team Member
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Base
        classes={STYLE.CONTAINER_WHITE}
        loading={isFetchingTree || isFetchingCompany}
      >
        <TreeContainer
          treeNode={state.displayTree}
          openBelow={openBelow}
          openAbove={openAbove}
          openUserDetails={addNode}
          visibleDepth={account?.settings?.visibleTreeDepth || 2}
        />
      </Base>
    </>
  );
};

export default OrganizationTree;
