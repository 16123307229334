import React from 'react';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import TopFilters from 'src/containers/CompanyDash/TopFilters/TopFilters';
import { useCompany } from 'src/queries/company';
import RangeTeamPerformance from 'src/pagesDashboard/CompanyDash/components/RangeTeamPerformance';
import TeamOverallScores from 'src/pagesDashboard/CompanyDash/components/TeamOverallScores';
import GroupPerformance from 'src/pagesDashboard/CompanyDash/components/GroupPerformance';
import TeamMemberPerformance from 'src/pagesDashboard/CompanyDash/components/TeamMemberPerformance';
import CompetencyComparison from 'src/pagesDashboard/CompanyDash/components/CompetencyComparison';

const { ACCESS, DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

const OrganizationAnalytics = () => {
  const loggedUser = appUtils.getLoggedUser();
  if (loggedUser.access !== ACCESS.ADMIN) {
    console.error(
      'User does not have access to company dash, redirecting to /dashboard/me/feedback'
    );
    window.location.replace(appUtils.getDashRoute());
  }

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  return (
    <>
      <div className='h-full w-full flex gap-3'>
        <div className='w-2/3'>
          <TopFilters
            show={['date', 'team']}
            hideKeys={[DATE_RANGE_FILTERS.CUSTOM.key]}
          />
          <RangeTeamPerformance />
        </div>
        <div className='w-1/3'>
          <TeamOverallScores />
        </div>
      </div>
      <GroupPerformance />
      <TeamMemberPerformance />
      <CompetencyComparison />
    </>
  );
};

export default OrganizationAnalytics;
