import COMMON_CONSTANTS from 'common/commonConstants';
import { isEmpty, isString } from 'lodash';
import uuidv4 from 'uuid/v4';

const {
  EMAIL_FREQUENCY,
  SCORE_SYSTEM,
  DIRECT_REVIEW_PERMISSIONS,
  COMPANY_REVIEW_VISIBILITY_OPTIONS,
  DEFAULT_COMPANY_REVIEW_VISIBILITY_OPTION,
  DEFAULT_COMPANY_VIEW_PERMISSIONS,
  VIEW_USER_PROFILE_PERMISSIONS,
  DEFAULT_COMPANY_SCORE_LABELS,
  VIEW_SCORES_PERMISSIONS,
  SCHEDULING_STATUS,
  ENABLED_STATUS,
  DEFAULT_COMPANY_TEMPLATES,
  DEFAULT_ROLE,
  DEFAULT_CATEGORY,
  DEFAULT_QUESTION
} = COMMON_CONSTANTS;

const utils = {};

export const COMPANY_EXPORT_VIEWS = {
  ORGANIZATION: 'Organization',
  REPORTS: 'Reports',
  USERS: 'Users'
};

export const DEFAULT_REQUIRE_COMMENTS_ID = 3;

export const requireCommentsOptions = [
  {
    id: 10,
    label: 'For All Reviews',
    type: 'under',
    score: 11
  },
  {
    id: 1,
    label: 'under 100%',
    type: 'under',
    score: 10
  },
  {
    id: 2,
    label: 'under 90%',
    type: 'under',
    score: 9
  },
  {
    id: 3,
    label: 'under 80%',
    type: 'under',
    score: 8
  },
  {
    id: 4,
    label: 'under 70%',
    type: 'under',
    score: 7
  },
  {
    id: 5,
    label: 'under 60%',
    type: 'under',
    score: 6
  },
  {
    id: 6,
    label: 'under 50%',
    type: 'under',
    score: 5
  },
  {
    id: 7,
    label: 'under 40%',
    type: 'under',
    score: 4
  },
  {
    id: 8,
    label: 'under 30%',
    type: 'under',
    score: 3
  },
  {
    id: 9,
    label: 'under 20%',
    type: 'under',
    score: 2
  },
  {
    id: 11,
    label: 'Range 20-40%, 95-100%',
    type: 'range',
    score: [
      { min: 2, max: 4 },
      { min: 9.5, max: 10 }
    ]
  },
  {
    id: 0,
    label: 'Never',
    type: 'never',
    score: 0
  }
];

export const defaultCompanySettings = {
  scoreSystem: SCORE_SYSTEM.AVERAGE,
  requireComments: DEFAULT_REQUIRE_COMMENTS_ID, // option id from 'requireCommentsOptions'
  contentEvents: false,
  directReviewPermissions: DIRECT_REVIEW_PERMISSIONS.PER_REVIEW_RELATIONSHIPS,
  builderDirectReviewPermissions: DIRECT_REVIEW_PERMISSIONS.OPEN,
  reviewVisibilityOptions: COMPANY_REVIEW_VISIBILITY_OPTIONS,
  reviewVisibilityDefaultOption: DEFAULT_COMPANY_REVIEW_VISIBILITY_OPTION,
  viewPermissions: DEFAULT_COMPANY_VIEW_PERMISSIONS,
  viewUserProfile: VIEW_USER_PROFILE_PERMISSIONS.OPEN,
  scoreLabels: DEFAULT_COMPANY_SCORE_LABELS,
  viewScores: VIEW_SCORES_PERMISSIONS.ALL
};

export const defaultCompanyEvents = {
  schedulingStatus: SCHEDULING_STATUS.IDLE,
  reviews: ENABLED_STATUS.ENABLED,
  goals: ENABLED_STATUS.DISABLED,
  pulse: ENABLED_STATUS.DISABLED
};

export const inactiveCompanyEvents = {
  schedulingStatus: SCHEDULING_STATUS.IDLE,
  reviews: ENABLED_STATUS.DISABLED,
  goals: ENABLED_STATUS.DISABLED,
  pulse: ENABLED_STATUS.DISABLED
};

export const getDefaultCompanyTemplates = (userId, date) => [
  {
    id: uuidv4(),
    type: 'SUMMARY_TEMPLATE',
    ...DEFAULT_COMPANY_TEMPLATES.MANAGER_SUMMARY_TEMPLATE,
    author: userId,
    createdAt: date,
    updatedAt: null
  },
  {
    id: uuidv4(),
    type: 'SUMMARY_TEMPLATE',
    ...DEFAULT_COMPANY_TEMPLATES.EMPLOYEE_SUMMARY_TEMPLATE,
    author: userId,
    createdAt: date,
    updatedAt: null
  }
];

utils.getRequireComments = (id) => requireCommentsOptions.find((c) => c.id === id) || requireCommentsOptions[0];

utils.doesRequireComments = (companySettings, score) => {
  try {
    if (
      !companySettings
      || !companySettings.requireComments
      || score === undefined
      || score === null
    ) {
      return false;
    }

    const req = utils.getRequireComments(companySettings.requireComments);
    const actual = parseFloat(score);

    if (req.type === 'never') {
      return false;
    }

    if (req.type === 'under') {
      const under = parseFloat(req.score);
      return actual < under;
    }

    if (req.type === 'range') {
      let isRequired = false;
      req.score.forEach((rule) => {
        if (actual >= rule.min && actual <= rule.max) {
          isRequired = true;
        }
      });

      return isRequired;
    }

    console.error(
      'commonCompanyUtils.doesRequireComments should never get here',
      {
        companySettings,
        score
      }
    );
    return false;
  } catch (error) {
    console.error('ReviewManager.doesRequireComments error', {
      error,
      companySettings,
      score
    });
    return false;
  }
};

utils.getFrequencyText = (companyFrequency) => {
  const key = Object.keys(EMAIL_FREQUENCY).find(
    (key) => EMAIL_FREQUENCY[key].value === companyFrequency
  );
  if (!key) {
    return 'in the last week';
  }
  return `${EMAIL_FREQUENCY[key].textForm}`;
};

utils.getFrequency = (frequencyValue) => {
  let obj = null;
  Object.keys(EMAIL_FREQUENCY).find((key) => {
    const o = EMAIL_FREQUENCY[key];
    if (o.value === frequencyValue) {
      obj = o;
    }
  });

  return obj;
};

utils.getScoreLabel = (scoreLabels, score) => {
  if (!scoreLabels || !score) return 'Not applicable';
  const scoreVal = parseFloat(score);

  const findScoreLabel = scoreLabels.find(
    (sl) => scoreVal >= sl.min && scoreVal <= sl.max
  );
  if (!findScoreLabel) return 'Not applicable';
  return findScoreLabel.label;
};

utils.getScoreIcon = (scoreLabels, score) => {
  if (!scoreLabels || !score) return '-';

  const scoreVal = parseFloat(score);

  const findScoreIcon = scoreLabels.find(
    (sl) => scoreVal >= sl.min && scoreVal <= sl.max
  );
  if (!findScoreIcon) return '-';
  return findScoreIcon.icon;
};

utils.getQuestionsWithoutDefaults = ({
  company = null,
  questions = null,
  returnAsString = false
}) => {
  let companyQuestions;

  const isCompanyEmpty = isEmpty(company);
  const isQuestionsEmpty = isEmpty(questions);

  if (isCompanyEmpty && isQuestionsEmpty) {
    throw new Error(
      'getQuestionsWithoutDefaults requires either company or questions'
    );
  }

  if (!isCompanyEmpty) {
    if (isString(company.questions)) companyQuestions = JSON.parse(company.questions);
    else companyQuestions = JSON.parse(JSON.stringify(company.questions));
  } else if (!isQuestionsEmpty) {
    if (isString(questions)) companyQuestions = JSON.parse(questions);
    else companyQuestions = JSON.parse(JSON.stringify(questions));
  }

  companyQuestions.ROLES = {
    ...Object.keys(companyQuestions.ROLES).reduce((acc, key) => {
      if (companyQuestions.ROLES[key].id !== DEFAULT_ROLE.id) {
        acc[key] = companyQuestions.ROLES[key];
      }
      return acc;
    }, {})
  };

  companyQuestions.CATEGORIES = {
    ...Object.keys(companyQuestions.CATEGORIES).reduce((acc, key) => {
      if (companyQuestions.CATEGORIES[key].id !== DEFAULT_CATEGORY.id) {
        acc[key] = companyQuestions.CATEGORIES[key];
      }
      return acc;
    }, {})
  };

  companyQuestions.QUESTIONS = companyQuestions.QUESTIONS.filter(
    (question) => question.id !== DEFAULT_QUESTION.id
  );

  if (companyQuestions.ROLES_LIST) {
    companyQuestions.ROLES_LIST = companyQuestions.ROLES_LIST.filter(
      (role) => role.id !== DEFAULT_ROLE.id
    );
  }

  if (returnAsString) return JSON.stringify(companyQuestions);
  return companyQuestions;
};

export default utils;
