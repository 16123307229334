import React from 'react';
import { Base, Button, appUtils } from 'src/components/';
import { useGroupMembers } from 'src/queries/company';
import { get, isEmpty } from 'lodash';
import CircleV2 from 'src/components/Circle/CircleV2';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild
} from '@headlessui/react';
import STYLE from 'src/constants/style';

const GroupModal = ({ isOpen, close, group }) => {
  const { data, isFetching: isFetchingGroupMembers } = useGroupMembers(
    get(group, 'groupId', null)
  );

  const groupName = get(group, 'groupName', 'Group');
  const groupMembers = get(data, 'data.members', []);
  const hasNoMembers = !isFetchingGroupMembers && isEmpty(groupMembers);

  return (
    <Transition show={isOpen}>
      <Dialog className='fixed top-0 left-0 w-full h-full z-10' onClose={close}>
        <TransitionChild
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </TransitionChild>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='inset-0 z-10 overflow-y-auto flex min-h-screen min-w-screen'>
            <div className='flex min-h-full min-w-full items-end justify-center text-center sm:items-center sm:p-0'>
              <TransitionChild
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <DialogPanel className='relative flex items-center transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all px-6 w-[430px] h-[430px]'>
                  <Base
                    classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS_MARGINLESS_SHADOWLESS} w-full h-fit`}
                    loading={isFetchingGroupMembers}
                  >
                    <DialogTitle
                      as='h3'
                      className='text-base font-semibold leading-6 text-gray-900 !p-0'
                    >
                      <p className='m-0 font-bold text-xl text-black text-left'>
                        Grouped Team Members
                      </p>
                      <p className='m-0 text-lg text-dark-grey text-left'>
                        {groupName}
                      </p>
                    </DialogTitle>
                    <div className='h-fit max-h-[276px] my-3 overflow-y-scroll'>
                      {hasNoMembers
                        ? 'This group has no members assigned to it'
                        : groupMembers.map((user) => (
                          <div
                            className='flex justify-start items-center h-[46px] w-full my-2'
                            key={user.id}
                          >
                            <div>
                              <CircleV2 imageUrl={user.imageUrl} size='xs' />
                            </div>
                            <div className='ml-3 text-left max-w-[100%]'>
                              <button
                                className='hover:text-indigo-900 font-bold leading-4'
                                onClick={() => window.open(
                                  appUtils.getDashRoute(user.id),
                                  '_blank'
                                )}
                              >
                                {user.name}
                              </button>
                              <p className='m-0 text-xs leading-4'>
                                {user.title}
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                    <Button
                      variant='black'
                      onClick={close}
                      disabled={isFetchingGroupMembers}
                    >
                      Close
                    </Button>
                  </Base>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GroupModal;
